import React, { useState } from "react";
import {
  Modal,
  NumberInput,
  Button,
  Group,
  Table,
  TextInput,
  Select,
  Text,
} from "@mantine/core";
import { showNotification } from "../../../utils/notification";
import { productlineService } from "../../../services";
import styles from "./CreateBriefModal.module.sass";
import {
  NEW_PRODUCT_LINE_BRIEF_STATUS,
  SUPPLIER_SAMPLE_REQUEST,
} from "../../../constant";
import { every, isEmpty, keys, map, omit } from "lodash";
import moment from "moment-timezone";

const SUPPLIER_OPTIONS = map(keys(SUPPLIER_SAMPLE_REQUEST), (key) => ({
  value: SUPPLIER_SAMPLE_REQUEST[key],
  label: key,
}));

const CreateBriefModal = ({ opened, onClose, selectedBrief, onSuccess }) => {
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  // Generate empty rows based on count
  const generateRows = (count) => {
    return Array(count)
      .fill(null)
      .map((_, index) => ({
        id: index,
        supplierName: "",
        printUrl: "",
        quantity: "",
      }));
  };

  const handleRowCountChange = (value) => {
    setRowCount(value);
    setRows(generateRows(value));
  };

  const handleRowChange = (id, field, value) => {
    setRows((currentRows) =>
      currentRows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      )
    );
  };

  const isFormValid = () => {
    return every(rows, (row) => {
      return !isEmpty(row.supplierName) && !isEmpty(row.quantity);
    });
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      showNotification(
        "Error",
        "Please fill all required fields (Supplier Name and Quantity)",
        "red"
      );
      return;
    }
    if (isEmpty(rows)) {
      return;
    }

    try {
      setLoading(true);
      const payloads = map(rows, (row) => ({
        ...omit(row, ["id"]),
        productType: selectedBrief?.name,
        date: moment().format("YYYY-MM-DD"),
      }));
      await productlineService.createSampleRequest({ payloads });
      showNotification("Success", "Created briefs successfully", "green");
    } catch (error) {
      console.error("Failed to create briefs:", error);
      showNotification("Error", "Failed to create briefs", "red");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateBrief = async () => {
    try {
      setLoading(true);
      await productlineService.update({
        uid: selectedBrief?.uid,
        data: {
          status: NEW_PRODUCT_LINE_BRIEF_STATUS.NEW_PRODUCT_LINE_DONE,
        },
      });
      onSuccess();
      onClose();
    } catch (error) {
      showNotification("Lỗi", "Cập nhật thất bại", "red");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { header: "Supplier Name", key: "supplierName", required: true },
    { header: "Print URL", key: "printUrl", required: false },
    { header: "Quantity", key: "quantity", required: true },
    { header: "Product Code", key: "productCode", required: false },
  ];

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Request Sample"
      size="xl"
      className={styles.modal}
    >
      <div className={styles.content}>
        <div className={styles.rowCount}>
          <NumberInput
            label="Number of Sample"
            description="Enter the number of sample you want to create"
            value={rowCount}
            onChange={handleRowCountChange}
            min={1}
            max={10}
            className={styles.input}
          />
        </div>

        <div className={styles.tableWrapper}>
          <Table striped highlightOnHover withTableBorder>
            <Table.Thead>
              <Table.Tr>
                {columns.map((col) => (
                  <Table.Th key={col.key}>
                    {col.header}
                    {col.required && (
                      <Text span c="red">
                        *
                      </Text>
                    )}
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {rows.map((row) => (
                <Table.Tr key={row.id}>
                  <Table.Td>
                    <Select
                      data={SUPPLIER_OPTIONS}
                      value={row.supplierName}
                      onChange={(value) =>
                        handleRowChange(row.id, "supplierName", value)
                      }
                      placeholder="Select supplier"
                    />
                  </Table.Td>
                  <Table.Td>
                    <TextInput
                      value={row.printUrl}
                      onChange={(e) =>
                        handleRowChange(row.id, "printUrl", e.target.value)
                      }
                      placeholder="Enter print URL (optional)"
                    />
                  </Table.Td>
                  <Table.Td>
                    <TextInput
                      value={row.quantity}
                      onChange={(event) =>
                        handleRowChange(row.id, "quantity", event.target.value)
                      }
                      placeholder="Enter quantity"
                      min={1}
                    />
                  </Table.Td>
                  <Table.Td>
                    <TextInput
                      value={row.productCode}
                      onChange={(event) =>
                        handleRowChange(
                          row.id,
                          "productCode",
                          event.target.value
                        )
                      }
                      placeholder="Enter Product Code"
                    />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </div>

        <div className={styles.actions}>
          <Group justify="flex-end">
            <Button variant="light" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await handleSubmit();
                await handleUpdateBrief();
              }}
              loading={loading}
              variant="outline"
              color="green"
            >
              Done
            </Button>
          </Group>
        </div>
      </div>
    </Modal>
  );
};

export default CreateBriefModal;
