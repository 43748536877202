import React from "react";
import { Select } from "@mantine/core";
import { get, isNil } from "lodash";

const ERROR_MESSAGES = {
  UPDATE_FAILED: "Cập nhật thất bại",
};

const SelectCell = ({
  value,
  onChange,
  onDraftChange,
  onUpdate,
  data,
  placeholder,
  isDraft = false,
  showError,
  clearable = true,
}) => {
  const handleChange = async (newValue) => {
    if (isNil(newValue)) return;

    const handler = isDraft ? onDraftChange : onChange;
    handler?.(newValue);

    if (!isDraft) {
      try {
        await onUpdate?.(newValue);
      } catch (error) {
        showError?.(get(error, "message", ERROR_MESSAGES.UPDATE_FAILED));
        onChange?.("");
      }
    }
  };

  return (
    <Select
      value={value || null}
      onChange={handleChange}
      data={data || []}
      placeholder={placeholder}
      clearable={clearable}
    />
  );
};

export default SelectCell;
