export const RANKING_URL = "https://products-ranking.uidevify.com";

export const RANKING_STATUS = {
  NOT_TOUCH: "Chưa touch",
  FOLLOWED: "Đã theo",
};
export const CONVERT_NUMBER_TO_RANKING_STATUS = {
  0: RANKING_STATUS.NOT_TOUCH,
  1: RANKING_STATUS.FOLLOWED,
};
export const CONVERT_STATUS_TO_RANKING_NUMBER = {
  [RANKING_STATUS.NOT_TOUCH]: 0,
  [RANKING_STATUS.FOLLOWED]: 1,
};
