import React, { useState } from "react";
import {
  Modal,
  Button,
  Table,
  Group,
  ActionIcon,
  Tooltip,
} from "@mantine/core";
import { IconEdit, IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { rankingServices } from "../../services";
import { showNotification } from "../../utils/index";
import StoreModal from "../StoreModal";
import styles from "./StoreListModal.module.sass";
import { map } from "lodash";

const StoreListModal = ({ opened, onClose, competitors, onRefetch }) => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [openedStoreModal, { open: openStoreModal, close: closeStoreModal }] =
    useDisclosure(false);
  const [loading, setLoading] = useState(false);

  const handleEditStore = (store) => {
    setSelectedStore(store);
    openStoreModal();
  };

  const handleAddNewStore = () => {
    setSelectedStore(null);
    openStoreModal();
  };

  const handleSubmitStore = async (data) => {
    try {
      setLoading(true);
      if (selectedStore) {
        // Update existing store
        await rankingServices.updateStore(selectedStore._id, data);
      } else {
        // Create new store
        await rankingServices.createStore(data);
      }
      closeStoreModal();
      onRefetch();
    } catch (error) {
      showNotification("Thất bại", error?.message || "Có lỗi xảy ra", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        title="Competitor Management"
        size="xl"
      >
        <div className={styles.header}>
          <Button
            leftSection={<IconPlus size={14} />}
            variant="light"
            onClick={handleAddNewStore}
          >
            Add Competitor
          </Button>
        </div>

        <Table striped highlightOnHover withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Short Name</Table.Th>
              <Table.Th>Products/Page</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th>Shopify</Table.Th>
              <Table.Th className={styles.actionColumn}>Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {map(competitors, (store) => (
              <Table.Tr key={store._id}>
                <Table.Td>{store.name}</Table.Td>
                <Table.Td>{store.shortName}</Table.Td>
                <Table.Td>{store.productsPerPage}</Table.Td>
                <Table.Td>{store.alive ? "Active" : "Inactive"}</Table.Td>
                <Table.Td>{store.isShopify ? "Yes" : "No"}</Table.Td>
                <Table.Td>
                  <Group gap="xs" justify="center">
                    <Tooltip label="Edit">
                      <ActionIcon
                        variant="light"
                        color="blue"
                        onClick={() => handleEditStore(store)}
                      >
                        <IconEdit size={16} />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Modal>

      <StoreModal
        opened={openedStoreModal}
        onClose={closeStoreModal}
        onSubmit={handleSubmitStore}
        loading={loading}
        initialData={selectedStore}
      />
    </>
  );
};

export default StoreListModal;
