import React from "react";
import { Modal, Group, Button } from "@mantine/core";
import styles from "./ProductSelectorModal.module.sass";
import ProductSelector from "../ProductSelector";
import { rndServices } from "../../../../services";

const ProductSelectorModal = ({
  opened,
  onClose,
  selectedProducts = [],
  onFinish,
  filters = [],
  onChange,
}) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Select Cliparts"
      size="xl"
      className={styles.modal}
    >
      <div className={styles.content}>
        <ProductSelector
          value={selectedProducts}
          onChange={(cliparts) => onChange?.(cliparts)}
          fetchProducts={rndServices.fetchClipArts}
          searchPlaceholder="Search Cliparts..."
          showSync={false}
          filters={filters}
          isShowDefaultFilter={false}
        />
      </div>

      <div className={styles.footer}>
        <Group>
          <Button variant="light" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => onFinish?.(selectedProducts)}>
            Finish ({selectedProducts.length})
          </Button>
        </Group>
      </div>
    </Modal>
  );
};

export default ProductSelectorModal;
