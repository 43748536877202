import React, { useCallback } from "react";
import { Grid, Loader, Pagination } from "@mantine/core";
import styles from "./ProductSelector.module.sass";
import { useProductSelector } from "./hooks/useProductSelector";
import SearchBar from "./components/SearchBar";
import FilterBar from "./components/FilterBar";
import ProductCard from "./components/ProductCard";
import { some, filter, isEmpty, map } from "lodash";

const ProductSelector = ({
  value = [],
  onChange,
  fetchProducts,
  searchPlaceholder = "Search...",
  showSync = true,
  onSync,
  filters = [],
  isShowDefaultFilter = true,
  singleSelect = false,
}) => {
  const {
    searchValue,
    products,
    loading,
    pagination,
    filters: filterValues,
    handleSearch,
    handlePageChange,
    handleSync,
    handleFilterChange,
  } = useProductSelector({ fetchProducts, onSync });

  const handleSelect = useCallback(
    (product) => {
      const isSelected = some(value, { uid: product?.uid });
      if (singleSelect) {
        if (isSelected) {
          onChange([]);
        } else {
          onChange([product]);
        }
      } else {
        onChange(
          isSelected
            ? filter(value, (item) => item?.uid !== product?.uid)
            : [...value, product]
        );
      }
    },
    [value, onChange]
  );

  const isProductSelected = useCallback(
    (productId) => {
      return some(value, { uid: productId });
    },
    [value]
  );

  return (
    <div className={styles.root}>
      {isShowDefaultFilter && (
        <>
          <SearchBar
            value={searchValue}
            onChange={handleSearch}
            loading={loading}
            showSync={showSync}
            onSync={handleSync}
            placeholder={searchPlaceholder}
          />
        </>
      )}

      {!isEmpty(filters) && (
        <FilterBar
          filters={filters}
          value={filterValues}
          onChange={handleFilterChange}
        />
      )}

      {loading ? (
        <div className={styles.loaderWrapper}>
          <Loader size="md" />
        </div>
      ) : (
        <>
          <Grid className={styles.grid} mt={10}>
            {map(products, (product) => (
              <Grid.Col span={2} key={product?.uid}>
                <ProductCard
                  product={product}
                  isSelected={isProductSelected(product?.uid)}
                  onClick={() => handleSelect(product)}
                />
              </Grid.Col>
            ))}
          </Grid>

          {pagination.totalPages > 1 && (
            <div className={styles.pagination}>
              <Pagination
                value={pagination.currentPage}
                total={pagination.totalPages}
                onChange={handlePageChange}
                classNames={{
                  root: styles.paginationRoot,
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductSelector;
