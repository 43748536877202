import React, { useState } from "react";
import { TextInput, ActionIcon } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import styles from "./SearchText.module.sass";

const SearchText = ({ onSearch, placeholder = "Search...", className }) => {
  const [value, setValue] = useState("");

  const handleSearch = () => {
    onSearch?.(value.trim());
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className={`${styles.searchWrapper} ${className}`}>
      <TextInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        rightSection={
          <ActionIcon
            onClick={handleSearch}
            variant="subtle"
            className={styles.searchIcon}
          >
            <IconSearch size={16} />
          </ActionIcon>
        }
        classNames={{
          input: styles.input,
          wrapper: styles.inputWrapper,
        }}
      />
    </div>
  );
};

export default SearchText;
