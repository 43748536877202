import {
  Button,
  Checkbox,
  Flex,
  Group,
  Image,
  Pagination,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconFilterOff,
  IconSearch,
  IconChevronDown,
  IconChevronRight,
  IconX,
  IconArchiveOff,
  IconArchive,
  IconCheck,
} from "@tabler/icons-react";
import { concat, filter, includes, isEmpty, keys, map } from "lodash";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { DateRangePicker } from "rsuite";
import { CONVERT_STATUS_TO_NUMBER } from "../../../utils";
import useTable from "./useTable";

import formatDate from "../../../utils/formatDate";
import classes from "./index.module.css";
import AppSelect from "../../../components/AppSelect";
import Link from "../../../components/Link";
import EditLink from "../../../components/EditLink";
import EditMultipleLink from "../../../components/MultiEditLink";
import EditMultipleLinkImages from "../../../components/MultiLinkImages";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import ModalEditNoteForVideoFromSample from "./NoteFromSample";
import {
  BD_TEAMS,
  BRIEF_TYPE_NEW_SAMPLE_VIDEO,
  BRIEF_TYPE_ON_PLAN_VIDEO,
  BRIEF_TYPE_SAMPLE_VIDEO,
  PROJECTS,
  STATUS,
  UNIQUE_TEAMS,
} from "../../../constant";
import CardInformation from "./CardInfomation";
import { modals } from "@mantine/modals";
import Value from "../../../components/Value";
import SelectValue from "../../../components/SelectValue";

const VideoTable = ({ query, setQuery }) => {
  const [selectedBrief, setSelectedBrief] = useState({});
  const [
    openedModalNoteFromSample,
    { open: openModalNoteFromSample, close: closeModalNoteFromSample },
  ] = useDisclosure(false);

  const {
    data,
    users,
    searchSKU,
    loading,
    pagination,
    listUserOptions,
    clearFilters,
    handleChangeDate,
    handleChangePage,
    handleSelectEditor,
    handleResetEditor,
    handleChangeSizeValue,
    handleChangeSKU,
    handleChangeStatus,
    handleChangeTeam,
    handleClearSizeValue,
    handleClearStatus,
    handleClearTeam,
    handleSubmitSKU,
    handleDoneSample,
    handleIncompleteSample,
    handleUpdateDoneScene,
    handleUpdateLinkVideo,
    handleUpdateVideoEditor,
    handleUpdateVideoThumbLinks,
    handleCheckDoneSource,
    handleResetIsDoneSource,
    handleUpdateNote,
    handleRemoveVideo,
    handleUpdateValue,
    handleArchiveBrief,
    handleUpdateTeam,
    handleChangeProject,
    handleClearProject,
  } = useTable({ query, setQuery });

  //DELETE action
  const openDeleteConfirmModal = (row) =>
    modals.openConfirmModal({
      title: "Are you sure you want to delete this SKU?",
      centered: true,
      children: (
        <Text>
          Are you sure you want to delete {row.original.sku}? This action cannot
          be revert.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => handleRemoveVideo(row.original.uid),
    });

  const table = useMantineReactTable({
    mantineTableHeadCellProps: { className: classes["head-cells"] },
    mantineTableProps: { striped: "even" },
    columns: [
      {
        accessorKey: "videoRequestedDate",
        header: "DATE",
        size: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell(row) {
          return formatDate(row.renderedCellValue);
        },
      },
      {
        accessorKey: "sku",
        header: "SKU",
        size: 120,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
        Cell({ row }) {
          const sku = row.original?.sku;
          const videoOrder = row.original?.videoName;
          return (
            <Flex direction="column" align="center">
              <span>{sku}</span>
              {videoOrder && <span>{videoOrder}</span>}
            </Flex>
          );
        },
      },
      {
        accessorKey: "numberOfBriefs",
        header: "SL File",
        size: 80,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
      },
      {
        accessorKey: "design",
        header: "DESIGN",
        size: 120,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
        Cell({ row }) {
          return (
            <Image
              radius="md"
              src={
                row.original?.designInfo?.thumbLink ||
                row.original?.videoInfo?.thumbLink ||
                row?.original?.imageLink
              }
              height={100}
              fit="contain"
            />
          );
        },
      },
      {
        accessorKey: "briefType",
        header: "Loại Brief",
        size: 130,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
        Cell({ row }) {
          const briefType = [
            BRIEF_TYPE_SAMPLE_VIDEO,
            BRIEF_TYPE_NEW_SAMPLE_VIDEO,
          ].includes(row.original.briefType)
            ? row.original.briefType
            : BRIEF_TYPE_ON_PLAN_VIDEO;
          return !row.original.briefType
            ? null
            : briefType?.replace("Video", "");
        },
      },
      {
        accessorKey: "value",
        header: "VALUE",
        size: 120,
        enableEditing: true,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["SKU"] },
        enableSorting: false,
        Cell: ({ row }) => {
          const value = row?.original?.value;
          return <Value value={value} />;
        },
        Edit: ({ row }) => {
          const isDisabled = row?.original?.isDisabled;
          const value = row?.original?.value;
          return isDisabled ? (
            <Value value={value} />
          ) : (
            <SelectValue
              uid={row?.original?.uid}
              defaultValue={row?.original?.value}
              handleChange={handleUpdateValue}
            />
          );
        },
      },
      {
        accessorKey: "project",
        header: "Project",
        size: 100,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
      },
      {
        header: "PIC Quay",
        size: 120,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
        Cell(record) {
          const row = record.row.original;
          return row.videoSupplier;
        },
      },
      {
        accessorKey: "noteFromSampleForVideo",
        header: "NOTE FROM SAMPLE",
        size: 100,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: false,
        enableSorting: false,
        Cell: (record) => {
          const row = record?.row.original;
          const note = row?.note?.noteFromSampleForVideo;
          const isDisabled = row?.isDisabled;
          return isDisabled ? null : (
            <Button
              onClick={() => {
                setSelectedBrief(row);
                openModalNoteFromSample();
              }}
              variant="filled"
              color={note ? "green" : "gray"}
              size="sx"
            >
              Note
            </Button>
          );
        },
      },
      {
        accessorKey: "rndTeam",
        header: "Team",
        size: 120,
        enableEditing: true,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        enableSorting: false,
        Edit: ({ row }) => {
          const isDisabled = row?.original?.isDisabled;
          const rndTeam = row?.original?.rndTeam;
          return isDisabled ? (
            rndTeam
          ) : (
            <Select
              defaultValue={row?.original?.rndTeam}
              onChange={(value) => handleUpdateTeam(row.original?.uid, value)}
              placeholder="Pick value"
              data={UNIQUE_TEAMS}
              allowDeselect={false}
            />
          );
        },
      },
      {
        header: "Done source Quay",
        size: 120,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        enableSorting: false,
        Cell(record) {
          const row = record.row.original;

          const isChecked = row?.isDoneSource;
          const isDisabled = row?.isDisabled;
          return isDisabled ? null : (
            <Flex justify="center">
              <Checkbox
                onChange={() => handleUpdateDoneScene(row.uid, !isChecked)}
                checked={isChecked}
              />
            </Flex>
          );
        },
      },
      {
        accessorKey: "picEdit",
        header: "PIC Edit",
        size: 200,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        enableSorting: false,
        Cell(record) {
          const row = record.row.original;
          const isDisabled = row?.isDisabled;
          return isDisabled ? null : (
            <AppSelect
              defaultValue={record.row.original.videoEditorId}
              options={listUserOptions}
              onChange={(e) => handleUpdateVideoEditor(row.uid, e.value)}
            />
          );
        },
      },
      {
        accessorKey: "linkVideos",
        header: "Link Videos",
        size: 120,
        enableEditing: true,
        mantineTableBodyCellProps: ({ row }) => {
          const videoThumbLinks = row.original?.videoThumbLinks || [];
          const videoLinks = row.original?.videoLinks || [];
          const isDiff = videoThumbLinks?.length !== videoLinks?.length;
          return {
            className: isDiff
              ? classes["body-cells-warning"]
              : classes["body-cells"],
          };
        },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        enableSorting: false,
        Cell(record) {
          const videoLinks = record.row.original?.videoLinks;
          const isDisabled = record.row.original?.isDisabled;

          return isDisabled ? null : (
            <Group
              style={{
                display: "flex",
                gap: "5px",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              {map(videoLinks, (link, index) => (
                <Link href={link} index={index + 1} />
              ))}
            </Group>
          );
        },
        Edit({ row }) {
          const videoLinks = row.original?.videoLinks;
          const adsLinks = row?.original?.adsLinks;
          const isDisabled = row?.original?.isDisabled;
          return isDisabled ? null : (
            <EditMultipleLink
              value={videoLinks}
              adsLinks={adsLinks}
              onBlurSuccess={(value) => {
                handleUpdateLinkVideo(row.original.uid, value);
              }}
            />
          );
        },
      },
      {
        accessorKey: "linkThumbs",
        header: "Link Thumbs",
        size: 120,
        enableEditing: true,
        mantineTableBodyCellProps: ({ row }) => {
          const videoThumbLinks = row?.original?.videoThumbLinks || [];
          const videoLinks = row?.original?.videoLinks || [];
          const isDiff = videoThumbLinks?.length !== videoLinks?.length;
          return {
            className: isDiff
              ? classes["body-cells-warning"]
              : classes["body-cells"],
          };
        },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        enableSorting: false,
        Cell(record) {
          const videoThumbLinks = record.row.original?.videoThumbLinks;
          const isDisabled = record.row.original?.isDisabled;
          return isDisabled ? null : (
            <Group
              style={{
                display: "flex",
                gap: "5px",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              {map(videoThumbLinks, (link, index) => (
                <Link href={link} index={index + 1} />
              ))}
            </Group>
          );
        },
        Edit({ row }) {
          const videoThumbLinks = row.original?.videoThumbLinks;
          const isDisabled = row.original?.isDisabled;

          return isDisabled ? null : (
            <EditMultipleLinkImages
              value={videoThumbLinks}
              onBlurSuccess={(value) => {
                handleUpdateVideoThumbLinks(row.original.uid, value);
              }}
            />
          );
        },
      },
      {
        header: "DONE",
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        enableSorting: false,
        Cell({ row }) {
          const isDone = row.original.videoStatus === 3;

          const videoLinks = row.original?.videoLinks;
          const videoThumbLinks = row.original?.videoThumbLinks;
          const isDisabledButton = row?.original?.isDisabled;

          const isDisabled =
            isEmpty(videoLinks) ||
            isEmpty(videoThumbLinks) ||
            !row.original?.videoEditorId;

          return isDisabledButton ? null : (
            <Button
              onClick={() => {
                if (isDone) {
                  handleIncompleteSample(row.original.uid);
                } else {
                  handleDoneSample(row.original.uid);
                }
              }}
              color={isDone ? "red" : "green"}
              disabled={isDisabled}
              loading={loading}
            >
              {isDone ? "Undone" : "Done"}
            </Button>
          );
        },
      },
      {
        accessorKey: "time",
        header: "TIME",
        size: 120,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
        Cell(record) {
          return (
            record?.row?.original?.videoInfo?.time ||
            record?.row?.original?.totalTime
          );
        },
      },
      {
        accessorKey: "remove",
        header: "ACTIONS",
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="filled"
                color="red"
                size="sx"
                onClick={() => {
                  openDeleteConfirmModal(row);
                }}
              >
                <IconX />
              </Button>
            </div>
          );
        },
      },
      {
        accessorKey: "archive",
        header: "ARCHIVE",
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["edit-header"] },
        Cell: ({ row }) => {
          const archive = row?.original?.archive;
          const isDisabled = row?.original?.isDisabled;
          const updateStatus =
            archive !== STATUS.ARCHIVED ? STATUS.ARCHIVED : STATUS.UN_ARCHIVED;
          return isDisabled ? null : (
            <Button
              variant="filled"
              color={archive === STATUS.ARCHIVED ? "red" : "green"}
              onClick={() => {
                openArchiveConfirmModal(row?.original, updateStatus);
              }}
            >
              {archive === STATUS.ARCHIVED ? (
                <Tooltip label="Un-Archive">
                  <IconArchiveOff />
                </Tooltip>
              ) : (
                <Tooltip label="Archive">
                  <IconArchive />
                </Tooltip>
              )}
            </Button>
          );
        },
      },
    ],
    data: data?.data || [],
    editDisplayMode: "cell",
    enableEditing: true,
    enablePagination: false,
    enableRowSelection: false,
    enableFilters: false,
    enableColumnActions: false,
    getRowId: (row) => row.id,
    renderTopToolbar: () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 5px",
            gap: "10px",
            flexWrap: "wrap-reverse",
          }}
        >
          <Flex
            style={{
              gap: "8px",
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: "#EFF0F1",
            }}
          >
            <TextInput
              placeholder="SKU"
              size="sm"
              width="100px"
              leftSection={
                <span
                  onClick={handleSubmitSKU}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <IconSearch size={16} />
                </span>
              }
              styles={{
                input: {
                  width: "100px",
                },
              }}
              value={searchSKU}
              onChange={handleChangeSKU}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmitSKU();
                }
              }}
            />
            <DateRangePicker
              size="sx"
              placeholder="Date"
              style={{
                width: "200px",
              }}
              value={query.dateValue}
              onOk={(value) => handleChangeDate(value, value[0], value[1])}
              onClean={() => handleChangeDate(null, null, null)}
              onShortcutClick={(shortcut) => {
                const { value } = shortcut;
                handleChangeDate(value, value[0], value[1]);
              }}
            />
            <Select
              clearable
              placeholder="Value"
              data={keys(CONVERT_STATUS_TO_NUMBER)}
              styles={{
                input: {
                  width: "150px",
                },
              }}
              value={query?.valueName}
              onChange={(value) => handleChangeSizeValue(value)}
              onClear={handleClearSizeValue}
            />
            <Select
              clearable
              placeholder="Project"
              data={keys(PROJECTS).map((key) => PROJECTS[key].VALUE)}
              styles={{
                input: {
                  width: "150px",
                },
              }}
              value={query?.projects?.[0] || null}
              onChange={(value) => handleChangeProject(value)}
              onClear={handleClearProject}
            />
            <Select
              clearable
              placeholder="Editor"
              data={map(filter(users, { position: "video" }), "name")}
              styles={{
                input: {
                  width: "150px",
                },
              }}
              value={query?.editor}
              onChange={(value) => handleSelectEditor(value)}
              onClear={handleResetEditor}
            />
            <Select
              clearable
              placeholder="Team"
              data={concat(UNIQUE_TEAMS, BD_TEAMS)}
              styles={{
                input: {
                  width: "100px",
                },
              }}
              value={query?.rndTeam}
              onChange={(value) => handleChangeTeam(value)}
              onClear={handleClearTeam}
              maxDropdownHeight={400}
              renderOption={({ option, checked }) => (
                <Group flex="1" gap="xs">
                  <span
                    style={{
                      color: includes(BD_TEAMS, option.label) ? "gray" : "",
                    }}
                  >
                    {option.label}
                  </span>
                  {checked && (
                    <IconCheck style={{ marginInlineStart: "auto" }} />
                  )}
                </Group>
              )}
            />
            <Select
              clearable
              placeholder="Done source"
              data={["Done", "Chưa Done", "All"]}
              styles={{
                input: {
                  width: "150px",
                },
              }}
              value={query?.isDoneSourceValue}
              onChange={(value) => handleCheckDoneSource(value)}
              onClear={handleResetIsDoneSource}
            />
            <Select
              clearable
              placeholder="Status"
              data={["Done", "Undone", "Archived"]}
              styles={{
                input: {
                  width: "120px",
                },
              }}
              value={query?.statusValue}
              onChange={handleChangeStatus}
              onClear={handleClearStatus}
            />
            <Button onClick={clearFilters}>
              <IconFilterOff />
            </Button>
          </Flex>
        </div>
      );
    },
    mantineTableBodyCellProps: ({ row, table, cell }) => ({
      onDoubleClick: () => {
        if (
          cell &&
          (cell.column.id === "linkVideos" ||
            cell.column.id === "linkThumbs" ||
            cell.column.id === "value" ||
            cell.column.id === "rndTeam")
        ) {
          table.setEditingCell(cell);
        }
      },
      onBlur: () => {
        table.setEditingCell(null);
      },
      style: {
        verticalAlign: "middle",
        textAlign: "center",
      },
    }),
    mantineExpandButtonProps: ({ row }) => {
      const isExpanded = row?.getIsExpanded();
      return {
        children: isExpanded ? (
          <IconChevronDown color="#228be6" />
        ) : (
          <IconChevronRight />
        ),
      };
    },
    enableStickyHeader: true,
    enableExpanding: true,
  });
  // CONFIRM ARCHIVE
  const openArchiveConfirmModal = (payload, status) =>
    modals.openConfirmModal({
      title: "Confirm Modal",
      centered: true,
      children: (
        <Text>Are you sure you want to call this action {payload?.sku}</Text>
      ),
      labels: { confirm: "Okay", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => handleArchiveBrief(payload?.uid, status),
    });
  return (
    <>
      <CardInformation {...data?.metadata} />
      <MantineReactTable table={table} />
      <Flex justify="flex-end">
        {pagination.totalPages && (
          <Pagination
            total={pagination.totalPages}
            value={pagination.page}
            onChange={handleChangePage}
            color="pink"
            size="md"
            style={{ marginTop: "20px", marginLeft: "auto" }}
          />
        )}
      </Flex>
      {openedModalNoteFromSample && selectedBrief && (
        <ModalEditNoteForVideoFromSample
          opened={openedModalNoteFromSample}
          close={closeModalNoteFromSample}
          selectedBrief={selectedBrief}
          handleUpdateNote={handleUpdateNote}
        />
      )}
    </>
  );
};

export default VideoTable;
