import React, { useState, useEffect } from "react";
import { TextInput, Stack, ActionIcon, Flex } from "@mantine/core";
import {
  IconPlus,
  IconEdit,
  IconTrash,
  IconCheck,
  IconX,
} from "@tabler/icons-react";
import styles from "./MultiTextLink.module.sass";
import TextLink from "../TextLink";

const MultiTextLink = ({
  value = [],
  onUpdate,
  placeholder = "Enter URL...",
  validateUrl,
  showError,
}) => {
  useEffect(() => {
    setLinks(value);
  }, [value]);
  const [links, setLinks] = useState(value);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editValue, setEditValue] = useState("");
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newLink, setNewLink] = useState("");

  const handleEditClick = (index, link) => {
    setEditingIndex(index);
    setEditValue(link);
  };

  const handleEditSave = async (index) => {
    if (validateUrl && !validateUrl(editValue)) {
      showError?.("Link không hợp lệ");
      return;
    }

    const updatedLinks = [...links];
    updatedLinks[index] = editValue;
    setLinks(updatedLinks);
    setEditingIndex(-1);
    await onUpdate?.(updatedLinks);
  };

  const handleDelete = async (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
    await onUpdate?.(updatedLinks);
  };

  const handleAddNew = async () => {
    if (!newLink) {
      setIsAddingNew(false);
      return;
    }

    if (validateUrl && !validateUrl(newLink)) {
      showError?.("Link không hợp lệ");
      return;
    }

    const updatedLinks = [...links, newLink];
    setLinks(updatedLinks);
    setNewLink("");
    setIsAddingNew(false);
    await onUpdate?.(updatedLinks);
  };

  const handleKeyDown = (e, type, index) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        setIsAddingNew(true);
      } else {
        type === "edit" ? handleEditSave(index) : handleAddNew();
      }
    } else if (e.key === "Escape") {
      type === "edit" ? setEditingIndex(-1) : setIsAddingNew(false);
    }
  };

  return (
    <Stack gap="xs" className={styles.container}>
      {links.map((link, index) => (
        <div key={index} className={styles.linkItem}>
          {editingIndex === index ? (
            <Flex gap="xs" align="center">
              <TextInput
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, "edit", index)}
                onBlur={() => handleEditSave(index)}
                placeholder={placeholder}
                autoFocus
                className={styles.input}
              />
              <ActionIcon
                color="blue"
                onClick={() => handleEditSave(index)}
                variant="subtle"
              >
                <IconCheck size={16} />
              </ActionIcon>
              <ActionIcon
                color="red"
                onClick={() => setEditingIndex(-1)}
                variant="subtle"
              >
                <IconX size={16} />
              </ActionIcon>
            </Flex>
          ) : (
            <Flex gap="xs" align="center">
              <TextLink href={link} />
              <ActionIcon
                color="blue"
                onClick={() => handleEditClick(index, link)}
                variant="subtle"
              >
                <IconEdit size={16} />
              </ActionIcon>
              <ActionIcon
                color="red"
                onClick={() => handleDelete(index)}
                variant="subtle"
              >
                <IconTrash size={16} />
              </ActionIcon>
            </Flex>
          )}
        </div>
      ))}

      {isAddingNew ? (
        <Flex gap="xs" align="center">
          <TextInput
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, "new")}
            onBlur={handleAddNew}
            placeholder={placeholder}
            autoFocus
            className={styles.input}
          />
          <ActionIcon color="blue" onClick={handleAddNew} variant="subtle">
            <IconCheck size={16} />
          </ActionIcon>
          <ActionIcon
            color="red"
            onClick={() => setIsAddingNew(false)}
            variant="subtle"
          >
            <IconX size={16} />
          </ActionIcon>
        </Flex>
      ) : (
        <ActionIcon
          color="blue"
          onClick={() => setIsAddingNew(true)}
          variant="subtle"
          className={styles.addButton}
        >
          <IconPlus size={16} />
        </ActionIcon>
      )}
    </Stack>
  );
};

export default MultiTextLink;
