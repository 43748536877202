import React, { useState, useMemo, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  Badge,
  Button,
  Flex,
  Group,
  Image,
  rem,
  Select,
  TextInput,
  FileButton,
} from "@mantine/core";
import Checkbox from "../../../components/Checkbox";
import { filter, find, get, isEmpty, map } from "lodash";
import { IconSearch, IconFilterOff, IconLink } from "@tabler/icons-react";
import classes from "./MyTable.module.css";
import { DateRangePicker } from "rsuite";
import {
  LOCAL_STORAGE_KEY,
  MEMBER_POSITIONS,
  NEW_PRODUCT_LINE_BRIEF_STATUS,
  DEPARTMENT_TEAMS,
} from "../../../constant";
import moment from "moment-timezone";
import { CONVERT_NUMBER_TO_STATUS } from "../../../utils";
import { productlineService } from "../../../services";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { showNotification } from "../../../utils/index";
import TextLink from "../../../components/TextLink";
import { uploadServices } from "../../../services/uploads";
import MultiTextLink from "../../../components/MultiTextLink";
import ImagePreview from "../../../components/ImagePreview";
import { ImageRefCell } from "../../../components/EditableCell";
import SelectValue from "../../../components/SelectValue";

const BriefsTable = ({
  briefs,
  query,
  setQuery,
  openModal,
  loadingFetchBrief,
  setTrigger,
  sorting,
  setSorting,
  metadata,
  users,
  setSelectedBrief,
}) => {
  const [loadingUpdateBriefUID, setLoadingUpdateBriefUID] = useState("");
  let [permissions] = useLocalStorage({
    key: LOCAL_STORAGE_KEY.PERMISSIONS,
    defaultValue: [],
  });
  const urlPattern =
    /^(https?:\/\/)((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[\w-]*)?$/i;
  const [payloads, setPayloads] = useState([]);
  permissions = map(permissions, "name");
  const [validationErrors, setValidationErrors] = useState({});
  const [data, setData] = useState(briefs || []);
  useEffect(() => {
    setData(briefs);
    setPayloads(briefs);
  }, [briefs]);

  const handleUpdateBrief = async ({ uid, data, isTrigger = false }) => {
    if (isTrigger) {
      setLoadingUpdateBriefUID(uid);
    }
    await productlineService.updateMockup({
      uid,
      data,
    });
    if (isTrigger) {
      setLoadingUpdateBriefUID("");
      setTrigger(true);
    }
  };

  const handleUploadImage = async (uid, file) => {
    try {
      setLoadingUpdateBriefUID(uid);

      const uploadResponse = await uploadServices.upload(file);
      const imageUrl = uploadResponse.data.url;

      await handleUpdateBrief({
        uid,
        data: { imageDesignTibLink: imageUrl },
        isTrigger: true,
      });
    } catch (error) {
      console.error("Failed to upload image:", error);
      showNotification("Thất bại", "Upload hình ảnh thất bại", "red");
    }
  };
  const handleUpdateValue = async (uid, value) => {
    setPayloads((prev) => {
      const newPayloads = map(prev, (x) => {
        if (x.uid === uid) {
          return {
            ...x,
            value,
          };
        }
        return x;
      });
      return newPayloads;
    });
    await handleUpdateBrief({
      uid,
      data: {
        value,
      },
      isTrigger: true,
    });
    table.setEditingCell(null);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        size: 120,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: true,
        accessorFn: (row) =>
          moment(row?.newProductLineInfo?.doneAt)
            .tz("Asia/Ho_Chi_Minh")
            .format("DD/MM/YYYY"),
      },
      {
        accessorKey: "rndTeam",
        header: "Team",
        size: 120,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: true,
      },
      {
        accessorKey: "productName",
        accessorFn: (row) => row?.name,
        header: "Tên Product",
        size: 120,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableSorting: false,
      },
      {
        accessorKey: "imageRef",
        header: "Hình Ref",
        size: 100,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => (
          <ImagePreview
            src={row?.original?.imageRef || "/images/content/not_found_2.jpg"}
            height={100}
            fit="contain"
          />
        ),
      },
      {
        accessorKey: "modal",
        header: "Brief",
        size: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          return (
            <Button
              leftSection={
                <IconLink
                  style={{
                    width: rem(16),
                    height: rem(16),
                  }}
                />
              }
              onClick={() => {
                setSelectedBrief(row.original);
                openModal();
              }}
            >
              Preview
            </Button>
          );
        },
      },
      {
        accessorKey: "value",
        header: "Value",
        size: 130,
        enableEditing: true,
        enableSorting: false,
        mantineTableHeadCellProps: { className: classes["SKU"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          let color = null;
          switch (row?.original?.value?.rnd) {
            case 1:
              color = "#cfcfcf";
              break;
            case 2:
              color = "yellow";
              break;
            case 3:
              color = "green";
              break;
            case 4:
              color = "#38761C";
              break;
            default:
              break;
          }
          return color ? (
            <Badge color={color} variant="filled">
              {CONVERT_NUMBER_TO_STATUS[row?.original?.value?.rnd]}
            </Badge>
          ) : (
            <span>{CONVERT_NUMBER_TO_STATUS[row?.original?.value?.rnd]}</span>
          );
        },
        Edit: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <SelectValue
              uid={row?.original?.uid}
              defaultValue={foundBrief?.value}
              handleChange={handleUpdateValue}
            />
          );
        },
      },
      {
        accessorKey: "priority",
        header: "Priority",
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: { className: classes["SKU"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        size: 100,
        Cell: ({ row }) => {
          const uid = row.original.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox value={foundBrief?.priority === 2} />
            </div>
          );
        },
      },
      {
        id: "linkTemplate",
        header: "Link Template (Library)",
        enableEditing: false,
        enableSorting: false,
        size: 170,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          const uid = row.original.uid;
          const foundBrief = find(payloads, { uid });
          return <TextLink href={foundBrief?.templateLink || ""} />;
        },
      },
      // {
      //   id: "linkDoc",
      //   header: "Brief Cho Mockup (Link Doc)",
      //   enableEditing: false,
      //   enableSorting: false,
      //   size: 150,
      //   mantineTableBodyCellProps: { className: classes["body-cells"] },
      //   Cell: ({ row }) => {
      //     const uid = row.original.uid;
      //     const foundBrief = find(payloads, { uid });
      //     return (
      //       <TextLink href={foundBrief?.readyToLaunchInfo?.docLink || ""} />
      //     );
      //   },
      // },
      {
        id: "linkProduct",
        header: "Link Product (Library)",
        enableEditing: false,
        enableSorting: false,
        size: 150,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          const uid = row.original.uid;
          const foundBrief = find(payloads, { uid });
          return <TextLink href={foundBrief?.productLink || ""} />;
        },
      },
      {
        accessorKey: "estimatedArrivalDate",
        header: "Dự kiến ngày có Sample",
        size: 120,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: false,
        enableSorting: false,
        Cell: ({ row }) => row.original.newPLSampleInfo?.estimatedArrivalDate,
      },
      {
        id: "mockupPic",
        header: "Mockup PIC",
        enableEditing: false,
        enableSorting: false,
        size: 150,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = row.original.uid;
          const foundBrief = find(payloads, { uid });
          const mockupPicNames = map(
            filter(users, { position: MEMBER_POSITIONS.MOCKUP }),
            "name"
          );
          return (
            <Select
              data={mockupPicNames || []}
              value={foundBrief?.mockup?.name || null}
              allowDeselect={false}
              onChange={(name) => {
                const foundMockup = find(users, { name });
                setPayloads((prev) => {
                  return map(prev, (x) => {
                    if (x.uid === uid) {
                      return {
                        ...x,
                        mockup: {
                          name,
                          uid: foundMockup?.uid,
                        },
                      };
                    }
                    return x;
                  });
                });
                const data = {
                  mockupId: foundMockup?.uid || "",
                };
                handleUpdateBrief({ uid, data });
              }}
            />
          );
        },
      },
      {
        accessorKey: "screenShot",
        header: "Chụp SP",
        enableSorting: false,
        enableEditing: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        size: 100,
        Cell: ({ row }) => {
          const uid = row.original.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox
                value={foundBrief?.isPhotography === true}
                onChange={() => {
                  const data = {
                    isPhotography:
                      foundBrief?.isPhotography === true ? false : true,
                  };
                  handleUpdateBrief({ uid, data, isTrigger: true });
                }}
              />
            </div>
          );
        },
      },
      {
        id: "photographyLink",
        header: "Hình chụp",
        enableEditing: false,
        enableSorting: false,
        size: 170,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          const uid = row.original.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <TextInput
              styles={{
                input: {
                  cursor: "pointer",
                },
              }}
              value={foundBrief?.photographyLink || ""}
              readOnly
              onClick={() => {
                if (foundBrief?.photographyLink) {
                  window.open(foundBrief?.photographyLink, "_blank");
                }
              }}
            />
          );
        },
      },
      {
        id: "mockupLinks",
        header: "Link Mockup (Library)",
        enableEditing: false,
        enableSorting: false,
        size: 170,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = row.original.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <MultiTextLink
              value={foundBrief?.mockupLinks || []}
              showError={(message) =>
                showNotification("Thất bại", message, "red")
              }
              onUpdate={(links) => {
                const data = {
                  mockupLinks: links,
                };
                setPayloads((prev) => {
                  return map(prev, (x) => {
                    if (x.uid === uid) {
                      return { ...x, mockupLinks: links };
                    }
                    return x;
                  });
                });
                handleUpdateBrief({
                  uid,
                  data,
                });
              }}
              placeholder="Enter mockup URL..."
            />
          );
        },
      },
      {
        id: "productBaseLinks",
        header: "Link Product Base (TIB)",
        enableEditing: false,
        enableSorting: false,
        size: 170,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = row.original.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <MultiTextLink
              value={foundBrief?.productBaseLinks || []}
              showError={(message) =>
                showNotification("Thất bại", message, "red")
              }
              onUpdate={(links) => {
                const data = {
                  productBaseLinks: links,
                };
                setPayloads((prev) => {
                  return map(prev, (x) => {
                    if (x.uid === uid) {
                      return { ...x, productBaseLinks: links };
                    }
                    return x;
                  });
                });
                handleUpdateBrief({
                  uid,
                  data,
                });
              }}
              placeholder="Enter product base URL..."
            />
          );
        },
      },
      {
        id: "imageDesignTibLink",
        header: "Hình Design (Test TiB)",
        enableEditing: false,
        enableSorting: false,
        size: 200,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = row.original.uid;
          const foundBrief = find(payloads, { uid });

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <ImagePreview
                src={foundBrief?.imageDesignTibLink}
                width={50}
                height={50}
                showDefaultImage={false}
              />
              <FileButton
                onChange={(file) => handleUploadImage(uid, file)}
                accept="image/png,image/jpeg,image/jpg"
              >
                {(props) => (
                  <Button
                    {...props}
                    loading={loadingUpdateBriefUID === uid}
                    size="xs"
                    variant="light"
                  >
                    {foundBrief?.imageDesignTibLink ? "Change" : "Upload"}
                  </Button>
                )}
              </FileButton>
            </div>
          );
        },
      },
      {
        accessorKey: "imageThumb",
        header: "Hình Thumb 1",
        size: 100,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = get(row, "original.uid");
          const foundBrief = find(payloads, { uid });
          const imageThumb = get(foundBrief, "imageThumb");

          const handleUploadSuccess = async (imageUrl) => {
            const updatePayload = map(payloads, (brief) =>
              get(brief, "uid") === uid
                ? { ...brief, imageThumb: imageUrl }
                : brief
            );
            setPayloads(updatePayload);
            await handleUpdateBrief({
              uid,
              data: { imageThumb: imageUrl },
            });
          };

          return (
            <ImageRefCell
              value={imageThumb}
              onUpload={async (file) => {
                const response = await uploadServices.upload(file);
                const imageUrl = get(response, "data.shortUrl");
                await handleUploadSuccess(imageUrl);
              }}
              config={{
                maxSize: 10,
                acceptedTypes: ["image/jpeg", "image/png"],
              }}
            />
          );
        },
      },
      {
        accessorKey: "status",
        header: "DONE",
        enableSorting: false,
        enableEditing: false,
        size: 150,
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = row.original.uid;
          const foundBrief = find(payloads, { uid });
          const isDone =
            foundBrief?.status === NEW_PRODUCT_LINE_BRIEF_STATUS.MOCKUP_DONE;
          return (
            <Group justify="center">
              <Button
                variant="filled"
                color={isDone ? "red" : "green"}
                size="sx"
                loading={loadingUpdateBriefUID === uid}
                onClick={() => {
                  if (
                    isEmpty(foundBrief?.mockupLinks) ||
                    isEmpty(foundBrief?.productBaseLinks) ||
                    (foundBrief?.isPhotography === true &&
                      isEmpty(foundBrief?.photographyLink))
                  ) {
                    showNotification(
                      "Thất bại",
                      "Vui lòng nhập đủ thông tin",
                      "red"
                    );
                    return;
                  }
                  const status = isDone
                    ? NEW_PRODUCT_LINE_BRIEF_STATUS.NEW_PRODUCT_LINE_DONE
                    : NEW_PRODUCT_LINE_BRIEF_STATUS.MOCKUP_DONE;
                  const data = {
                    status,
                  };
                  handleUpdateBrief({ uid, data, isTrigger: true });
                }}
              >
                {isDone ? "Undone" : "Done"}
              </Button>
            </Group>
          );
        },
      },
      {
        id: "time",
        accessorFn: (row) => row?.mockupInfo?.time,
        header: "TIME",
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        enableEditing: false,
        size: 50,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
      },
    ],
    [
      validationErrors,
      briefs,
      query,
      payloads,
      loadingUpdateBriefUID,
      users,
      setPayloads,
      handleUpdateBrief,
    ]
  );

  const [clipartName, setClipartName] = useState("");

  const table = useMantineReactTable({
    columns,
    data,
    editDisplayMode: "cell",
    enableEditing: true,
    enablePagination: false,
    getRowId: (row) => row.id,
    enableRowSelection: false,
    enableFilters: false,
    enableColumnActions: false,
    mantinePaperProps: {
      style: { "--mrt-striped-row-background-color": "#eff0f1" },
    },
    mantineTableHeadCellProps: { className: classes["head-cells"] },
    mantineTableProps: { striped: "even" },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    enableDensityToggle: false,
    renderTopToolbar: () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 5px",
            gap: "10px",
            flexWrap: "wrap-reverse",
          }}
        >
          <Flex
            style={{
              gap: "8px",
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: "#EFF0F1",
              flexWrap: "wrap",
            }}
          >
            <TextInput
              placeholder="Tên Product"
              size="sm"
              width="100px"
              leftSection={
                <span
                  onClick={() => {
                    setQuery({ ...query, name: clipartName });
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <IconSearch size={16} />
                </span>
              }
              styles={{
                input: {
                  width: "100px",
                },
              }}
              value={clipartName}
              onChange={(e) => setClipartName(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setQuery({ ...query, name: clipartName });
                }
              }}
            />
            <DateRangePicker
              size="sx"
              placeholder="Date"
              style={{
                width: "100px",
              }}
              value={query.dateValue}
              onOk={(value) =>
                setQuery({
                  ...query,
                  dateValue: value,
                  startDate: moment(value[0]).format("YYYY-MM-DD"),
                  endDate: moment(value[1]).format("YYYY-MM-DD"),
                })
              }
              onClean={() => {
                setQuery({
                  ...query,
                  dateValue: null,
                  date: null,
                  startDate: null,
                  endDate: null,
                });
              }}
              onShortcutClick={(shortcut) => {
                setQuery({
                  ...query,
                  dateValue: shortcut.value,
                  startDate: moment(shortcut.value[0]).format("YYYY-MM-DD"),
                  endDate: moment(shortcut.value[1]).format("YYYY-MM-DD"),
                });
              }}
            />

            <Select
              placeholder="Team"
              data={DEPARTMENT_TEAMS}
              styles={{
                input: {
                  width: "100px",
                },
              }}
              value={query?.rndTeam}
              onChange={(value) => setQuery({ ...query, rndTeam: value })}
              clearable
              onClear={() => {
                setQuery({
                  ...query,
                  rndTeam: null,
                });
              }}
            />
            <Select
              placeholder="Mockup"
              data={
                map(
                  filter(users, { position: MEMBER_POSITIONS.MOCKUP }),
                  "name"
                ) || []
              }
              styles={{
                input: {
                  width: "100px",
                },
              }}
              value={query?.mockupName}
              onChange={(value) =>
                setQuery({
                  ...query,
                  mockupName: find(users, { name: value })?.name,
                  mockupId: find(users, { name: value })?.uid,
                })
              }
              clearable
              onClear={() => {
                setQuery({
                  ...query,
                  mockupName: null,
                  mockupId: null,
                });
              }}
            />
            <Select
              placeholder="Status"
              data={["Done", "Undone"]}
              styles={{
                input: {
                  width: "120px",
                },
              }}
              value={query?.statusValue}
              onChange={(value) =>
                setQuery({
                  ...query,
                  status:
                    value === "Done"
                      ? [
                          NEW_PRODUCT_LINE_BRIEF_STATUS.MOCKUP_DONE,
                          NEW_PRODUCT_LINE_BRIEF_STATUS.EPM_DONE_PRODUCT_BASE,
                        ]
                      : [NEW_PRODUCT_LINE_BRIEF_STATUS.NEW_PRODUCT_LINE_DONE],
                  statusValue: value,
                })
              }
              clearable
              onClear={() => {
                setQuery({
                  ...query,
                  status: [
                    NEW_PRODUCT_LINE_BRIEF_STATUS.MOCKUP_DONE,
                    NEW_PRODUCT_LINE_BRIEF_STATUS.NEW_PRODUCT_LINE_DONE,
                    NEW_PRODUCT_LINE_BRIEF_STATUS.EPM_DONE_PRODUCT_BASE,
                  ],
                  statusValue: null,
                });
              }}
            />
            <Button
              onClick={() => {
                setQuery({
                  date: null,
                  name: "",
                  size: null,
                  rndTeam: null,
                  rndId: null,
                  epm: null,
                  status: [
                    NEW_PRODUCT_LINE_BRIEF_STATUS.MOCKUP_DONE,
                    NEW_PRODUCT_LINE_BRIEF_STATUS.NEW_PRODUCT_LINE_DONE,
                    NEW_PRODUCT_LINE_BRIEF_STATUS.EPM_DONE_PRODUCT_BASE,
                  ],
                  sizeValue: null,
                  rndName: null,
                  statusValue: null,
                  dateValue: null,
                  startDate: null,
                  endDate: null,
                  mockupName: null,
                  mockupId: null,
                });
                setClipartName("");
              }}
            >
              <IconFilterOff />
            </Button>
          </Flex>
          <Flex
            style={{
              gap: "30px",
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: "#EFF0F1",
            }}
            justify="end"
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Undone: {metadata?.totalUndoneNewProductLineBriefsWithFilter}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Time to done:{" "}
              {metadata?.totalTimeToDoneNewProductLineBriefsWithFilter}h
            </div>
          </Flex>
        </div>
      );
    },
    state: {
      showProgressBars: loadingFetchBrief,
      sorting,
    },
    mantineTableBodyCellProps: () => ({
      className: classes["body-cells"],
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    onSortingChange: setSorting,
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default BriefsTable;
