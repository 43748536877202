import {
  Box,
  Flex,
  Group,
  Title,
  Radio,
  Select,
  MultiSelect,
  Stack,
} from "@mantine/core";
import { userServices } from "../../services/users";
import { dashboardServices } from "../../services/dashboards";
import { DateRangePicker } from "rsuite";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { useState, useEffect } from "react";
import DynamicTable from "../DashboardPersonal/table/table";
import {
  uniq,
  map,
  capitalize,
  fromPairs,
  values,
  startCase,
  camelCase,
  flatMap,
  difference,
  keys,
  omit,
} from "lodash";

export default function DashboardUIDTeam() {
  dayjs.extend(isoWeek);
  const [filter, setFilter] = useState({});
  const [filterDepartment, setFilterDepartment] = useState({});

  const [users, setUsers] = useState([]);
  const [positions, setPositions] = useState([]);
  const [positionValue, setPositionValue] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(7, "day").toDate(), // 7 ngày trước
    dayjs().toDate(), // Ngày hiện tại
  ]);

  const [summaryColumns, setSummaryColumns] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(false);

  const [departmentColumns, setDepartmentColumns] = useState();
  const [departmentData, setDepartmentData] = useState({});
  const [departmentLoading, setDepartmentLoading] = useState(false);

  const breakdown = [
    {
      label: "Day",
      value: "day",
    },
    {
      label: "Week",
      value: "week",
    },
    {
      label: "Month",
      value: "month",
    },
  ];
  const [breakdownSelected, setBreakdownSelected] = useState("week");

  const fetchUsers = async () => {
    const response = await userServices.fetchUsers({
      page: 0,
      limit: 0,
    });
    getPositions(response?.data);
    // setUsers(response?.data);
  };

  const getPositions = (users) => {
    const positions = uniq(map(users, "position"));
    // console.log("positions ", positions);
    setPositions(positions);
    return positions;
  };

  function transformData(data) {
    // Lấy danh sách các name
    const names = map(data, "name");

    // Lấy danh sách các summary
    const summaries = map(data, "summary");

    // Lấy danh sách các key trong summary (theo thứ tự)
    const summaryKeys = Object.keys(summaries[0]); // ['totalCards', 'totalDoneCards', ...]

    // Hàm format key thành dạng title case với khoảng trắng
    const formatMetric = (key) => {
      return startCase(camelCase(key)); // Chuyển camelCase thành title case
    };

    // Tạo mảng kết quả
    const result = summaryKeys.map((summaryKey, index) => {
      const dataObject = fromPairs(
        names.map((name, i) => [name, values(summaries[i])[index]])
      );
      return {
        ...dataObject,
        metric: formatMetric(summaryKey), // Format lại metric
      };
    });

    return result;
  }

  const fetchDataReportSummaryDepartments = async () => {
    try {
      setSummaryLoading(true);
      const response =
        await dashboardServices.fetchDataReportSummaryDepartments({
          query: filter,
        });
      // console.log("response Summary Departments", response);
      const columns = map(response?.data, (item) => {
        return {
          accessorKey: item?.name,
          header: capitalize(item?.name),
        };
      });
      columns.unshift({
        accessorKey: "metric",
        header: "",
      });
      // console.log("  columns  ", columns);
      let data = transformData(response?.data);
      // console.log("  data  ", data);
      setSummaryColumns(columns);
      setSummaryData(data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setSummaryLoading(false);
    }
  };

  // hàm xử lý data theo breakdown (week,day,month)
  function groupOrdersByTime(data) {
    const formatDateInData = map(data, (item) => {
      return {
        ...item,
        date:
          breakdownSelected === "day"
            ? dayjs(item.date).format("YYYY-MM-DD")
            : breakdownSelected === "week"
            ? `Week ${dayjs(item.date).isoWeek()}`
            : dayjs(item.date).format("MMMM"),
      };
    });

    // Lấy danh sách các key trong object (ngoài 'date')
    const metricKeys = keys(omit(formatDateInData[0], "date")); // ['totalCards', 'totalDoneCards', ...]

    // Tạo mảng kết quả
    const result = metricKeys.map((metric) => {
      // Tạo object với key là date và value là giá trị của metric
      const dataObject = fromPairs(
        formatDateInData.map((item) => [item.date, item[metric]])
      );
      // Thêm field metric
      return {
        metric,
        ...dataObject,
      };
    });

    return result;
  }

  //  breakdown by dateRange
  function breakdownByDateRange(from, to, type) {
    if (!from || !to) return [];

    const startDate = dayjs(from);
    const endDate = dayjs(to);
    const result = [];

    switch (type) {
      case "day": {
        let currentDate = startDate;
        while (
          currentDate.isBefore(endDate) ||
          currentDate.isSame(endDate, "day")
        ) {
          result.push(currentDate.format("YYYY-MM-DD"));
          currentDate = currentDate.add(1, "day");
        }
        break;
      }

      case "week": {
        let currentWeek = startDate;
        while (
          currentWeek.isBefore(endDate) ||
          currentWeek.isSame(endDate, "week")
        ) {
          result.push(`Week ${currentWeek.isoWeek()}`);
          currentWeek = currentWeek.add(1, "week");
        }
        break;
      }

      case "month": {
        let currentMonth = startDate;
        while (
          currentMonth.isBefore(endDate) ||
          currentMonth.isSame(endDate, "month")
        ) {
          result.push(currentMonth.format("MMMM"));
          currentMonth = currentMonth.add(1, "month");
        }
        break;
      }

      default:
        throw new Error("Invalid type. Use 'day', 'week', or 'month'.");
    }

    const columnsByBreakdown = result.map((item) => {
      return {
        accessorKey: item,
        header: item,
      };
    });

    return columnsByBreakdown;
  }

  const fetchDataReportDepartments = async () => {
    try {
      setDepartmentLoading(true);
      const response = await dashboardServices.fetchDataReportDepartments({
        query: filter,
      });

      const from = dayjs(dateRange?.[0]).format();
      const to = dayjs(dateRange?.[1]).format();
      const columns = breakdownByDateRange(from, to, breakdownSelected);
      columns.unshift({
        accessorKey: "metric",
        header: "",
      });

      const data = map(response?.data, (item) => ({
        ...item,
        data: item.data
          ? groupOrdersByTime(item.data, breakdownSelected ?? "week")
          : [],
      }));
      console.log("columns", columns);
      setDepartmentColumns(columns);
      setDepartmentData(data);
      console.log("response Departments", data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setDepartmentLoading(false);
    }
  };

  const changeTimeline = (value) => {
    if (value === "7-days") {
      setDateRange([dayjs().subtract(7, "day").toDate(), dayjs().toDate()]);
    } else if (value === "3-days") {
      setDateRange([dayjs().subtract(3, "day").toDate(), dayjs().toDate()]);
    } else if (value === "30-days") {
      setDateRange([dayjs().subtract(30, "day").toDate(), dayjs().toDate()]);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchDataReportSummaryDepartments();
    fetchDataReportDepartments();
  }, []);

  useEffect(() => {
    fetchDataReportDepartments();
  }, [dateRange, breakdownSelected]);

  useEffect(() => {
    fetchDataReportSummaryDepartments();
  }, [dateRange]);

  const dynamicTable = (data, columns, isLoading = false) => (
    <DynamicTable
      columns={columns}
      data={data}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={true}
      pageSize={10}
      isLoading={isLoading}
    />
  );

  return (
    <Flex p="lg" gap="xl" justify="center" direction="column" align="center">
      <Box w={"100%"} mx="auto">
        <Group>
          <DateRangePicker
            onChange={(value) => {
              setFilter({
                ...filter,
                from: dayjs(value?.[0]).format(),
                to: dayjs(value?.[1]).format(),
              });
              setDateRange(value);
            }}
            value={dateRange}
            defaultValue={dateRange}
            placeholder="Date"
          />
          <Radio.Group
            defaultValue="7-days"
            onChange={(value) => {
              changeTimeline(value);
            }}
          >
            <Group mt="xs">
              <Radio value="3-days" label="3 day" />
              <Radio value="7-days" label="7 day" />
              <Radio value="30-days" label="30 day" />
            </Group>
          </Radio.Group>
        </Group>
      </Box>

      <Title order={2}>Summary</Title>

      <Flex w={"100%"} gap="xl">
        <Box w={"100%"}>
          {dynamicTable(summaryData, summaryColumns, summaryLoading)}
        </Box>
      </Flex>

      <Title order={2}>Department</Title>

      <Group w={"100%"}>
        <MultiSelect
          w={"60%"}
          label="Department"
          data={positions}
          value={positionValue}
          onChange={setPositionValue}
          style={{ overflow: "hidden" }}
        />
        <Select
          w={"20%"}
          data={breakdown}
          label="Breakdown"
          onChange={(value) => {
            setBreakdownSelected(value);
          }}
          defaultValue="week"
        />
      </Group>

      <Stack gap="xl" w={"100%"}>
        {departmentColumns &&
          departmentData &&
          departmentData.map((item) => (
            <Stack gap="xs">
              <Title order={3}>{startCase(camelCase(item?.name))}</Title>
              <DynamicTable
                columns={departmentColumns}
                data={item.data}
                enableColumnFilters={false}
                enablePagination={false}
                enableSorting={true}
                isLoading={departmentLoading}
              />
            </Stack>
          ))}
      </Stack>
    </Flex>
  );
}
