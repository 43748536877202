import { useEffect, useState } from "react";
import { supplierServices } from "../services";

const useGetSupplierAttribute = () => {
  const [supplierAttributes, setSupplierAttributes] = useState([]);

  const fetchSupplier = async () => {
    const response = await supplierServices.fetchSuppliers({
      page: 1,
      limit: -1,
    });
    setSupplierAttributes(response?.data || []);
  };

  useEffect(() => {
    fetchSupplier();
  }, []);

  return {
    supplierAttributes,
  };
};

export default useGetSupplierAttribute;
