import { filter, isEmpty, keys, omit, pick, reduce } from "lodash";
import apiClient from "./axiosClient";

export const supplierServices = {
  getSupplierAttribute: async () => {
    const response = await apiClient.get("/supplier-attribute");
    return response.data;
  },

  fetchSuppliers: async ({ page, limit, query, sorting }) => {
    try {
      let url = `/supplier-attributes?page=${page}&pageSize=${limit}`;
      const queryKeys = keys(query);
      const transformedQuery = filter(queryKeys, (key) => query[key]);
      const sort = reduce(
        sorting,
        (accumulate, item) => {
          accumulate[item.id] = item.desc ? "desc" : "asc";
          return accumulate;
        },
        {}
      );
      if (!isEmpty(transformedQuery)) {
        let pickQuery = pick(query, transformedQuery);
        pickQuery = omit(pickQuery, ["statusValue", "valueName", "dateValue"]);
        const queryString = `filter=${encodeURIComponent(
          JSON.stringify({
            ...pickQuery,
          })
        )}`;
        url = `${url}&${queryString}`;
      }
      if (!isEmpty(sort)) {
        const sortString = `sort=${encodeURIComponent(JSON.stringify(sort))}`;
        url = `${url}&${sortString}`;
      }

      const response = await apiClient.get(url);

      const { data: result } = response;
      if (result?.success === false) {
        return false;
      }
      return result;
    } catch (error) {
      console.log("Error at fetchProductLines:", error);
      return false;
    }
  },
};
