import React from "react";
import { Group } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { filter, isEmpty, map, toPairs } from "lodash";
import styles from "./ActionButtons.module.sass";

const ActionButtons = ({
  isDraft,
  isLoading,
  isDone,
  onDone,
  onAdd,
  onDelete,
  onRemove,
  disabled,
  validateFields = {},
  showError,
}) => {
  const handleAction = (action) => {
    if (isDraft) {
      const missingFields = map(
        filter(toPairs(validateFields), ([_, value]) => isEmpty(value)),
        ([key]) => key
      );

      if (missingFields.length > 0) {
        showError?.("Vui lòng nhập đủ thông tin & upload ảnh");
        return;
      }
    }
    action?.();
  };

  return (
    <Group gap={10} justify="center" w="100%">
      <div
        className={`${styles.actionButton} ${styles.success} ${
          isLoading ? styles.loading : ""
        }`}
        onClick={() => handleAction(isDraft ? onAdd : onDone)}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <IconCheck size={16} />
      </div>
      <div
        className={`${styles.actionButton} ${styles.error}`}
        onClick={() => {
          if (isDraft) {
            onRemove?.();
          } else {
            onDelete?.();
          }
        }}
      >
        <IconX size={16} />
      </div>
    </Group>
  );
};

export default ActionButtons;
