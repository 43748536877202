import React, { useState } from "react";
import { TextInput } from "@mantine/core";
import { get, isNil, isEmpty } from "lodash";

const DEFAULT_CONFIG = {
  validatePattern: null,
};

const ERROR_MESSAGES = {
  INVALID: "Giá trị không hợp lệ",
  UPDATE_FAILED: "Cập nhật thất bại",
};

const TextCell = ({
  value,
  onChange,
  onDraftChange,
  onUpdate,
  placeholder,
  showError,
  isDraft = false,
  config = {},
}) => {
  const { validatePattern } = { ...DEFAULT_CONFIG, ...config };

  const [inputValue, setInputValue] = useState(value);

  const validateValue = (value) => {
    if (isEmpty(value)) return true;
    if (!isNil(validatePattern) && !validatePattern.test(value)) {
      showError?.(ERROR_MESSAGES.INVALID);
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const newValue = get(e, "target.value", "");
    setInputValue(newValue);
    const handler = isDraft ? onDraftChange : onChange;
    handler?.(newValue);
  };

  const handleBlur = async (e) => {
    const value = get(e, "target.value", "");
    if (!validateValue(value)) {
      setInputValue("");

      return;
    }

    if (!isDraft) {
      try {
        await onUpdate?.(inputValue);
      } catch (error) {
        showError?.(get(error, "message", ERROR_MESSAGES.UPDATE_FAILED));
        setInputValue("");
      }
    }
  };

  return (
    <TextInput
      value={inputValue || ""}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
    />
  );
};

export default TextCell;
