import React from "react";
import Card from "../../components/Card";
import Table from "./Table";
import cn from "classnames";
import styles from "./ProductBaseForEPM.module.sass";
import { useProductBaseForEPM } from "./hooks/useProductBaseForEPM.hook";

const ProductBaseForEPM = () => {
  const {
    query,
    products,
    loading,
    epms,
    pagination,
    updateProduct,
    setQuery,
  } = useProductBaseForEPM();

  return (
    <Card
      className={styles.card}
      title="POD Product Base Task"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={cn(styles.head)}
    >
      <Table
        data={products}
        loading={loading}
        updateProduct={updateProduct}
        epms={epms}
        query={query}
        setQuery={setQuery}
        pagination={pagination}
      />
    </Card>
  );
};

export default ProductBaseForEPM;
