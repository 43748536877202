import React from "react";
import { Modal } from "@mantine/core";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styles from "./CompetitorChart.module.sass";

const CompetitorChart = ({ opened, onClose, data }) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Tình hình đối thủ"
      size="xl"
    >
      <div className={styles.chartWrapper}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="key" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="ordersChange"
              name="Orders Change"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="rank"
              name="Rank"
              stroke="#82ca9d"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>

        <div className={styles.statsGrid}>
          <div className={styles.statCard}>
            <div className={styles.statLabel}>Orders Change (Latest)</div>
            <div className={styles.statValue}>{data[0]?.ordersChange || 0}</div>
          </div>
          <div className={styles.statCard}>
            <div className={styles.statLabel}>Rank Change (Latest)</div>
            <div className={styles.statValue}>{data[0]?.rankChange || 0}</div>
          </div>
          <div className={styles.statCard}>
            <div className={styles.statLabel}>Current Rank</div>
            <div className={styles.statValue}>{data[0]?.rank || 0}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompetitorChart;
