import React from "react";
import styles from "./SampleRequest.module.sass";
import Table from "./Table";
import Card from "../../components/Card";
import cn from "classnames";
import { useSampleRequest } from "./hooks/useSampleRequest.hook";

const SampleRequest = () => {
  const {
    query,
    sampleRequests,
    loading,
    pagination,
    setQuery,
    fetchSampleRequests,
    createSampleRequest,
    updateSampleRequest,
    setPagination,
  } = useSampleRequest();

  return (
    <Card
      className={styles.card}
      title="Sample Request"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={cn(styles.head)}
    >
      <Table
        data={sampleRequests}
        loading={loading}
        createSampleRequest={createSampleRequest}
        updateSampleRequest={updateSampleRequest}
        fetchSampleRequests={fetchSampleRequests}
        pagination={pagination}
        query={query}
        setQuery={setQuery}
        setPagination={setPagination}
      />
    </Card>
  );
};

export default SampleRequest;
