import React, { useEffect, useRef, useState, useMemo } from "react";
import styles from "./RankingPODShopifyProducts.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import {
  ActionIcon,
  Checkbox,
  Flex,
  Grid,
  Group,
  Image,
  Loader,
  Modal,
  Radio,
  Select,
  Text,
  TextInput,
  Tooltip,
  Stack,
  Button,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { rankingServices } from "../../services";
import Table from "./Table";
import {
  filter,
  includes,
  isEmpty,
  map,
  omit,
  toNumber,
  uniq,
  values,
  uniqBy,
  find,
} from "lodash";
import moment from "moment-timezone";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconPlus } from "@tabler/icons-react";
import { showNotification } from "../../utils/index";
import {
  CONVERT_NUMBER_TO_RANKING_STATUS,
  CONVERT_STATUS_TO_RANKING_NUMBER,
  RANKING_STATUS,
} from "../../constant";
import SearchText from "../../components/SearchText";
import StoreListModal from "../../components/StoreListModal";

const TARGET_DATES = {
  TODAY: "Today",
  THREE_DAYS: "3 Days",
  SEVEN_DAYS: "7 Days",
};
const TARGET_MODES = {
  ORDERS: "Orders",
  RANKING: "Change Rank",
  DEFAULT_RANKING: "Rank",
};

const moveIdsToStart = (array, ids) => {
  return array.sort((a, b) => {
    if (ids.includes(a.id) && !ids.includes(b.id)) {
      return -1;
    }
    if (!ids.includes(a.id) && ids.includes(b.id)) {
      return 1;
    }
    return 0;
  });
};

const CompetitorGroup = ({
  competitors,
  selectedValue,
  onChange,
  selectedCategory,
}) => {
  if (isEmpty(competitors)) return null;

  const accumulated = competitors.filter((comp) => comp.accumulatedRankingLink);
  const trending = competitors.filter((comp) => comp.trendingRankingLink);

  // Parse the selected value to get competitor name and type
  const [selectedName, selectedType] = (selectedValue || "").split("__");

  return (
    <Stack spacing="xs">
      {trending.length > 0 && (
        <div className={styles.competitorGroup}>
          <Text className={styles.groupTitle}>{selectedCategory} 2500</Text>
          <Radio.Group
            value={selectedType === "2500" ? selectedValue : ""}
            onChange={onChange}
          >
            <Group spacing="md">
              {trending.map((comp) => (
                <Radio
                  key={comp._id}
                  value={`${comp.name}__2500`}
                  label={comp.shortName || comp.name}
                  className={styles.radio}
                />
              ))}
            </Group>
          </Radio.Group>
        </div>
      )}
      {accumulated.length > 0 && (
        <div className={styles.competitorGroup}>
          <Text className={styles.groupTitle}>{selectedCategory} LT</Text>
          <Radio.Group
            value={selectedType === "LT" ? selectedValue : ""}
            onChange={onChange}
          >
            <Group spacing="md">
              {accumulated.map((comp) => (
                <Radio
                  key={comp._id}
                  value={`${comp.name}__LT`}
                  label={comp.shortName || comp.name}
                  className={styles.radio}
                />
              ))}
            </Group>
          </Radio.Group>
        </div>
      )}
    </Stack>
  );
};

const RankingPODShopifyProducts = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [
    openedPreviewImage,
    { close: closePreviewImage, open: openPreviewImage },
  ] = useDisclosure(false);
  const [visible, setVisible] = useState(true);
  const [overrideProductRankings, setOverrideProductRankings] = useState([]);
  const [isLoadmore, setIsLoadmore] = useState(false);
  const [productRankings, setProductRankings] = useState([]);
  const initialPage = parseInt(queryParams.get("page") || "1", 10);
  const [competitors, setCompetitors] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: initialPage,
    totalPages: 1,
  });
  const endDate = moment().format("YYYY-MM-DD");
  const [query, setQuery] = useState({
    competitor: "Wanderprints",
    mode: [TARGET_MODES.RANKING],
    targetDate: TARGET_DATES.THREE_DAYS,
    dateRange: 3,
    sortBy: "totalRankChanges",
    sortDir: "desc",
  });
  const [sorting, setSorting] = useState([]);
  const isMounted = useRef(false);
  const [trigger, setTrigger] = useState(false);
  const [loadingFetchRankings, setLoadingFetchRankings] = useState(true);
  const [
    openedStoreListModal,
    { open: openStoreListModal, close: closeStoreListModal },
  ] = useDisclosure(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [allCompetitors, setAllCompetitors] = useState([]);
  const isInitialMount = useRef(true);

  const categories = useMemo(() => {
    const uniqueCategories = uniqBy(
      filter(competitors, (comp) => comp?.category),
      "category"
    ).map((comp) => ({
      value: comp.category || "",
      label: comp.category || "",
    }));

    return [
      {
        value: "",
        label: "All",
      },
      ...uniqueCategories,
    ];
  }, [competitors]);

  const groupedCompetitors = useMemo(() => {
    if (!selectedCategory) return null;

    const categoryCompetitors = filter(competitors, {
      category: selectedCategory,
    });

    return {
      accumulated: filter(
        categoryCompetitors,
        (comp) => comp.accumulatedRankingLink
      ),
      trending: filter(categoryCompetitors, (comp) => comp.trendingRankingLink),
    };
  }, [selectedCategory, competitors]);

  useEffect(() => {
    if (groupedCompetitors) {
      const defaultComp =
        groupedCompetitors.accumulated[0] || groupedCompetitors.trending[0];
      if (defaultComp && !query.competitor) {
        setQuery((prev) => ({
          ...prev,
          competitor: defaultComp.name,
        }));
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    const categoryCompetitors = selectedCategory
      ? filter(competitors, {
          category: selectedCategory,
        })
      : competitors;
    const defaultComp =
      find(categoryCompetitors, (comp) => comp.accumulatedRankingLink) ||
      categoryCompetitors?.[0];
    if (defaultComp) {
      // Add type suffix based on which link exists
      const type = defaultComp.accumulatedRankingLink ? "LT" : "2500";
      setQuery((prev) => ({
        ...prev,
        competitor: `${defaultComp.name}__${type}`,
        competitorType: type,
      }));
    }
  }, [selectedCategory]);

  const fetchRankings = async (page) => {
    setLoadingFetchRankings(true);
    const [competitorName, competitorType] = query.competitor.split("__");
    const response = await rankingServices.fetchRankingMetrics({
      page,
      query: omit(
        {
          ...query,
          competitor: competitorName,
          isTrending: competitorType === "2500",
          view:
            query.mode[0] === TARGET_MODES.ORDERS
              ? "order"
              : query.mode[0] === TARGET_MODES.RANKING
              ? "rankChange"
              : "rank",
        },
        [
          "sortValue",
          "storeValues",
          "dateValue",
          "isConfirmed",
          "fulfillmentChannelValues",
          "salesDateValue",
          "mode",
          "startDate",
          "endDate",
          "targetDate",
          "competitorType",
        ]
      ),
      limit: 50,
      sorting,
      ignoreEmptyKeys: ["follow", "isTrending"],
    });
    const { data, metadata } = response;
    if (!isEmpty(data)) {
      if (isLoadmore) {
        const oldProductRankings = map(productRankings, (x) => {
          if (includes(overrideProductRankings, x.id)) {
            return {
              ...x,
              follow: 1,
            };
          }
          return {
            ...x,
          };
        });
        const newProductRankings = [...oldProductRankings, ...data];
        const sortedProductRankings = moveIdsToStart(
          newProductRankings,
          uniq(overrideProductRankings)
        );
        setProductRankings(sortedProductRankings);
      } else {
        const sortedProductRankings = moveIdsToStart(
          data,
          uniq(overrideProductRankings)
        );
        setProductRankings(sortedProductRankings);
      }
      setPagination({
        currentPage: toNumber(metadata.currentPage) || 1,
        totalPages: toNumber(metadata.totalPages) || 1,
      });
    } else {
      setProductRankings([]);
    }
    // setIsConfirmedQuery(false);
    setIsLoadmore(false);
    setLoadingFetchRankings(false);
    setTrigger(false);
  };
  const fetchCompetitors = async () => {
    const competitors = await rankingServices.fetchCompetitors();
    setAllCompetitors(competitors);
    const aliveCompetitors = filter(competitors, { alive: true });
    setCompetitors(aliveCompetitors);

    // Set default competitor from the first alive competitor
    if (!isEmpty(aliveCompetitors)) {
      const defaultComp =
        aliveCompetitors.find((comp) => comp.accumulatedRankingLink) ||
        aliveCompetitors[0];
      if (defaultComp) {
        const type = defaultComp.accumulatedRankingLink ? "LT" : "2500";
        setQuery((prev) => ({
          ...prev,
          competitor: `${defaultComp.name}__${type}`,
          competitorType: type,
        }));
      }
    }
  };
  useEffect(() => {
    if (!isInitialMount.current) {
      fetchRankings(pagination.currentPage);
    }
  }, [query, trigger, sorting, pagination.currentPage]);

  useEffect(() => {
    fetchCompetitors();
    isInitialMount.current = false;
  }, []);

  const [fromRank, setFromRank] = useState(0);
  const [toRank, setToRank] = useState(0);

  // listen sorting change set isConfirmedQuery to true for refetch data
  useEffect(() => {
    if (isMounted.current) {
      if (!isEmpty(sorting)) {
        setPagination({
          ...pagination,
          currentPage: 1,
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [sorting]);

  return (
    <>
      <div className={styles.container}>
        <Card
          className={styles.card}
          title="POD Ranking"
          classTitle={cn("title-purple", styles.title)}
          classCardHead={cn(styles.head, { [styles.hidden]: visible })}
          head={
            <Flex
              justify="end"
              align="center"
              gap="sm"
              style={{ width: "100%" }}
            >
              <SearchText
                onSearch={(value) => {
                  setQuery({
                    ...query,
                    title: value,
                  });
                }}
                style={{ flex: 1 }}
              />
              <Button
                leftSection={<IconPlus size={14} />}
                variant="light"
                onClick={openStoreListModal}
                style={{ whiteSpace: "nowrap" }}
              >
                Manage Competitors
              </Button>
            </Flex>
          }
        >
          <Grid>
            <Grid.Col span={12}>
              <Flex gap="md" direction="column">
                <Stack>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "10px 0px",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <Flex
                      style={{
                        padding: "10px",
                        borderRadius: "10px",
                        backgroundColor: "rgb(239 240 241 / 32%)",
                        width: "100%",
                      }}
                    >
                      <Grid.Col
                        span={3}
                        style={{
                          gap: "5px",
                          padding: "10px",
                          borderRadius: "10px",
                          backgroundColor: "#e2eaff",
                          flexWrap: "wrap",
                          width: "100%",
                          minHeight: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          border: "1px solid #4f80ff",
                          borderColor: "#4f80ff",
                          marginRight: "2px",
                        }}
                      >
                        <Radio.Group
                          value={query?.targetDate}
                          onChange={(value) => {
                            setPagination({
                              ...pagination,
                              currentPage: 1,
                            });
                            setFromRank("");
                            setToRank("");

                            switch (value) {
                              case TARGET_DATES.TODAY:
                                setQuery({
                                  ...query,
                                  fromRank: "",
                                  toRank: "",
                                  dateRange: 1,
                                  targetDate: value,
                                });
                                break;
                              case TARGET_DATES.THREE_DAYS:
                                setQuery({
                                  ...query,
                                  fromRank: "",
                                  toRank: "",
                                  dateRange: 3,
                                  targetDate: value,
                                });
                                break;
                              case TARGET_DATES.SEVEN_DAYS:
                                setQuery({
                                  ...query,
                                  fromRank: "",
                                  toRank: "",
                                  dateRange: 7,
                                  targetDate: value,
                                });
                                break;
                              default:
                                break;
                            }
                          }}
                        >
                          <Group
                            styles={{
                              root: {
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                              },
                            }}
                          >
                            <Radio
                              styles={{
                                input: {
                                  borderRadius: "50%",
                                },
                              }}
                              value={TARGET_DATES.TODAY}
                              label={TARGET_DATES.TODAY}
                            />
                            <Radio
                              styles={{
                                input: {
                                  borderRadius: "50%",
                                },
                              }}
                              value={TARGET_DATES.THREE_DAYS}
                              label={TARGET_DATES.THREE_DAYS}
                            />
                            <Radio
                              styles={{
                                input: {
                                  borderRadius: "50%",
                                },
                              }}
                              value={TARGET_DATES.SEVEN_DAYS}
                              label={TARGET_DATES.SEVEN_DAYS}
                            />
                          </Group>
                        </Radio.Group>
                      </Grid.Col>
                      <Grid.Col
                        span={4}
                        style={{
                          gap: "5px",
                          borderRadius: "10px",
                          flexWrap: "wrap",
                          width: "100%",
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          marginRight: "2px",
                        }}
                      >
                        <Group
                          justify="start"
                          style={{
                            height: "100%",
                          }}
                        >
                          <Grid
                            style={{
                              height: "100%",
                            }}
                          >
                            <Grid.Col span={3.5}>
                              <TextInput
                                value={fromRank || ""}
                                placeholder="From Rank (1)"
                                onChange={(event) => {
                                  setFromRank(event.target.value);
                                }}
                                styles={{
                                  root: {
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                  },
                                  label: {
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                  },
                                }}
                              />
                            </Grid.Col>
                            <Grid.Col span={3.5}>
                              {" "}
                              <TextInput
                                placeholder="To Rank (750)"
                                value={toRank || ""}
                                onChange={(event) => {
                                  setToRank(event.target.value);
                                }}
                                styles={{
                                  root: {
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "start",
                                  },
                                  label: {
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                  },
                                }}
                              />
                            </Grid.Col>
                            <Grid.Col
                              span={1}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip label="Confirm">
                                <ActionIcon
                                  aria-label="default action icon"
                                  size="xs"
                                  color="lime.4"
                                  loading={loadingFetchRankings}
                                  onClick={() => {
                                    setPagination({
                                      ...pagination,
                                      currentPage: 1,
                                    });

                                    if (!fromRank && !toRank) {
                                      showNotification(
                                        "Thất bại",
                                        "Vui lòng nhập giá trị Rank",
                                        "red"
                                      );
                                      return;
                                    }
                                    if (toNumber(fromRank) > toNumber(toRank)) {
                                      showNotification(
                                        "Thất bại",
                                        "From Rank phải nhỏ hơn To Rank",
                                        "red"
                                      );
                                      return;
                                    }
                                    if (
                                      !isEmpty(fromRank) &&
                                      !isEmpty(toRank)
                                    ) {
                                      setQuery({
                                        ...query,
                                        ...(fromRank && {
                                          fromRank: toNumber(fromRank),
                                        }),
                                        ...(toRank && {
                                          toRank: toNumber(toRank),
                                        }),
                                      });
                                    }
                                  }}
                                >
                                  <IconCheck />{" "}
                                </ActionIcon>
                              </Tooltip>
                            </Grid.Col>
                            <Grid.Col span={4}>
                              <Select
                                data={[...values(RANKING_STATUS), "All"]}
                                value={
                                  CONVERT_NUMBER_TO_RANKING_STATUS[
                                    query?.follow
                                  ] || "All"
                                }
                                onChange={(value) => {
                                  setPagination({
                                    ...pagination,
                                    currentPage: 1,
                                  });
                                  if (value === "All") {
                                    // remove it from query
                                    const newQuery = omit(query, ["follow"]);
                                    setQuery({
                                      ...newQuery,
                                    });
                                  } else {
                                    setQuery({
                                      ...query,
                                      follow:
                                        CONVERT_STATUS_TO_RANKING_NUMBER[value],
                                    });
                                  }
                                }}
                              />
                            </Grid.Col>
                          </Grid>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <Checkbox.Group
                          value={query.mode}
                          label="SHOW DATA"
                          onChange={(value) => {
                            let realValue = value;
                            if (isEmpty(value)) {
                              realValue = query.mode;
                            } else {
                              realValue = value[1] ? [value[1]] : value;
                            }
                            setFromRank("");
                            setToRank("");
                            setPagination({
                              ...pagination,
                              currentPage: 1,
                            });
                            setFromRank("");
                            setToRank("");
                            setQuery({
                              ...query,
                              mode: realValue,
                              fromRank: "",
                              toRank: "",
                              sortBy: "totalRankChanges",
                              sortDir: "desc",
                            });
                          }}
                          styles={{
                            root: {
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                              height: "100%",
                            },
                            label: {
                              fontSize: "14px",
                              fontWeight: "bold",
                              marginRight: "10px",
                            },
                          }}
                        >
                          <Group
                            styles={{
                              root: {
                                height: "100%",
                                display: "flex",
                                justifyContent: "start",
                              },
                            }}
                          >
                            <Checkbox
                              styles={{
                                input: {
                                  borderRadius: "50%",
                                },
                              }}
                              value={TARGET_MODES.RANKING}
                              label={TARGET_MODES.RANKING}
                            />
                            <Checkbox
                              styles={{
                                input: {
                                  borderRadius: "50%",
                                },
                              }}
                              value={TARGET_MODES.DEFAULT_RANKING}
                              label={TARGET_MODES.DEFAULT_RANKING}
                            />
                            <Checkbox
                              styles={{
                                input: {
                                  borderRadius: "50%",
                                },
                              }}
                              value={TARGET_MODES.ORDERS}
                              label={TARGET_MODES.ORDERS}
                            />
                          </Group>
                        </Checkbox.Group>
                      </Grid.Col>
                    </Flex>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "10px 0px",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <Flex
                      style={{
                        padding: "10px",
                        borderRadius: "10px",
                        backgroundColor: "#EFF0F1",
                        width: "100%",
                      }}
                    >
                      <Grid.Col
                        span={12}
                        style={{
                          borderRadius: "10px",
                          flexWrap: "wrap",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Grid
                          style={{
                            width: "100%",
                          }}
                        >
                          <Grid.Col span={12}>
                            <Stack spacing="md">
                              <Select
                                label="Category"
                                placeholder="Select category"
                                data={categories || []}
                                value={selectedCategory}
                                onChange={setSelectedCategory}
                                allowDeselect={false}
                                styles={{
                                  root: {
                                    maxWidth: 300,
                                  },
                                }}
                              />

                              <CompetitorGroup
                                competitors={
                                  selectedCategory
                                    ? filter(competitors, {
                                        category: selectedCategory,
                                      })
                                    : competitors
                                }
                                selectedCategory={selectedCategory}
                                selectedValue={query?.competitor}
                                onChange={(value) => {
                                  const [competitorName, type] =
                                    value.split("__");
                                  setFromRank("");
                                  setToRank("");
                                  setQuery({
                                    ...query,
                                    fromRank: "",
                                    toRank: "",
                                    competitor: value,
                                    competitorType: type,
                                  });
                                }}
                              />
                            </Stack>
                          </Grid.Col>
                        </Grid>
                      </Grid.Col>
                    </Flex>
                  </div>

                  <Grid.Col span={12}>
                    {!isEmpty(productRankings) && (
                      <Table
                        tableData={productRankings}
                        loading={loadingFetchRankings}
                        pagination={pagination}
                        setPagination={setPagination}
                        setSorting={setSorting}
                        setTrigger={setTrigger}
                        setIsLoadmore={setIsLoadmore}
                        sorting={sorting}
                        openPreviewImage={openPreviewImage}
                        setSelectedProduct={setSelectedProduct}
                        query={query}
                        setQuery={setQuery}
                        setOverrideProductRankings={setOverrideProductRankings}
                        overrideProductRankings={overrideProductRankings}
                      />
                    )}
                    {loadingFetchRankings && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          marginTop: "20px",
                        }}
                      >
                        <Loader size={30} />
                      </div>
                    )}
                    {isEmpty(productRankings) && !loadingFetchRankings && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Không tìm thấy Data
                        </Text>
                      </div>
                    )}
                  </Grid.Col>
                </Stack>
              </Flex>
            </Grid.Col>
          </Grid>
        </Card>
      </div>

      <Modal
        opened={openedPreviewImage}
        onClose={closePreviewImage}
        fullScreen
        radius={0}
        transitionProps={{ transition: "fade", duration: 200 }}
        zIndex={9999}
        styles={{
          body: {
            width: "90%",
            height: "90%",
          },
        }}
      >
        <Image
          radius="md"
          src={selectedProduct?.image || "/images/content/not_found_2.jpg"}
          height="100%"
          fit="contain"
          style={{
            cursor: "pointer",
          }}
        />
      </Modal>

      <StoreListModal
        opened={openedStoreListModal}
        onClose={closeStoreListModal}
        competitors={allCompetitors}
        onRefetch={fetchCompetitors}
      />
    </>
  );
};

export default RankingPODShopifyProducts;
