import React from "react";
import BriefForm from "./components/BriefForm";
import { useRnDBriefDesign } from "./hooks/useRnDBriefDesign";

const RnDBriefDesign = ({ initialData, onClose }) => {
  const {
    handleSubmit,
    loading,
    directions,
    users,
    fetchProductBases,
    filtersClipArt,
    timeSettings,
  } = useRnDBriefDesign();

  return (
    <BriefForm
      onSubmit={handleSubmit}
      loading={loading}
      initialData={initialData}
      onClose={onClose}
      directions={directions}
      users={users}
      fetchProductBases={fetchProductBases}
      filtersClipArt={filtersClipArt}
      timeSettings={timeSettings}
    />
  );
};

export default RnDBriefDesign;
