import React from "react";
import { Badge, Group } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import styles from "./TextLink.module.sass";

const TextLink = ({ children, href, onClick }) => {
  return (
    <Badge
      component={href ? "a" : "span"}
      href={href}
      target={href ? "_blank" : undefined}
      className={styles.link}
      onClick={() => {
        if (href) {
          window.open(href, "_blank");
        }
      }}
      variant="outline"
      color="blue"
      size="md"
      radius="sm"
      styles={{
        root: {
          cursor: "pointer",
          textTransform: "none",
          padding: "4px 12px",
          borderColor: "#E2E8F0",
          "&:hover": {
            textDecoration: "none",
            backgroundColor: "#F7FAFC",
            borderColor: "#CBD5E0",
          },
        },
      }}
    >
      {href ? (
        <Group spacing={6} align="center">
          <span>Link</span>
          <IconExternalLink size={14} />
        </Group>
      ) : (
        <span>Blank</span>
      )}
    </Badge>
  );
};

export default TextLink;
