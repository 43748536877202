import React, { useState, useCallback } from "react";
import { TextInput, Button, Group } from "@mantine/core";
import { IconSearch, IconRefresh } from "@tabler/icons-react";
import styles from "../ProductSelector.module.sass";

const SearchBar = ({
  value,
  onChange,
  loading,
  showSync,
  onSync,
  placeholder,
}) => {
  const [searchValue, setSearchValue] = useState(value);

  const handleSearch = useCallback(() => {
    if (searchValue !== value) {
      onChange(searchValue);
    }
  }, [onChange, searchValue, value]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleSearch();
      }
    },
    [handleSearch]
  );

  const handleInputChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  return (
    <Group className={styles.searchBar}>
      <TextInput
        placeholder={placeholder}
        value={searchValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        className={styles.searchInput}
        rightSection={
          <IconSearch
            size={16}
            color={loading ? "#adb5bd" : "#495057"}
            style={{ cursor: "pointer" }}
            onClick={handleSearch}
          />
        }
        disabled={loading}
      />
      {showSync && (
        <Button
          variant="filled"
          leftSection={<IconRefresh size={16} />}
          className={styles.syncButton}
          onClick={onSync}
          loading={loading}
        >
          Sync Product Base
        </Button>
      )}
    </Group>
  );
};

export default SearchBar;
