import { useState, useCallback, useEffect } from "react";
import { showNotification } from "../../../utils/index";
import { productlineService } from "../../../services";
import moment from "moment-timezone";

export const useSampleRequest = () => {
  const [sampleRequests, setSampleRequests] = useState([]);
  const [loading, setLoading] = useState({
    fetch: false,
    create: false,
    update: false,
    delete: false,
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  const [query, setQuery] = useState({
    supplierStatus: "all",
    page: 1,
    search: "",
    productType: "",
    supplierName: null,
    uidReceived: "all",
    startDate: null,
    endDate: null,
  });

  const fetchSampleRequests = useCallback(async (params = {}) => {
    try {
      setLoading((prev) => ({ ...prev, fetch: true }));
      const response = await productlineService.fetchSampleRequests({
        ...params,
        page: params.page || pagination.currentPage || 1,
        limit: params.limit || 10,
      });
      setSampleRequests(response?.data?.data);
      setPagination({
        currentPage: params.page || pagination.currentPage || 1,
        totalPages: response?.data?.metadata?.totalPages || 1,
      });
    } catch (error) {
      showNotification("Error", error.message, "red");
    } finally {
      setLoading((prev) => ({ ...prev, fetch: false }));
    }
  }, []);

  const createSampleRequest = useCallback(
    async (data) => {
      try {
        setLoading((prev) => ({ ...prev, create: true }));
        await productlineService.createSampleRequest(data);
        await fetchSampleRequests(transformQuery(query)); // Refresh list after creation
        showNotification(
          "Success",
          "Sample request created successfully",
          "green"
        );
      } catch (error) {
        showNotification("Error", error.message, "red");
      } finally {
        setLoading((prev) => ({ ...prev, create: false }));
      }
    },
    [fetchSampleRequests]
  );

  const updateSampleRequest = useCallback(
    async (id, data) => {
      try {
        setLoading((prev) => ({ ...prev, update: true }));
        await productlineService.updateSampleRequest(id, data);
        await fetchSampleRequests(transformQuery(query)); // Refresh list after update
        showNotification(
          "Success",
          "Sample request updated successfully",
          "green"
        );
      } catch (error) {
        showNotification("Error", error.message, "red");
      } finally {
        setLoading((prev) => ({ ...prev, update: false }));
      }
    },
    [fetchSampleRequests, query]
  );

  const transformQuery = (query) => {
    let uidReceived = query.uidReceived;
    switch (uidReceived) {
      case "Yes":
        uidReceived = "yes";
        break;
      case "No":
        uidReceived = "no";
        break;
      default:
        uidReceived = null;
        break;
    }

    const params = {
      page: query.page || 1,
      limit: 10,
      supplierStatus:
        query.supplierStatus === "all" ? null : query.supplierStatus,
      productType: query.productType || undefined,
      supplierName: query.supplierName || undefined,
      uidReceived,
      startDate: query.startDate
        ? moment(query.startDate).format("YYYY-MM-DD")
        : null,
      endDate: query.endDate
        ? moment(query.endDate).format("YYYY-MM-DD")
        : null,
    };
    return params;
  };

  // Update data when query changes
  useEffect(() => {
    const params = transformQuery(query);

    fetchSampleRequests(params);
  }, [query, fetchSampleRequests]);

  return {
    sampleRequests,
    loading,
    pagination,
    setPagination,
    fetchSampleRequests,
    createSampleRequest,
    updateSampleRequest,
    query,
    setQuery,
  };
};
