import { useEffect, useState } from "react";
import { showNotification } from "../../../utils/notification";
import { dashboardServices, rndServices } from "../../../services";
import { map, toLower } from "lodash";

export const useRnDBriefDesign = () => {
  const [loading, setLoading] = useState(false);
  const [directions, setDirections] = useState([]);
  const [users, setUsers] = useState([]);
  const [filtersClipArt, setFiltersClipArt] = useState([]);
  const [timeSettings, setTimeSettings] = useState([]);
  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      // values sẽ có dạng { isOptimized: boolean, payloads: array }
      console.log("Submit values:", payload);
      // Call API với payload đã được format
      await rndServices.createBriefs(payload);

      // reload lại window
      window.location.reload();
    } catch (error) {
      showNotification("Error", error.message, "red");
    } finally {
      setLoading(false);
    }
  };

  const fetchDirections = async () => {
    const response = await rndServices.fetchDirections({
      page: -1,
      limit: -1,
    });
    const { data } = response;
    setDirections(data || []);
  };

  const fetchFilters = async () => {
    const { data } = await rndServices.fetchFilters();
    setFiltersClipArt(data);
  };

  const fetchUsers = async () => {
    const { data } = await rndServices.getUsers({
      limit: -1,
    });
    setUsers(data);
  };

  const fetchDashboardSettings = async () => {
    const response = await dashboardServices.fetchDashboardsSetting({
      page: -1,
      query: {
        actives: [0, 1],
      },
      limit: -1,
    });
    const { data } = response;
    if (data) {
      setTimeSettings(
        map(data, (time) => ({
          ...time,
          option: toLower(time.option),
        }))
      );
    }
  };
  useEffect(() => {
    fetchDirections();
    fetchUsers();
    fetchFilters();
    fetchDashboardSettings();
  }, []);

  return {
    loading,
    handleSubmit,
    directions,
    users,
    fetchProductBases: rndServices.fetchProductLines,
    filtersClipArt,
    timeSettings,
  };
};
