import React, { useState, useMemo, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  Badge,
  Button,
  Flex,
  Image,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import Checkbox from "../../../components/Checkbox";

import { concat, filter, find, includes, map, values, without } from "lodash";
import {
  IconSearch,
  IconFilterOff,
  IconArchive,
  IconArchiveOff,
  IconCopy,
} from "@tabler/icons-react";
import classes from "./MyTable.module.css";
import { DateRangePicker } from "rsuite";
import { IconCheck, IconX, IconBan } from "@tabler/icons-react";
import {
  AMZ_EPM_CHECK_STATUS,
  BRIEF_TYPE_NEW_SAMPLE_VIDEO,
  BRIEF_TYPE_ON_PLAN_VIDEO,
  BRIEF_TYPE_SAMPLE_VIDEO,
  BRIEF_TYPE_SCALE_FROM_POD,
  BRIEF_TYPES,
  CHOOSE_BRIEF_TYPES,
  IGNORE_AMZ_TEAMS,
  LOCAL_STORAGE_KEY,
  STATUS,
  UNIQUE_TEAMS,
} from "../../../constant";
import moment from "moment-timezone";
import { CONVERT_STATUS_TO_NUMBER } from "../../../utils";
import { rndServices } from "../../../services";
import { showNotification } from "../../../utils/index";
import { useLocalStorage } from "../../../hooks";
import Value from "../../../components/Value";
import SelectValue from "../../../components/SelectValue";
import { resetState } from "./resetState";
import AppMultiSelect from "../../../components/CustomMultiselect";
import UIDSize from "../../../components/UIDSize";
import EditSize from "../../../components/EditSize";
import ImagePreview from "../../../components/ImagePreview";

const DesignerTable = ({
  briefs,
  query,
  setQuery,
  setSelectedSKU,
  openModal,
  users,
  setEditingCell,
  loadingFetchBrief,
  setTrigger,
  setLinkDesign,
  sorting,
  setSorting,
  openNoteForEPM,
  timeSettings,
  directions,
}) => {
  const [validationErrors, setValidationErrors] = useState({});
  let [permissions] = useLocalStorage({
    key: LOCAL_STORAGE_KEY.PERMISSIONS,
    defaultValue: [],
  });
  permissions = map(permissions, "name");
  const [payloads, setPayloads] = useState([]);
  const [data, setData] = useState(briefs || []);
  useEffect(() => {
    setData(briefs);
    setPayloads(briefs);
  }, [briefs]);
  const handleUpdateStatus = async ({ uid, status, realStatus }) => {
    await rndServices.updateAMZBriefDesign({
      uid,
      data: {
        status: status === 1 ? 2 : 1,
        ...(realStatus && { status: realStatus }),
      },
    });
    setTrigger(true);
  };
  const handleUpdatePriority = async ({ uid, priority }) => {
    await rndServices.updateAMZBriefDesign({
      uid,
      data: {
        priority: priority === 1 ? 2 : 1,
      },
    });
    setTrigger(true);
  };
  const handleUpdateValue = async (uid, value) => {
    setPayloads((prev) => {
      const newPayloads = map(prev, (x) => {
        if (x.uid === uid) {
          return {
            ...x,
            value,
          };
        }
        return x;
      });
      return newPayloads;
    });
    rndServices.updateAMZBriefDesign({
      uid,
      data: {
        value,
      },
    });
  };
  const handleUpdateSize = (payload, size) => {
    const uid = payload.uid;
    const briefType = payload.briefType;
    // get designerTimeId
    const designerTime = find(timeSettings, {
      scaleType: briefType,
      team: "designer",
      size: size.design,
    });
    setPayloads((prev) => {
      const newPayloads = map(prev, (x) => {
        if (x.uid === uid) {
          return {
            ...x,
            size,
          };
        }
        return x;
      });
      return newPayloads;
    });
    rndServices.updateAMZBriefDesign({
      uid,
      data: {
        size,
        designerTimeId: designerTime?.uid,
      },
    });
  };
  const handleUpdateTodo = async ({ uid, toDoInfo }) => {
    await rndServices.updateAMZBriefDesign({
      uid,
      data: { toDoInfo },
    });
    setTrigger(true);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        mantineTableHeadCellProps: {
          align: "right",
        },
        size: 50, //small column
        header: "NO",
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
      },
      {
        accessorKey: "date",
        header: "DATE",
        size: 120,
        enableEditing: false,
        enableSorting: true,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
      },
      {
        accessorKey: "batch",
        header: "BATCH",
        size: 100,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) =>
          row.original.batch && (
            <Badge
              color={row?.original?.attribute?.batchColor}
              variant="filled"
            >
              {row.original.batch}
            </Badge>
          ),
      },
      {
        accessorKey: "direction",
        header: "Direction",
        size: 200,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <Select
              data={map(directions, (x) => {
                return {
                  label: x.name,
                  value: x.uid,
                };
              })}
              styles={{
                dropdown: {
                  minWidth: "fit-content",
                },
              }}
              allowDeselect={false}
              size="xs"
              maxDropdownHeight={400}
              value={foundBrief?.directionId}
              onChange={(value) => {
                setPayloads((prev) => {
                  const newPayloads = map(prev, (x) => {
                    if (x.uid === uid) {
                      return {
                        ...x,
                        directionId: value,
                      };
                    }
                    return x;
                  });
                  return newPayloads;
                });
                rndServices.updateAMZBriefDesign({
                  uid,
                  data: {
                    directionId: value,
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "sku",
        header: "SKU",
        size: 100,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Header: ({ column }) => (
          <div
            style={{
              color: "#ffffff",
            }}
          >
            {column.columnDef.header}
          </div>
        ),
        mantineTableHeadCellProps: { className: classes["SKU"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          const isScaleFromPOD =
            foundBrief?.briefType === BRIEF_TYPE_SCALE_FROM_POD &&
            foundBrief?.briefIdRef;
          return (
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedSKU({
                  ...row.original,
                  ...(isScaleFromPOD && {
                    briefType: foundBrief?.briefTypeRef,
                  }),
                  isScaleFromPOD,
                });
                setLinkDesign(row.original.linkDesign);
                openModal();
              }}
            >
              <Badge color="blue" variant="filled">
                {" "}
                <u>{row.original.sku}</u>{" "}
              </Badge>
            </div>
          );
        },
      },
      {
        accessorKey: "imageRef",
        header: "HÌNH REF",
        size: 100,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          const briefType = foundBrief?.briefType;
          const isScaleFromPOD =
            foundBrief?.briefType === BRIEF_TYPE_SCALE_FROM_POD &&
            foundBrief?.briefIdRef;
          return isScaleFromPOD ? (
            <ImagePreview
              src={foundBrief?.designInfo?.refThumbLink}
              height="100%"
              fit="contain"
            />
          ) : (
            <ImagePreview
              src={row?.original?.imageRef}
              height="100%"
              fit="contain"
            />
          );
        },
      },
      {
        accessorKey: "briefType",
        header: "LOẠI BRIEF",
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
      },
      {
        accessorKey: "epmCheckStatus",
        header: "EPM CHECK",
        enableEditing: false,
        enableSorting: false,
        size: 200,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          const epmCheckStatus = foundBrief?.epmCheckStatus;
          return (
            <Select
              data={values(AMZ_EPM_CHECK_STATUS)}
              size="xs"
              value={epmCheckStatus}
              onChange={(value) => {
                setPayloads((prev) => {
                  const newPayloads = map(prev, (x) => {
                    if (x.uid === uid) {
                      return {
                        ...x,
                        epmCheckStatus: value,
                      };
                    }
                    return x;
                  });
                  return newPayloads;
                });
                rndServices.updateAMZBriefDesign({
                  uid,
                  data: {
                    epmCheckStatus: value,
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "amzDesignLinkClipart",
        header: "LINK CLIPART",
        enableEditing: true,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Edit: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          const amzDesignLinkClipart = foundBrief?.amzDesignLinkClipart;
          return (
            <TextInput
              value={amzDesignLinkClipart}
              onChange={(e) => {
                const value = e.target.value;
                setPayloads((prev) => {
                  const newPayloads = map(prev, (x) => {
                    if (x.uid === uid) {
                      return {
                        ...x,
                        amzDesignLinkClipart: value,
                      };
                    }
                    return x;
                  });
                  return newPayloads;
                });
                rndServices.updateAMZBriefDesign({
                  uid,
                  data: {
                    amzDesignLinkClipart: value,
                  },
                });
              }}
            />
          );
        },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <a
              style={{
                cursor: "pointer",
              }}
              target="_blank"
              href={foundBrief?.amzDesignLinkClipart}
            >
              {foundBrief?.amzDesignLinkClipart ? (
                <Badge color="blue" variant="filled">
                  <u>Link</u>{" "}
                </Badge>
              ) : null}
            </a>
          );
        },
      },
      {
        accessorKey: "epmCheckNote",
        header: "EPM NOTE",
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        size: 100,
        enableSorting: false,
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          const note = foundBrief?.note?.epmCheckNote;
          return (
            <Button
              onClick={() => {
                setSelectedSKU({
                  ...foundBrief,
                  noteKey: "epmCheckNote",
                  noteTitle: "EPM NOTE",
                });
                openNoteForEPM();
              }}
              color={note ? "#f1f3f5" : "blue"}
            >
              Note
            </Button>
          );
        },
      },
      {
        accessorKey: "todo",
        header: "TO DO",
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["SKU"] },
        size: 100,
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox value={row?.original?.toDoInfo?.design === 2} />
            </div>
          );
        },
      },
      {
        accessorKey: "priority",
        header: "PRIORITY",
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["SKU"] },
        size: 100,
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox value={row?.original?.priority === 2} />
            </div>
          );
        },
      },
      {
        accessorKey: "value",
        header: "VALUE",
        size: 130,
        enableEditing: true,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["SKU"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          return <Value value={foundBrief?.value} />;
        },
        Edit: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <SelectValue
              uid={row?.original?.uid}
              defaultValue={foundBrief?.value}
              handleChange={handleUpdateValue}
            />
          );
        },
      },
      {
        accessorKey: "size",
        header: "SIZE",
        size: 140,
        enableEditing: true,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          const size = foundBrief?.size?.design;
          return <UIDSize size={size} />;
        },
        Edit: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          const size = foundBrief?.size?.design;
          return (
            <EditSize
              size={size}
              onChange={(value) => {
                const size = {
                  ...row.original.size,
                  design: value,
                };
                handleUpdateSize(row?.original, size);
              }}
            />
          );
        },
      },

      {
        accessorKey: "rndTeam",
        header: "TEAM",
        size: 100,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <Select
              data={map(concat(UNIQUE_TEAMS, IGNORE_AMZ_TEAMS), (x) => {
                return {
                  label: x,
                  value: x,
                  disabled: IGNORE_AMZ_TEAMS.includes(x),
                };
              })}
              allowDeselect={false}
              size="xs"
              maxDropdownHeight={400}
              value={foundBrief?.rndTeam}
              onChange={(value) => {
                setPayloads((prev) => {
                  const newPayloads = map(prev, (x) => {
                    if (x.uid === uid) {
                      return {
                        ...x,
                        rndTeam: value,
                      };
                    }
                    return x;
                  });
                  return newPayloads;
                });
                rndServices.updateAMZBriefDesign({
                  uid,
                  data: {
                    rndTeam: value,
                  },
                });
              }}
            />
          );
        },
      },
      {
        id: "rndName",
        accessorFn: (row) => row?.rnd?.name,
        header: "RND",
        enableEditing: false,
        enableSorting: false,
        size: 150,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <Select
              size="xs"
              data={map(filter(users, { position: "rnd" }), "name") || []}
              allowDeselect={false}
              value={foundBrief?.rnd?.name}
              onChange={(value) => {
                setPayloads((prev) => {
                  const newPayloads = map(prev, (x) => {
                    if (x.uid === uid) {
                      return {
                        ...x,
                        rnd: {
                          ...x.rnd,
                          name: value,
                        },
                        rndId: find(users, { name: value })?.uid,
                      };
                    }
                    return x;
                  });
                  return newPayloads;
                });
                rndServices.updateAMZBriefDesign({
                  uid,
                  data: {
                    rndId: find(users, { name: value })?.uid,
                  },
                });
              }}
            />
          );
        },
      },
      {
        id: "designer",
        accessorFn: (row) => row?.designer?.name,
        header: "DESIGNER",
        enableEditing: false,
        enableSorting: false,
        size: 150,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <Select
              size="xs"
              data={map(filter(users, { position: "designer" }), "name") || []}
              allowDeselect={false}
              value={foundBrief?.designer?.name}
              onChange={(value) => {
                setPayloads((prev) => {
                  const newPayloads = map(prev, (x) => {
                    if (x.uid === uid) {
                      return {
                        ...x,
                        designer: {
                          ...x.designer,
                          name: value,
                        },
                        designerId: find(users, { name: value })?.uid,
                      };
                    }
                    return x;
                  });
                  return newPayloads;
                });
                rndServices.updateAMZBriefDesign({
                  uid,
                  data: {
                    designerId: find(users, { name: value })?.uid,
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "linkDesign",
        header: "LINK DESIGN",
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        size: 100,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Edit: ({ row, table }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });

          return (
            <TextInput
              value={foundBrief?.linkDesign}
              onChange={(e) => {
                const value = e.target.value;
                setPayloads((prev) => {
                  const newPayloads = map(prev, (x) => {
                    if (x.uid === uid) {
                      return {
                        ...x,
                        linkDesign: value,
                      };
                    }
                    return x;
                  });
                  return newPayloads;
                });
              }}
              onBlur={(e) => {
                const value = e.target.value;
                const urlPattern =
                  /^(https?:\/\/)((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[\w-]*)?$/i;
                if (!urlPattern.test(value)) {
                  showNotification(
                    "Thất bại",
                    "Link Design không hợp lệ",
                    "red"
                  );
                  setPayloads((prev) => {
                    const newPayloads = map(prev, (x) => {
                      if (x.uid === uid) {
                        return {
                          ...x,
                          linkDesign: "",
                        };
                      }
                      return x;
                    });
                    return newPayloads;
                  });
                  return;
                } else {
                  rndServices
                    .updateAMZBriefDesign({
                      uid,
                      data: {
                        linkDesign: value,
                      },
                    })
                    .then((response) => {
                      table.setEditingCell(null);
                    });
                }
              }}
              readOnly={[
                STATUS.DESIGNED,
                STATUS.OPTIMIZED_LISTING_DESIGNED,
                STATUS.OPTIMIZED_ADS_DESIGNED,
              ].includes(row?.original?.status)}
            />
          );
        },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          const isScaleFromPOD =
            foundBrief?.briefType === BRIEF_TYPE_SCALE_FROM_POD &&
            foundBrief?.briefIdRef;
          return (
            <a
              style={{
                cursor: "pointer",
              }}
              target="_blank"
              href={foundBrief?.linkDesign}
            >
              {foundBrief?.linkDesign ? (
                <Badge color="blue" variant="filled">
                  {" "}
                  <u>Link</u>{" "}
                </Badge>
              ) : null}
            </a>
          );
        },
      },
      {
        accessorKey: "noteForEPM",
        header: "NOTE FOR EPM",
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        size: 100,
        enableSorting: false,
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          const note = foundBrief?.note?.noteForEPM;
          return (
            <Button
              onClick={() => {
                setSelectedSKU({
                  ...foundBrief,
                  noteKey: "noteForEPM",
                });
                openNoteForEPM();
              }}
              color={note ? "#f1f3f5" : "blue"}
            >
              Note
            </Button>
          );
        },
      },
      {
        accessorKey: "status",
        header: "DONE",
        size: 100,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        Cell: (props) => {
          const { row } = props;
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          return (
            <Button
              variant="filled"
              color={
                [
                  STATUS.DESIGNED,
                  STATUS.OPTIMIZED_LISTING_DESIGNED,
                  STATUS.OPTIMIZED_ADS_DESIGNED,
                ].includes(row?.original?.status)
                  ? "red"
                  : "green"
              }
              leftSection={
                [
                  STATUS.DESIGNED,
                  STATUS.OPTIMIZED_LISTING_DESIGNED,
                  STATUS.OPTIMIZED_ADS_DESIGNED,
                ].includes(row?.original?.status) ? (
                  <IconBan />
                ) : (
                  <IconCheck />
                )
              }
              disabled={
                (foundBrief?.status === 1 && !foundBrief?.linkDesign) ||
                foundBrief?.archive === STATUS.ARCHIVED
              }
              onClick={() => {
                let realStatus = null;
                switch (foundBrief?.briefType) {
                  case BRIEF_TYPES[6]:
                    if (foundBrief?.status === STATUS.BRIEF_CREATED) {
                      realStatus = STATUS.OPTIMIZED_LISTING_DESIGNED;
                    } else {
                      realStatus = STATUS.BRIEF_CREATED;
                    }
                    break;
                  case BRIEF_TYPES[7]:
                    if (foundBrief?.status === STATUS.BRIEF_CREATED) {
                      realStatus = STATUS.OPTIMIZED_ADS_DESIGNED;
                    } else {
                      realStatus = STATUS.BRIEF_CREATED;
                    }
                    break;
                  case BRIEF_TYPES[8]:
                    if (foundBrief?.status === STATUS.BRIEF_CREATED) {
                      realStatus = STATUS.DESIGNED;
                    } else {
                      realStatus = STATUS.BRIEF_CREATED;
                    }
                    break;
                  default:
                    realStatus = null;
                    break;
                }
                openUpdateStatusConfirmModal({
                  uid,
                  status: foundBrief?.status,
                  sku: foundBrief?.sku,
                  realStatus,
                });
              }}
            >
              {[
                STATUS.DESIGNED,
                STATUS.OPTIMIZED_LISTING_DESIGNED,
                STATUS.OPTIMIZED_ADS_DESIGNED,
              ].includes(row?.original?.status)
                ? "Undone"
                : "Done"}
            </Button>
          );
        },
      },
      {
        id: "time",
        accessorFn: (row) => row?.designInfo?.time,
        header: "TIME",
        enableSorting: true,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        enableEditing: false,
        size: 50,
        mantineTableBodyCellProps: { className: classes["body-cells"] },
      },
      // {
      //   accessorKey: "stopwatch",
      //   header: "Bộ đếm",
      //   size: 100,
      //   enableSorting: false,
      //   mantineTableBodyCellProps: { className: classes["body-cells"] },
      //   mantineTableHeadCellProps: { className: classes["head-cells"] },
      //   Cell: ({ row }) => {
      //     const uid = row?.original?.uid;
      //     const foundBrief = find(payloads, { uid });
      //     const elapsedTime = useStopWatch(
      //       foundBrief?.designInfo?.startedAt,
      //       foundBrief?.designInfo?.doneAt
      //     );
      //     return foundBrief?.designInfo?.startedAt ? (
      //       <Button
      //         variant="filled"
      //         color="blue"
      //         disabled={true}
      //         style={{
      //           color: "#000000",
      //         }}
      //       >
      //         {elapsedTime}
      //       </Button>
      //     ) : null;
      //   },
      // },
      {
        accessorKey: "remove",
        header: "ACTIONS",
        enableSorting: false,
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Edit: ({ cell, column, table }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button variant="filled" color="red">
              <IconX />
            </Button>
          </div>
        ),
        Cell: ({ cell, column, table }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button variant="filled" color="red" size="sx">
              <IconX />
            </Button>
          </div>
        ),
      },
      {
        accessorKey: "duplicate",
        header: "DUPLICATE",
        enableSorting: false,
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          return (
            <Button
              variant="filled"
              color="blue"
              onClick={() => {
                openDuplicateBriefModal(uid);
              }}
            >
              <IconCopy />
            </Button>
          );
        },
      },
      {
        accessorKey: "archive",
        header: "ARCHIVE",
        enableSorting: false,
        mantineTableHeadCellProps: { className: classes["linkDesign"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          const uid = row?.original?.uid;
          const foundBrief = find(payloads, { uid });
          const archive = foundBrief?.archive;
          const updateStatus =
            archive !== STATUS.ARCHIVED ? STATUS.ARCHIVED : STATUS.UN_ARCHIVED;
          return (
            <Button
              variant="filled"
              color={archive === STATUS.ARCHIVED ? "red" : "green"}
              onClick={() => {
                openArchiveConfirmModal(foundBrief, updateStatus);
              }}
            >
              {archive === STATUS.ARCHIVED ? (
                <Tooltip label="Un-Archive">
                  <IconArchiveOff />
                </Tooltip>
              ) : (
                <Tooltip label="Archive">
                  <IconArchive />
                </Tooltip>
              )}
            </Button>
          );
        },
      },
    ],
    [validationErrors, data, payloads]
  );

  //DELETE action
  const openDeleteConfirmModal = (row) =>
    modals.openConfirmModal({
      title: "Are you sure you want to delete this SKU?",
      centered: true,
      children: (
        <Text>
          Are you sure you want to delete {row.original.sku}? This action cannot
          be revert.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => handleDeleteBrief(row.original.uid),
    });

  // CONFIRM UPDATE STATUS
  const openUpdateStatusConfirmModal = ({ uid, status, sku, realStatus }) =>
    modals.openConfirmModal({
      title: "Confirm Modal",
      centered: true,
      children: <Text>Are you sure you want to update {sku}?</Text>,
      labels: { confirm: "Update", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () =>
        handleUpdateStatus({
          uid,
          status,
          realStatus,
        }),
    });
  // CONFIRM ARCHIVE
  const openArchiveConfirmModal = (payload, status) =>
    modals.openConfirmModal({
      title: "Confirm Modal",
      centered: true,
      children: (
        <Text>Are you sure you want to call this action {payload?.sku}</Text>
      ),
      labels: { confirm: "Okay", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => handleArchiveBrief(payload?.uid, status),
    });
  const handleDuplicateBrief = async (briefId) => {
    await rndServices.copyBriefFromDesign({
      briefId,
    });
    setTrigger(true);
  };
  const openDuplicateBriefModal = (uid) => {
    modals.openConfirmModal({
      title: "Duplicate Brief",
      centered: true,
      children: <Text>Are you sure you want to duplicate this brief?</Text>,
      labels: { confirm: "Duplicate", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => handleDuplicateBrief(uid),
    });
  };

  const handleArchiveBrief = async (uid, status) => {
    await rndServices.updateAMZBriefDesign({
      uid,
      data: {
        archive: status,
      },
    });
    setTrigger(true);
  };

  const handleDeleteBrief = async (uid) => {
    await rndServices.deleteBrief(uid);
    setTrigger(true);
  };

  const handleReset = () => {
    setQuery(resetState);
    setBatch("");
    setSearchSKU("");
  };

  const [batch, setBatch] = useState(query?.batch || "");
  const [searchSKU, setSearchSKU] = useState(query?.sku || "");

  const table = useMantineReactTable({
    columns,
    data,
    editDisplayMode: "cell", // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enablePagination: false,
    getRowId: (row) => row.id,
    enableRowSelection: false,
    enableFilters: false,
    enableColumnActions: false,
    mantinePaperProps: {
      style: { "--mrt-striped-row-background-color": "#eff0f1" },
    },
    mantineTableHeadCellProps: { className: classes["head-cells"] },
    mantineTableProps: { striped: "even" },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    enableDensityToggle: false,
    renderTopToolbar: () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 5px",
            gap: "10px",
            flexWrap: "wrap-reverse",
            position: "sticky",
            top: 0,
            right: 0,
            zindex: 10,
          }}
        >
          <Flex
            style={{
              gap: "8px",
              padding: "10px",
              borderRadius: "10px",
              backgroundColor: "#EFF0F1",
              flexWrap: "wrap",
            }}
          >
            <TextInput
              placeholder="Batch"
              size="sm"
              width="100px"
              leftSection={
                <span
                  onClick={() => {
                    setQuery({ ...query, batch });
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <IconSearch size={16} />
                </span>
              }
              styles={{
                input: {
                  width: "100px",
                },
              }}
              value={batch}
              onChange={(e) => {
                const value = e.target.value;
                setBatch(value);
                if (!value) {
                  setQuery({ ...query, batch: value });
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setQuery({ ...query, batch });
                }
              }}
            />
            <TextInput
              placeholder="SKU"
              size="sm"
              width="100px"
              leftSection={
                <span
                  onClick={() => {
                    setQuery({ ...query, sku: searchSKU });
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <IconSearch size={16} />
                </span>
              }
              styles={{
                input: {
                  width: "100px",
                },
              }}
              value={searchSKU}
              onChange={(e) => {
                const value = e.target.value;
                setSearchSKU(value);
                if (!value) {
                  setQuery({ ...query, sku: value });
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setQuery({ ...query, sku: searchSKU });
                }
              }}
            />
            <DateRangePicker
              size="sx"
              placeholder="Date"
              style={{
                width: "100px",
              }}
              value={query?.dateValue}
              onOk={(value) =>
                setQuery({
                  ...query,
                  dateValue: value,
                  date: {
                    startDate: moment(value[0]).format("YYYY-MM-DD"),
                    endDate: moment(value[1]).format("YYYY-MM-DD"),
                  },
                })
              }
              onClean={() => {
                setQuery({
                  ...query,
                  dateValue: null,
                  date: null,
                });
              }}
              onShortcutClick={(shortcut) => {
                setQuery({
                  ...query,
                  dateValue: shortcut.value,
                  date: {
                    startDate: moment(shortcut.value[0]).format("YYYY-MM-DD"),
                    endDate: moment(shortcut.value[1]).format("YYYY-MM-DD"),
                  },
                });
              }}
            />
            <AppMultiSelect
              options={map(
                without(
                  [...CHOOSE_BRIEF_TYPES],
                  BRIEF_TYPE_SAMPLE_VIDEO,
                  BRIEF_TYPE_NEW_SAMPLE_VIDEO,
                  BRIEF_TYPE_ON_PLAN_VIDEO
                ),
                (x) => ({
                  label: x,
                  value: x,
                })
              )}
              placeholder="Loại Brief"
              styles={{
                input: {
                  width: "150px",
                },
              }}
              onClear={() => {
                setQuery({
                  ...query,
                  briefTypes: null,
                });
              }}
              defaultValue={query?.briefTypes}
              onChange={(value) => {
                setQuery({ ...query, briefTypes: value });
              }}
            />
            <AppMultiSelect
              options={map(concat(UNIQUE_TEAMS, IGNORE_AMZ_TEAMS), (x) => ({
                label: x,
                value: x,
                color: IGNORE_AMZ_TEAMS.includes(x) ? "gray" : "",
              }))}
              placeholder="Team"
              defaultValue={query?.rndTeams}
              onChange={(value) => {
                setQuery({ ...query, rndTeams: value });
              }}
              onClear={() => {
                setQuery({
                  ...query,
                  rndTeams: null,
                });
              }}
            />
            <Select
              placeholder="Size"
              data={["Small", "Medium", "Big"]}
              styles={{
                input: {
                  width: "100px",
                },
              }}
              value={query?.sizeValue}
              onChange={(value) =>
                setQuery({
                  ...query,
                  size: {
                    "size.design": CONVERT_STATUS_TO_NUMBER[value],
                  },
                  sizeValue: value,
                })
              }
              clearable
              onClear={() => {
                setQuery({
                  ...query,
                  size: null,
                  sizeValue: null,
                });
              }}
            />
            <AppMultiSelect
              options={map(
                filter(users, (user) => user?.position === "rnd"),
                (x) => ({
                  label: x?.name,
                  value: x?.uid,
                })
              )}
              placeholder="RND"
              defaultValue={query?.rnds}
              onChange={(value) => {
                setQuery({ ...query, rnds: value });
              }}
              onClear={() => {
                setQuery({
                  ...query,
                  rnds: [],
                });
              }}
            />

            <AppMultiSelect
              options={map(
                filter(users, (user) => user.position === "designer"),
                (x) => ({
                  label: x?.name,
                  value: x?.uid,
                })
              )}
              placeholder="Designer"
              defaultValue={query?.designers}
              onChange={(value) => {
                setQuery({ ...query, designers: value });
              }}
              onClear={() => {
                setQuery({
                  ...query,
                  designers: [],
                });
              }}
            />
            <AppMultiSelect
              options={map(directions, (x) => ({
                label: x?.name,
                value: x?.uid,
              }))}
              placeholder="Direction"
              defaultValue={query?.directions}
              onChange={(value) => {
                setQuery({ ...query, directions: value });
              }}
              onClear={() => {
                setQuery({
                  ...query,
                  directions: null,
                });
              }}
            />
            <Select
              placeholder="Status"
              data={["Done", "Undone", "Archived"]}
              styles={{
                input: {
                  width: "120px",
                },
              }}
              value={query?.statusValue}
              onChange={(value) => {
                let status;
                let archive = null;
                switch (value) {
                  case "Done":
                    status = [2, 12, 22];
                    break;
                  case "Undone":
                    status = [1];
                    break;
                  case "Archived":
                    archive = STATUS.ARCHIVED;
                    break;
                  default:
                    status = [1, 2, 12, 22];
                    break;
                }
                setQuery({
                  ...query,
                  status,
                  statusValue: value,
                  ...(archive
                    ? { archive, status: [1, 2, 12, 22] }
                    : {
                        archive: null,
                        status,
                      }),
                });
              }}
              clearable
              onClear={() => {
                setQuery({
                  ...query,
                  status: [1, 2, 12, 22],
                  statusValue: null,
                  archive: null,
                });
              }}
            />
            <Button onClick={handleReset}>
              <IconFilterOff />
            </Button>
          </Flex>
        </div>
      );
    },
    state: {
      showProgressBars: loadingFetchBrief,
      sorting,
    },
    mantineTableBodyCellProps: ({ row, table, cell }) => ({
      className: classes["body-cells"],
      onDoubleClick: () => {
        if (
          cell &&
          (cell.column.id === "linkDesign" ||
            cell.column.id === "value" ||
            cell.column.id === "amzDesignLinkClipart" ||
            cell.column.id === "size")
        ) {
          setEditingCell(true);
          table.setEditingCell(cell);
        }
      },
      onClick: () => {
        if (
          cell &&
          cell.column.id === "remove" &&
          (includes(permissions, "delete:design") ||
            includes(permissions, "delete:brief"))
        ) {
          openDeleteConfirmModal(row);
          return;
        }
        if (
          cell &&
          cell.column.id === "priority" &&
          (includes(permissions, "update:design") ||
            includes(permissions, "update:brief"))
        ) {
          handleUpdatePriority({
            uid: row.original.uid,
            priority: row.original.priority,
          }).then((response) => {
            console.log(response);
          });
          return;
        }
        if (
          cell &&
          cell.column.id === "todo" &&
          (includes(permissions, "update:design") ||
            includes(permissions, "update:brief"))
        ) {
          handleUpdateTodo({
            uid: row?.original?.uid,
            toDoInfo: {
              ...row?.original?.toDoInfo,
              design: row?.original?.toDoInfo?.design === 2 ? 1 : 2,
            },
          });
        }
      },
      // when leaving the cell, we want to reset the editing cell
      onBlur: () => {
        table.setEditingCell(null);
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    onSortingChange: setSorting,
    enableStickyHeader: true,
  });

  return <MantineReactTable table={table} />;
};

export default DesignerTable;
