import React, { useMemo, useState } from "react";
import { MantineReactTable } from "mantine-react-table";
import { Button, Select, Image, TextInput, Flex, Text } from "@mantine/core";
import { IconFilterOff } from "@tabler/icons-react";
import classes from "./Table.module.css";
import TextLink from "../../../components/TextLink";
import { map, filter, toUpper } from "lodash";
import Value from "../../../components/Value";
import { Pagination } from "@mantine/core";
import { NEW_PRODUCT_LINE_BRIEF_STATUS } from "../../../constant";
import ImagePreview from "../../../components/ImagePreview";
import moment from "moment-timezone";

const ImageCell = ({ url }) => (
  <Image
    src={url}
    width={50}
    height={50}
    fit="contain"
    radius="sm"
    withPlaceholder
  />
);

const STATUS_OPTIONS = [
  { value: "5", label: "Done" },
  { value: "4", label: "Undone" },
];

const FilterGroup = ({ label, children }) => (
  <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Text size="sm" fw={500} c="dimmed">
      {label}
    </Text>
    {children}
  </div>
);

const Table = ({
  data,
  loading,
  updateProduct,
  epms,
  query,
  setQuery,
  pagination,
}) => {
  const EPM_OPTIONS = useMemo(
    () => [
      ...map(
        filter(epms, (epm) => toUpper(epm.position) === "EPM") || [],
        (epm) => ({
          value: String(epm.uid),
          label: epm.name,
        })
      ),
    ],
    [epms]
  );

  const handleUpdateEPM = (row, value) => {
    updateProduct(row.original.uid, { epmId: value });
  };

  const handleDoneClick = (row) => {
    const isDone =
      row.original.status ===
      NEW_PRODUCT_LINE_BRIEF_STATUS.EPM_DONE_PRODUCT_BASE;
    updateProduct(row.original.uid, {
      status: isDone
        ? NEW_PRODUCT_LINE_BRIEF_STATUS.MOCKUP_DONE
        : NEW_PRODUCT_LINE_BRIEF_STATUS.EPM_DONE_PRODUCT_BASE,
    });
  };

  const handleFilterReset = () => {
    setQuery({
      name: null,
      epmId: null,
      status: null,
    });
    setProductNameInput("");
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "mockupDoneAt",
        header: "Date",
        enableSorting: false,
        enableEditing: false,
        size: 200,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) =>
          moment(row.original.mockupDoneAt)
            .tz("Asia/Ho_Chi_Minh")
            .format("DD/MM/YYYY"),
      },
      {
        accessorKey: "name",
        header: "Product Name",
        enableSorting: false,
        enableEditing: false,
        size: 200,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
      },
      {
        accessorKey: "imageRef",
        header: "Hình Ref",
        size: 100,
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => <ImagePreview src={row.original.imageRef} />,
      },
      {
        accessorKey: "value",
        header: "Value",
        size: 100,
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => <Value value={row.original.value} />,
      },
      {
        accessorKey: "productLink",
        header: "Product Library",
        size: 150,
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => (
          <TextLink href={row.original.productLink}>
            {row.original.productLink}
          </TextLink>
        ),
      },
      {
        accessorKey: "productBaseLinks",
        header: "Product Base",
        size: 150,
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => (
          <Flex direction="column" gap="xs">
            {map(row.original.productBaseLinks, (link) => (
              <TextLink href={link}>{link}</TextLink>
            ))}
          </Flex>
        ),
      },
      {
        accessorKey: "imageDesignTibLink",
        header: "Hình Design",
        size: 100,
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => (
          <ImagePreview src={row.original.imageDesignTibLink} />
        ),
      },
      {
        accessorKey: "epm",
        header: "EPM",
        size: 150,
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: { className: classes["edit-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => (
          <Select
            value={row?.original?.epmId ? String(row.original.epmId) : null}
            onChange={(value) => handleUpdateEPM(row, value)}
            data={EPM_OPTIONS}
            clearable
            styles={{
              input: {
                textAlign: "center",
              },
            }}
          />
        ),
      },
      {
        accessorKey: "status",
        header: "Done",
        size: 100,
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => (
          <Button
            variant={
              row.original.status ===
              NEW_PRODUCT_LINE_BRIEF_STATUS.EPM_DONE_PRODUCT_BASE
                ? "filled"
                : "outline"
            }
            color={
              row.original.status ===
              NEW_PRODUCT_LINE_BRIEF_STATUS.EPM_DONE_PRODUCT_BASE
                ? "red"
                : "green"
            }
            onClick={() => handleDoneClick(row)}
            size="xs"
            radius="xs"
          >
            {row.original.status ===
            NEW_PRODUCT_LINE_BRIEF_STATUS.EPM_DONE_PRODUCT_BASE
              ? "UnDone"
              : "Done"}
          </Button>
        ),
      },
    ],
    [loading, data, EPM_OPTIONS]
  );

  // Add local state for text input
  const [productNameInput, setProductNameInput] = useState(query.name);

  const handleProductNameKeyDown = (e) => {
    if (e.key === "Enter") {
      setQuery((prev) => ({
        ...prev,
        name: productNameInput,
      }));
    }
  };

  return (
    <>
      <div className={classes.toolbar}>
        <Flex align="flex-end" gap="md" wrap="wrap" mb="md">
          <FilterGroup label="Product Name">
            <TextInput
              placeholder="Search Product Name..."
              style={{ width: 200 }}
              value={productNameInput}
              onChange={(e) => setProductNameInput(e.target.value)}
              onKeyDown={handleProductNameKeyDown}
            />
          </FilterGroup>

          <FilterGroup label="EPM">
            <Select
              placeholder="Select EPM"
              style={{ width: 200 }}
              data={EPM_OPTIONS}
              value={query.epmId || null}
              onChange={(value) =>
                setQuery((prev) => ({
                  ...prev,
                  page: 1,
                  epmId: value,
                }))
              }
              clearable
            />
          </FilterGroup>

          <FilterGroup label="Status">
            <Select
              placeholder="Select Status"
              style={{ width: 150 }}
              data={STATUS_OPTIONS}
              value={query.status || null}
              onChange={(value) =>
                setQuery((prev) => ({
                  ...prev,
                  page: 1,
                  status: value,
                }))
              }
              clearable
            />
          </FilterGroup>

          <Button
            onClick={handleFilterReset}
            style={{ height: "36px", marginTop: "21px" }}
          >
            <IconFilterOff />
          </Button>
        </Flex>
      </div>

      <MantineReactTable
        columns={columns}
        data={data}
        enablePagination={false}
        enableRowSelection={false}
        enableColumnActions={false}
        enableTopToolbar={false}
        enableFilters={false}
        mantineTableProps={{
          highlightOnHover: true,
          withColumnBorders: true,
          style: { tableLayout: "fixed" },
        }}
        state={{
          isLoading: loading,
        }}
      />
      <Pagination
        total={pagination.totalPages}
        value={query.page}
        onChange={(value) => setQuery((prev) => ({ ...prev, page: value }))}
        color="pink"
        size="md"
        style={{ marginTop: "20px", marginLeft: "auto" }}
      />
    </>
  );
};

export default Table;
