import React, { useState } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';

const theme = createTheme({
  primaryColor: 'blue',
});

const DynamicTable = ({
  columns,
  data,
  enableColumnFilters = true,
  enablePagination = true,
  enableSorting = true,
  pageSize = 10,
  enableColumnResizing = true,
  isLoading = false,
  totalColumns = [], // Danh sách các cột cần tính tổng
}) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize,
  });

  // Hàm tính tổng cho một cột
//   const calculateColumnTotal = (columnKey) => {
//     return data.reduce((sum, row) => {
//       const value = row[columnKey];
//       return sum + (typeof value === 'number' ? value : 0);
//     }, 0);
//   };

  const table = useMantineReactTable({
    data,
    columns,
    state: {
      columnFilters,
      sorting,
      pagination,
      isLoading,
    },
    enableColumnFilters,
    enablePagination,
    enableSorting,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <MantineProvider theme={theme}>
      <div className="w-full overflow-x-auto">
        <MantineReactTable
          table={table}
          enableColumnResizing={enableColumnResizing}
          mantineTableProps={{
            striped: true,
            highlightOnHover: true,
          }}
          mantineTableHeadCellProps={{
            style: {
              padding: '16px',
              backgroundColor: '#f8f9fa',
            },
          }}
          mantineTableBodyCellProps={{
            style: {
              padding: '16px',
            },
          }}
          renderTopToolbarCustomActions={() => (
            <div className="flex items-center gap-2">
              <input
                placeholder="Tìm kiếm..."
                className="px-3 py-2 border rounded-md"
                onChange={(e) => table.setGlobalFilter(e.target.value)}
              />
            </div>
          )}
          mantineBottomToolbarProps={{
            children: (
              <div className="flex flex-col gap-4 p-4 border-t">
                {/* Phần tổng cộng */}
                {/* {totalColumns.length > 0 && (
                  <div className="flex items-center gap-4">
                    <span className="font-semibold">Tổng cộng:</span>
                    {totalColumns.map((columnKey) => {
                      const column = columns.find(col => col.accessorKey === columnKey);
                      const total = calculateColumnTotal(columnKey);
                      return (
                        <div key={columnKey} className="flex items-center gap-2">
                          <span className="font-medium">{column?.header || columnKey}:</span>
                          <span>{typeof total === 'number' ? total.toLocaleString('vi-VN') : total}</span>
                        </div>
                      );
                    })}
                  </div>
                )} */}
                
                {/* Phần điều khiển phân trang */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <select
                      value={table.getState().pagination.pageSize}
                      onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                      }}
                      className="px-3 py-2 border rounded-md"
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Hiển thị {pageSize} dòng
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex items-center gap-4">
                    <span>
                      Trang {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                    </span>
                    <span>
                      Tổng số: {data?.length} dòng
                    </span>
                  </div>
                </div>
              </div>
            )
          }}
        />
      </div>
    </MantineProvider>
  );
};

export default DynamicTable;
