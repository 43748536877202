export const AUTH0_MANAGEMENT_API =
  process.env.REACT_APP_AUTH0_MANAGEMENT_API_URL;
export const AUTH0_MANAGEMENT_API_TOKEN_URL =
  process.env.REACT_APP_AUTH0_MANAGEMENT_API_TOKEN_URL;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_MANAGEMENT_CLIENT_ID =
  process.env.REACT_APP_AUTH0_MANAGEMENT_CLIENT_ID;
export const AUTH0_MANAGEMENT_CLIENT_SECRET =
  process.env.REACT_APP_AUTH0_MANAGEMENT_CLIENT_SECRET;
export const AUTH0_MANAGEMENT_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_MANAGEMENT_CLIENT_GRANT_TYPE = "client_credentials";
