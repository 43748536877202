import React, { useState } from "react";
import {
  Card,
  Group,
  Text,
  Button,
  Stack,
  Image,
  ActionIcon,
  Loader,
  TextInput,
} from "@mantine/core";
import {
  IconPlus,
  IconX,
  IconUpload,
  IconClipboard,
} from "@tabler/icons-react";
import { FileUploader } from "react-drag-drop-files";
import QuillEditor from "../../../../components/QuillEditor";
import styles from "./RefDesignCard.module.sass";
import ProductSelectorModal from "../ClipartModal/ProductSelectorModal";
import { get, filter, find, isEmpty, map } from "lodash";
import { uploadServices } from "../../../../services/uploads";

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const RefDesignCard = ({ onRemove, onChange, value, filters }) => {
  const [showUploader, setShowUploader] = useState(!get(value, "image"));
  const [modalOpened, setModalOpened] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleUploadImage = async (file) => {
    try {
      setUploading(true);
      const response = await uploadServices.upload(file);

      onChange({
        ...value,
        image: response.data.url,
        fileName: file.name,
      });
      setShowUploader(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      // Có thể thêm notification error ở đây
    } finally {
      setUploading(false);
    }
  };

  const handleChange = (file) => {
    if (file) {
      handleUploadImage(file);
    }
  };

  const handlePaste = (e) => {
    const items =
      get(e, "clipboardData.items") ||
      get(e, "originalEvent.clipboardData.items");
    const imageItem = find(items, (item) => item.type.indexOf("image") === 0);

    if (imageItem) {
      handleUploadImage(imageItem.getAsFile());
    }
  };

  const handleRemoveProduct = (productId) => {
    onChange({
      ...value,
      products: filter(get(value, "products", []), (p) => p.id !== productId),
    });
  };

  const handleNoteChange = (content) => {
    onChange({
      ...value,
      note: content,
    });
  };

  return (
    <Card className={styles.card}>
      <Group position="right" mb="sm">
        <ActionIcon onClick={onRemove} variant="subtle">
          <IconX size={16} />
        </ActionIcon>
      </Group>

      <Group align="flex-start" noWrap spacing="lg">
        <div className={styles.mediaSection}>
          {showUploader ? (
            <Stack spacing="md">
              <div className={styles.uploaderBox}>
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  classes={styles.uploader}
                  disabled={uploading}
                >
                  <Stack
                    align="center"
                    spacing="xs"
                    className={styles.uploaderContent}
                  >
                    {uploading ? (
                      <>
                        <Loader size="sm" />
                        <Text size="sm" weight={500}>
                          Uploading...
                        </Text>
                      </>
                    ) : (
                      <>
                        <IconUpload size={32} />
                        <Text size="sm" weight={500}>
                          Drag Or Upload
                        </Text>
                      </>
                    )}
                  </Stack>
                </FileUploader>
              </div>

              <div
                className={styles.pasteBox}
                onPaste={handlePaste}
                tabIndex={0}
              >
                <Stack
                  align="center"
                  spacing="xs"
                  className={styles.pasteContent}
                >
                  {uploading ? (
                    <>
                      <Loader size="sm" />
                      <Text size="sm" weight={500}>
                        Uploading...
                      </Text>
                    </>
                  ) : (
                    <>
                      <IconClipboard size={32} />
                      <Text size="sm" weight={500}>
                        Click to paste image here
                      </Text>
                    </>
                  )}
                </Stack>
              </div>
            </Stack>
          ) : (
            <div className={styles.imageWrapper}>
              <Image
                src={get(value, "image")}
                alt={get(value, "fileName")}
                className={styles.image}
              />
              <Text size="sm" align="center" mt="xs">
                {get(value, "fileName")}
              </Text>
            </div>
          )}
        </div>

        <div className={styles.contentSection}>
          {!showUploader && (
            <>
              <div className={styles.productsSection}>
                <Group position="apart" mb="sm">
                  <Text size="sm" weight={500}>
                    Selected Cliparts ({get(value, "products.length", 0)})
                  </Text>
                  <Button
                    variant="light"
                    leftSection={<IconPlus size={16} />}
                    onClick={() => setModalOpened(true)}
                    size="xs"
                  >
                    Add Cliparts
                  </Button>
                </Group>

                {!isEmpty(get(value, "products")) && (
                  <div className={styles.productsList}>
                    {map(value.products, (product) => (
                      <div key={product.id} className={styles.productItem}>
                        <div className={styles.productContent}>
                          <Image
                            src={
                              product?.image ||
                              product?.imageSrc ||
                              product?.imageUrl
                            }
                            width={40}
                            height={40}
                            className={styles.productImage}
                          />
                          <Text size="sm" lineClamp={1}>
                            {product?.title || product?.name}
                          </Text>
                        </div>
                        <ActionIcon
                          variant="subtle"
                          color="red"
                          onClick={() => handleRemoveProduct(product.id)}
                        >
                          <IconX size={16} />
                        </ActionIcon>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Design Link */}
              <Group className={styles.section}>
                <TextInput
                  placeholder="Enter design link..."
                  className={styles.input}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      designLink: e.target.value,
                    })
                  }
                  style={{ width: "100%" }}
                />
              </Group>

              <div className={styles.noteSection}>
                <Text size="sm" weight={500} mb="xs">
                  Note
                </Text>
                <QuillEditor
                  state={get(value, "note", "")}
                  onChange={handleNoteChange}
                  height={120}
                  placeholder="Add note..."
                />
              </div>
            </>
          )}
        </div>
      </Group>

      <ProductSelectorModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        selectedProducts={get(value, "products", [])}
        onFinish={(products) => {
          onChange({
            ...value,
            products,
          });
          setModalOpened(false);
        }}
        filters={filters}
        onChange={(cliparts) => {
          onChange({
            ...value,
            products: cliparts,
          });
        }}
      />
    </Card>
  );
};

export default RefDesignCard;
