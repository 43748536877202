import React, { useRef } from "react";
import { Group, Button, FileButton, Loader } from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";
import { get, isNil } from "lodash";
import ImagePreview from "../../ImagePreview";
import styles from "./ImageRefCell.module.sass";

const DEFAULT_CONFIG = {
  maxSize: 5, // MB
  acceptedTypes: ["image/jpeg", "image/png", "image/gif"],
};

const ERROR_MESSAGES = {
  SIZE: (maxSize) => `File size must be less than ${maxSize}MB`,
  TYPE: "Invalid file type",
  UPLOAD: "Upload failed",
};

const ImageRefCell = ({
  value,
  onUpload,
  isLoading,
  showError,
  config = {},
}) => {
  const resetRef = useRef(null);
  const { maxSize, acceptedTypes } = { ...DEFAULT_CONFIG, ...config };

  const validateFile = (file) => {
    if (isNil(file)) return false;

    const sizeInMB = get(file, "size", 0) / (1024 * 1024);
    if (sizeInMB > maxSize) {
      showError?.(ERROR_MESSAGES.SIZE(maxSize));
      return false;
    }

    if (!acceptedTypes.includes(get(file, "type"))) {
      showError?.(ERROR_MESSAGES.TYPE);
      return false;
    }

    return true;
  };

  const handleFileChange = async (file) => {
    if (!validateFile(file)) {
      resetRef.current?.();
      return;
    }

    try {
      await onUpload?.(file);
    } catch (error) {
      showError?.(get(error, "message", ERROR_MESSAGES.UPLOAD));
    } finally {
      resetRef.current?.();
    }
  };

  const renderUploadButton = (props) => (
    <div className={styles.uploadOverlay}>
      <Button
        {...props}
        variant="subtle"
        className={styles.uploadButton}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loader color="blue" size="xs" />
        ) : (
          <IconUpload size={16} />
        )}
      </Button>
    </div>
  );

  return (
    <Group gap={8} justify="center" w="100%">
      <div className={styles.imageContainer}>
        <ImagePreview src={value} showDefaultImage={true} />
        <FileButton
          resetRef={resetRef}
          onChange={handleFileChange}
          accept={acceptedTypes.join(",")}
        >
          {renderUploadButton}
        </FileButton>
      </div>
    </Group>
  );
};

export default ImageRefCell;
