import React, { useEffect, useMemo, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  Text,
  Flex,
  Grid,
  Image,
  Button,
  Badge,
  Tooltip,
  Group,
  Select,
  ActionIcon,
} from "@mantine/core";
import { find, values, isEmpty, map } from "lodash";

import classes from "./MyTable.module.css";
import {
  IconSortAscending,
  IconSortDescending,
  IconArrowsSort,
  IconX,
} from "@tabler/icons-react";
import {
  CONVERT_NUMBER_TO_STATUS,
  CONVERT_STATUS_TO_NUMBER,
} from "../../../utils";

import { amzServices } from "../../../services";
import moment from "moment-timezone";
import {
  OPTIMIZED_INFO_NUMBER_TO_STATUS,
  AMZ_OPTIMIZED_INFO_STATUS,
  OPTIMIZED_INFO_STATUS_TO_NUMBER,
} from "./optimizedInfoStatus";
import { modals } from "@mantine/modals";
import {
  AMZ_DASHBOARD_STATUS,
  CONVERT_NUMBER_TO_AMZ_DASHBOARD_STATUS,
  CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER,
  MEMBER_MKT_PIC,
} from "../../../constant";
import AppSelect from "../../../components/AppSelect";
import EditLink from "../../../components/EditLink";
import BadgeLink from "../../../components/BadgeLink";

const OptimizedTableMode = ({
  tableData,
  query,
  loading,
  sorting,
  setSorting,
  setQuery,
  setPagination,
  pagination,
  setIsLoadmore,
  setIsConfirmedQuery,
}) => {
  const handleUpdateAMZDashboard = async (sku, data) => {
    await amzServices.handleOptimizedReportAMZDashboard(sku, data);
  };

  const handleUpdateASIN = async (sku, data) => {
    await amzServices.handleUpdateAMZDashboard(sku, data);
  };

  const handleDeleteSku = async (id) => {
    await amzServices.handleDeleteAMZDashboard(id);
  };

  const [data, setData] = useState(tableData || []);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const handleUpdateMarkOptimized = async (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);
    await handleDeleteSku(id);
  };

  // CONFIRM UPDATE STATUS
  const openUpdateStatusConfirmModal = ({ id, sku }) =>
    modals.openConfirmModal({
      title: "Confirm Modal",
      centered: true,
      children: <Text>Are you sure you want to delete {sku}?</Text>,
      labels: { confirm: "Update", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => handleUpdateMarkOptimized(id),
    });

  // UseMemo to construct final columns array
  const columns = useMemo(
    () => [
      {
        accessorKey: "product",
        header: "Product",
        size: 250,
        maxSize: 250,
        enableEditing: false,
        enableSorting: false,
        enableMultiSort: true,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-op-team"],
            rowSpan: 1,
          };
        },
        Header: () => {
          return (
            <Group gap={30}>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Product
              </Text>

              <Flex direction="column">
                <Group justify="space-between">
                  <Text
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Ads:
                  </Text>
                  <Group>
                    {(!query?.primarySortBy ||
                      query?.primarySortBy !== "testDate") && (
                      <ActionIcon
                        aria-label="Settings"
                        variant="default"
                        size="sm"
                        style={{
                          background: "none",
                          border: "none",
                        }}
                        onClick={() => {
                          setPagination({
                            ...pagination,
                            currentPage: 1,
                          });
                          setIsConfirmedQuery(true);
                          setQuery({
                            ...query,
                            primarySortBy: "testDate",
                            primarySortDir: "desc",
                          });
                        }}
                      >
                        <IconArrowsSort
                          style={{
                            width: "60%",
                            height: "60%",
                            fontWeight: "bold",
                          }}
                          stroke={2}
                          color="#ffffff"
                        />
                      </ActionIcon>
                    )}
                    {query?.primarySortBy === "testDate" &&
                      query?.primarySortDir === "desc" && (
                        <ActionIcon
                          variant="filled"
                          aria-label="Settings"
                          color="transparent"
                          size="sm"
                          onClick={() => {
                            setPagination({
                              ...pagination,
                              currentPage: 1,
                            });
                            setQuery({
                              ...query,
                              primarySortBy: "testDate",
                              primarySortDir: "asc",
                            });
                          }}
                        >
                          <IconSortDescending
                            style={{ width: "70%", height: "70%" }}
                            stroke={2}
                            color="#70B1ED"
                          />
                        </ActionIcon>
                      )}
                    {query?.primarySortBy === "testDate" &&
                      query?.primarySortDir === "asc" && (
                        <ActionIcon
                          variant="filled"
                          aria-label="Settings"
                          size="sm"
                          color="transparent"
                          onClick={() => {
                            setIsConfirmedQuery(true);
                            setPagination({
                              ...pagination,
                              currentPage: 1,
                            });
                            setQuery({
                              ...query,
                              primarySortBy: null,
                              primarySortDir: null,
                            });
                          }}
                        >
                          <IconSortAscending
                            style={{
                              width: "70%",
                              height: "70%",
                              fontWeight: "bold",
                            }}
                            stroke={2}
                            color="#70B1ED"
                          />
                        </ActionIcon>
                      )}
                  </Group>
                </Group>
                <Group justify="space-between">
                  <Text
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    List:
                  </Text>
                  <Group>
                    {(!query?.primarySortBy ||
                      query?.primarySortBy !== "createdDate") && (
                      <ActionIcon
                        aria-label="Settings"
                        variant="default"
                        size="sm"
                        style={{
                          background: "none",
                          border: "none",
                        }}
                        onClick={() => {
                          setIsConfirmedQuery(true);
                          setPagination({
                            ...pagination,
                            currentPage: 1,
                          });
                          setQuery({
                            ...query,
                            primarySortBy: "createdDate",
                            primarySortDir: "desc",
                          });
                        }}
                      >
                        <IconArrowsSort
                          style={{
                            width: "60%",
                            height: "60%",
                            fontWeight: "bold",
                          }}
                          stroke={2}
                          color="#ffffff"
                        />
                      </ActionIcon>
                    )}

                    {query?.primarySortBy === "createdDate" &&
                      query?.primarySortDir === "desc" && (
                        <ActionIcon
                          variant="filled"
                          aria-label="Settings"
                          color="transparent"
                          size="sm"
                          onClick={() => {
                            setIsConfirmedQuery(true);
                            setPagination({
                              ...pagination,
                              currentPage: 1,
                            });
                            setQuery({
                              ...query,
                              primarySortBy: "createdDate",
                              primarySortDir: "desc",
                            });
                          }}
                        >
                          <IconSortDescending
                            style={{ width: "70%", height: "70%" }}
                            stroke={2}
                            color="#70B1ED"
                          />
                        </ActionIcon>
                      )}
                    {query?.primarySortBy === "createdDate" &&
                      query?.primarySortDir === "asc" && (
                        <ActionIcon
                          variant="filled"
                          aria-label="Settings"
                          size="sm"
                          color="transparent"
                          onClick={() => {
                            setIsConfirmedQuery(true);
                            setPagination({
                              ...pagination,
                              currentPage: 1,
                            });
                            setQuery({
                              ...query,
                              primarySortBy: null,
                              primarySortDir: null,
                            });
                          }}
                        >
                          <IconSortAscending
                            style={{
                              width: "70%",
                              height: "70%",
                              fontWeight: "bold",
                            }}
                            stroke={2}
                            color="#70B1ED"
                          />
                        </ActionIcon>
                      )}
                  </Group>
                </Group>
              </Flex>
            </Group>
          );
        },
        mantineTableHeadCellProps: ({ row }) => {
          return {
            className: classes["head-cells-op-team"],
          };
        },
        Cell: ({ row }) => {
          const {
            asin,
            image,
            fulfillmentChannel,
            sku,
            createdDate,
            testDate,
            markOptimized,
          } = row.original;
          const url = `https://www.amazon.com/dp/${asin}`;
          return (
            <Flex direction="column">
              <Grid>
                <Grid.Col span={6}>
                  <Tooltip label={url}>
                    <Image
                      src={image || "/images/content/not_found_2.jpg"}
                      width="100%"
                      height="100%"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(url, "_blank");
                      }}
                      fit="contain"
                    />
                  </Tooltip>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Grid>
                    <Grid.Col
                      span={12}
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      <Flex>
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          {sku}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    {createdDate && (
                      <Grid.Col
                        span={12}
                        style={{
                          padding: 0,
                          marginTop: "5px",
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "left",
                            fontSize: 11,
                            color: "gray",
                          }}
                        >
                          List:{" "}
                          {moment(createdDate)
                            .subtract(createdDate)
                            .fromNow(true)}
                        </Text>
                      </Grid.Col>
                    )}
                    {testDate && (
                      <Grid.Col
                        span={12}
                        style={{
                          padding: 0,
                          marginTop: "5px",
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "left",
                            fontSize: 11,
                            color: "gray",
                          }}
                        >
                          Ads:{" "}
                          {moment(testDate).subtract(testDate).fromNow(true)}
                        </Text>
                      </Grid.Col>
                    )}

                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      <Tooltip label={url}>
                        <Text
                          style={{
                            fontSize: 12,
                            color: "gray",
                            cursor: "pointer",
                            textAlign: "left",
                          }}
                          onClick={() => {
                            window.open(url, "_blank");
                          }}
                        >
                          {asin} - {fulfillmentChannel}
                        </Text>
                      </Tooltip>
                    </Grid.Col>
                    {markOptimized > 0 && (
                      <Grid.Col
                        span={12}
                        style={{
                          padding: 0,
                          marginTop: "5px",
                        }}
                      >
                        <Group>
                          <Text
                            style={{
                              textAlign: "left",
                              fontSize: 11,
                              color: "gray",
                              // marginLeft: "3px",
                            }}
                          >
                            Được add tay
                          </Text>
                          <IconX
                            size="xs"
                            color="gray"
                            style={{
                              width: "15%",
                              height: "15%",
                              cursor: "pointer",
                            }}
                            stroke={1.5}
                            onClick={() => {
                              openUpdateStatusConfirmModal({
                                id: row.original.id,
                                sku: row.original.sku,
                              });
                            }}
                          />
                        </Group>
                      </Grid.Col>
                    )}
                  </Grid>
                </Grid.Col>
              </Grid>
            </Flex>
          );
        },
      },
      {
        accessorKey: "time",
        header: "Time",
        size: 130,
        Header: () => {
          return (
            <Grid>
              <Grid.Col
                span={7}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "100%",
                    wordBreak: "break-word", // Break long words
                    whiteSpace: "normal", // Allow text to wrap
                    overflowWrap: "break-word", // Break long words
                  }}
                >
                  Tồn Time
                </Text>
              </Grid.Col>
              <Grid.Col
                span={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {(!query?.primarySortBy || query?.primarySortBy !== "time") && (
                  <ActionIcon
                    aria-label="Settings"
                    variant="default"
                    size="sm"
                    style={{
                      background: "none",
                      border: "none",
                    }}
                    onClick={() => {
                      setPagination({
                        ...pagination,
                        currentPage: 1,
                      });
                      setIsConfirmedQuery(true);
                      setQuery({
                        ...query,
                        primarySortBy: "time",
                        primarySortDir: "desc",
                      });
                    }}
                  >
                    <IconArrowsSort
                      style={{
                        width: "60%",
                        height: "60%",
                        fontWeight: "bold",
                      }}
                      stroke={2}
                      color="#ffffff"
                    />
                  </ActionIcon>
                )}

                {query?.primarySortBy === "time" &&
                  query?.primarySortDir === "desc" && (
                    <ActionIcon
                      variant="filled"
                      aria-label="Settings"
                      color="transparent"
                      size="sm"
                      onClick={() => {
                        setPagination({
                          ...pagination,
                          currentPage: 1,
                        });
                        setIsConfirmedQuery(true);
                        setQuery({
                          ...query,
                          primarySortBy: "time",
                          primarySortDir: "asc",
                        });
                      }}
                    >
                      <IconSortDescending
                        style={{ width: "70%", height: "70%" }}
                        stroke={2}
                        color="#70B1ED"
                      />
                    </ActionIcon>
                  )}
                {query?.primarySortBy === "time" &&
                  query?.primarySortDir === "asc" && (
                    <ActionIcon
                      variant="filled"
                      aria-label="Settings"
                      size="sm"
                      color="transparent"
                      onClick={() => {
                        setPagination({
                          ...pagination,
                          currentPage: 1,
                        });
                        setIsConfirmedQuery(true);
                        setQuery({
                          ...query,
                          primarySortBy: null,
                          primarySortDir: null,
                        });
                      }}
                    >
                      <IconSortAscending
                        style={{
                          width: "70%",
                          height: "70%",
                          fontWeight: "bold",
                        }}
                        stroke={2}
                        color="#70B1ED"
                      />
                    </ActionIcon>
                  )}
              </Grid.Col>
            </Grid>
          );
        },
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-op-team"],
            rowSpan: 1,
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-op-team"],
          };
        },
        Cell: ({ row }) => {
          const { time } = row.original;
          return (
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {time} {time > 1 ? "days" : "day"}
            </Text>
          );
        },
      },
      {
        accessorKey: "createdDate",
        size: 100,
        maxSize: 100,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-op-team"],
            rowSpan: 1,
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-op-team"],
          };
        },
        Header: () => {
          return (
            <Group gap={5}>
              <Grid>
                <Grid.Col
                  span={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      textAlign: "center",
                      width: "100%",
                      wordBreak: "break-word", // Break long words
                      whiteSpace: "normal", // Allow text to wrap
                      overflowWrap: "break-word", // Break long words
                    }}
                  >
                    7 days Order
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {(!query?.primarySortBy ||
                    query?.primarySortBy === "ordersInRange" ||
                    query?.primarySortBy === "createdDate" ||
                    query?.primarySortBy === "testDate" ||
                    query?.primarySortBy === "time") && (
                    <ActionIcon
                      aria-label="Settings"
                      variant="default"
                      style={{
                        background: "none",
                        border: "none",
                      }}
                      onClick={() => {
                        setIsConfirmedQuery(true);
                        setPagination({
                          ...pagination,
                          currentPage: 1,
                        });
                        setQuery({
                          ...query,
                          primarySortBy: "totalOrders7d",
                          primarySortDir: "desc",
                        });
                      }}
                    >
                      <IconArrowsSort
                        style={{
                          width: "60%",
                          height: "60%",
                          fontWeight: "bold",
                        }}
                        stroke={2}
                        color="#ffffff"
                      />
                    </ActionIcon>
                  )}

                  {query?.primarySortBy === "totalOrders7d" &&
                    query?.primarySortDir === "desc" && (
                      <ActionIcon
                        variant="filled"
                        aria-label="Settings"
                        color="transparent"
                        onClick={() => {
                          setIsConfirmedQuery(true);
                          setPagination({
                            ...pagination,
                            currentPage: 1,
                          });
                          setQuery({
                            ...query,
                            primarySortBy: "totalOrders7d",
                            primarySortDir: "asc",
                          });
                        }}
                      >
                        <IconSortDescending
                          style={{ width: "70%", height: "70%" }}
                          stroke={2}
                          color="#70B1ED"
                        />
                      </ActionIcon>
                    )}
                  {query?.primarySortBy === "totalOrders7d" &&
                    query?.primarySortDir === "asc" && (
                      <ActionIcon
                        variant="filled"
                        aria-label="Settings"
                        color="transparent"
                        onClick={() => {
                          setIsConfirmedQuery(true);
                          setPagination({
                            ...pagination,
                            currentPage: 1,
                          });
                          setQuery({
                            ...query,
                            primarySortBy: null,
                            primarySortDir: null,
                          });
                        }}
                      >
                        <IconSortAscending
                          style={{
                            width: "70%",
                            height: "70%",
                            fontWeight: "bold",
                          }}
                          stroke={2}
                          color="#70B1ED"
                        />
                      </ActionIcon>
                    )}
                </Grid.Col>
              </Grid>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const { totalOrders7d } = row.original;
          return (
            <Group
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {totalOrders7d?.toLocaleString()}
              </Text>
              {/* <Text
            style={{
              fontSize: "12px",
              fontWeight: "thin",
              color: "gray",
            }}
          >
            {moment(createdDate)
              .tz("America/Los_Angeles")
              .format("DD MMM YYYY")}
          </Text> */}
            </Group>
          );
        },
      },
      {
        accessorKey: "value",
        header: "Value",
        size: 120,
        maxSize: 120,
        enableEditing: true,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-op-team"],
            rowSpan: 1,
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-op-team"],
          };
        },
        Cell: ({ row }) => {
          const sku = row?.original?.sku;
          const foundData = find(data, { sku });
          const value = foundData?.value;
          let color = null;
          switch (value) {
            case 1:
              color = "#cfcfcf";
              break;
            case 2:
              color = "yellow";
              break;
            case 3:
              color = "green";
              break;
            case 4:
              color = "#38761C";
              break;
            default:
              break;
          }
          return color ? (
            <Badge color={color} variant="filled">
              {CONVERT_NUMBER_TO_STATUS[value]}
            </Badge>
          ) : (
            <span>{CONVERT_NUMBER_TO_STATUS[value]}</span>
          );
        },
        Edit: ({ row }) => {
          const sku = row?.original?.sku;
          const foundData = find(data, { sku });
          return (
            <Select
              size="xs"
              data={["Small", "Medium", "Big", "Super Big"]}
              value={CONVERT_NUMBER_TO_STATUS[foundData?.value]}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      value: CONVERT_STATUS_TO_NUMBER[value],
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateASIN(sku, {
                  value: CONVERT_STATUS_TO_NUMBER[value],
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "PIC",
        header: "PIC",
        size: 50,
        maxSize: 50,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: { className: classes["body-cells-op-team"] },
        mantineTableHeadCellProps: {
          className: classes["head-cells-op-team"],
        },
        Cell: ({ row }) => {
          const sku = row?.original?.sku;
          const foundData = find(data, { sku });
          const pic = foundData?.pic;
          return (
            <AppSelect
              options={MEMBER_MKT_PIC}
              defaultValue={pic}
              onChange={(e) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      pic: e.value,
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  pic: e.value,
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 130,
        maxSize: 130,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-op-team"],
            rowSpan: 1,
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-op-team"],
          };
        },
        Cell: ({ row }) => {
          const sku = row?.original?.sku;
          const optimized = row?.original?.optimized || 0;
          return (
            <Select
              size="xs"
              allowDeselect={false}
              styles={{
                ...(optimized === 1 && {
                  input: {
                    backgroundColor: "#FFFF5C",
                    color: "#000000",
                  },
                }),
                ...(optimized === 2 && {
                  input: {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  },
                }),
              }}
              data={values(AMZ_DASHBOARD_STATUS)}
              value={CONVERT_NUMBER_TO_AMZ_DASHBOARD_STATUS[optimized]}
              onChange={(value) => {
                const newFollow = CONVERT_STATUS_TO_AMZ_DASHBOARD_NUMBER[value];
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimized: newFollow,
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateASIN(sku, { optimized: newFollow });
              }}
            />
          );
        },
      },
      {
        accessorKey: "checkedDate",
        header: "Check Date",
        size: 150,
        maxSize: 150,
        enableEditing: true,
        enableSorting: false,
        mantineTableBodyCellProps: {
          className: classes["body-cells-op-team"],
        },
        mantineTableHeadCellProps: {
          className: classes["head-cells-op-team"],
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { checkedDate } = payload || {};
          return (
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {checkedDate ? moment(checkedDate).format("MMM DD") : ""}
            </Text>
          );
        },
        Edit: ({ row, table }) => {
          const sku = row?.original?.sku;
          return (
            <input
              type="date"
              min={moment().format("YYYY-MM-DD")}
              onChange={(event) => {
                const value = event.target.value;
                const newData = map(data, (item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      checkedDate: moment(value).format("MMM DD"),
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, { checkedDate: value });
                table.setEditingCell(null);
              }}
            />
          );
        },
      },
      {
        accessorKey: "threadLink",
        header: "Thread Link",
        size: 150,
        maxSize: 150,
        enableEditing: true,
        enableSorting: false,
        mantineTableBodyCellProps: {
          className: classes["body-cells-op-team"],
        },
        mantineTableHeadCellProps: {
          className: classes["head-cells-op-team"],
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { threadLink } = payload || {};
          return <BadgeLink link={threadLink} />;
        },
        Edit: ({ row }) => {
          const sku = row?.original?.sku;
          return (
            <EditLink
              onBlurSuccess={(value) => {
                const newData = map(data, (item) => {
                  if (item?.sku === sku) {
                    return {
                      ...item,
                      threadLink: value,
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, { threadLink: value });
              }}
            />
          );
        },
      },
      {
        accessorKey: "price",
        header: "Price",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-info"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - P1
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Giá/Flow Custom
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const price =
            optimizedInfo?.price ||
            AMZ_OPTIMIZED_INFO_STATUS?.PRICE?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((price === AMZ_OPTIMIZED_INFO_STATUS?.PRICE?.DONE ||
                    price === AMZ_OPTIMIZED_INFO_STATUS?.PRICE?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(price === AMZ_OPTIMIZED_INFO_STATUS?.PRICE?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[price] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        price: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    price: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "thumbListing",
        header: "Thumb Listing",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-info"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - P2
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Thumb Listing
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const status =
            optimizedInfo?.thumbListing ||
            AMZ_OPTIMIZED_INFO_STATUS?.THUMB_LISTING?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.THUMB_LISTING?.DONE ||
                    status ===
                      AMZ_OPTIMIZED_INFO_STATUS?.THUMB_LISTING?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.THUMB_LISTING?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[status] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        thumbListing: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    thumbListing: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "videoAPlus",
        header: "Video/A+",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-info"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - P3
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                A+
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const status =
            optimizedInfo?.videoAPlus ||
            AMZ_OPTIMIZED_INFO_STATUS?.VIDEO_A_PLUS?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.VIDEO_A_PLUS?.DONE ||
                    status === AMZ_OPTIMIZED_INFO_STATUS?.VIDEO_A_PLUS?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.VIDEO_A_PLUS?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[status] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        videoAPlus: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    videoAPlus: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "videoAPlusP4",
        header: "VideoP4/A+",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-info"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - P4
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Video
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const status =
            optimizedInfo?.videoAPlusP4 ||
            AMZ_OPTIMIZED_INFO_STATUS?.P4_VIDEO_A_PLUS?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.P4_VIDEO_A_PLUS?.DONE ||
                    status ===
                      AMZ_OPTIMIZED_INFO_STATUS?.P4_VIDEO_A_PLUS?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.P4_VIDEO_A_PLUS?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[status] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        videoAPlusP4: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    videoAPlusP4: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "niche",
        header: "Niche",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-info"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - P5
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Ngách
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const status =
            optimizedInfo?.niche ||
            AMZ_OPTIMIZED_INFO_STATUS?.NICHE?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((status === AMZ_OPTIMIZED_INFO_STATUS?.NICHE?.DONE ||
                    status === AMZ_OPTIMIZED_INFO_STATUS?.NICHE?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(status === AMZ_OPTIMIZED_INFO_STATUS?.NICHE?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[status] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        niche: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    niche: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "bidAndBudget",
        header: "Bid & Budget",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-info-light-color"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - M1
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Bid + Budget
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const status =
            optimizedInfo?.bidAndBudget ||
            AMZ_OPTIMIZED_INFO_STATUS?.BID_AND_BUDGET?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.BID_AND_BUDGET?.DONE ||
                    status ===
                      AMZ_OPTIMIZED_INFO_STATUS?.BID_AND_BUDGET?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.BID_AND_BUDGET?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[status] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        bidAndBudget: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    bidAndBudget: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "openCampSP",
        header: "Open Camp SP",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-info-light-color"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - M2
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Mở Camp SP
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const status =
            optimizedInfo?.openCampSP ||
            AMZ_OPTIMIZED_INFO_STATUS?.OPEN_CAMP_SP?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.OPEN_CAMP_SP?.DONE ||
                    status === AMZ_OPTIMIZED_INFO_STATUS?.OPEN_CAMP_SP?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.OPEN_CAMP_SP?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[status] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        openCampSP: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    openCampSP: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "videoSBV",
        header: "Video SBV",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-info-light-color"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - M3
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Video SBV
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const status =
            optimizedInfo?.videoSBV ||
            AMZ_OPTIMIZED_INFO_STATUS?.VIDEO_SBV?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((status === AMZ_OPTIMIZED_INFO_STATUS?.VIDEO_SBV?.DONE ||
                    status === AMZ_OPTIMIZED_INFO_STATUS?.VIDEO_SBV?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.VIDEO_SBV?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[status] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        videoSBV: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    videoSBV: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "sampleQC",
        header: "Sample QC",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-fulfill-color"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - F1
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Q/C Sample
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const status =
            optimizedInfo?.sampleQC ||
            AMZ_OPTIMIZED_INFO_STATUS?.SAMPLE_QC?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((status === AMZ_OPTIMIZED_INFO_STATUS?.SAMPLE_QC?.DONE ||
                    status === AMZ_OPTIMIZED_INFO_STATUS?.SAMPLE_QC?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.SAMPLE_QC?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[status] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        sampleQC: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    sampleQC: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "openVariant",
        header: "Open Variant",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-fulfill-color"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - F2
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Mở Variant
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const status =
            optimizedInfo?.openVariant ||
            AMZ_OPTIMIZED_INFO_STATUS?.OPEN_VARIANT?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.OPEN_VARIANT?.DONE ||
                    status === AMZ_OPTIMIZED_INFO_STATUS?.OPEN_VARIANT?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(status ===
                    AMZ_OPTIMIZED_INFO_STATUS?.OPEN_VARIANT?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[status] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        openVariant: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    openVariant: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "cogs",
        header: "Cogs",
        size: 120,
        maxSize: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableBodyCellProps: ({ row }) => {
          return {
            className: classes["body-cells-optimized-info"],
            style: {
              "--mrt-row-hover-background-color": "#ffffff",
              padding: "8px",
            },
          };
        },
        mantineTableHeadCellProps: () => {
          return {
            className: classes["head-cells-optimized-fulfill-color"],
          };
        },
        Header: () => {
          return (
            <Group
              dir="column"
              style={{
                padding: "10px",
                gap: 2,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                O - F3
              </Text>
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Cogs
              </Text>
            </Group>
          );
        },
        Cell: ({ row }) => {
          const sku = row.original.sku;
          const payload = find(data, { sku });
          const { optimizedInfo } = payload || {};
          const status =
            optimizedInfo?.cogs || AMZ_OPTIMIZED_INFO_STATUS?.COGS?.NOT_CHECKED;
          return (
            <Select
              size="xs"
              styles={{
                input: {
                  ...((status === AMZ_OPTIMIZED_INFO_STATUS?.COGS?.DONE ||
                    status === AMZ_OPTIMIZED_INFO_STATUS?.COGS?.OK) && {
                    backgroundColor: "#40c057",
                    color: "#ffffff",
                  }),
                  ...(status === AMZ_OPTIMIZED_INFO_STATUS?.COGS?.CHECKED && {
                    backgroundColor: "#fab005",
                    color: "#ffffff",
                  }),
                },
              }}
              data={values(OPTIMIZED_INFO_NUMBER_TO_STATUS)}
              value={OPTIMIZED_INFO_NUMBER_TO_STATUS[status] || null}
              onChange={(value) => {
                const newData = data.map((item) => {
                  if (item.sku === sku) {
                    return {
                      ...item,
                      optimizedInfo: {
                        ...item.optimizedInfo,
                        cogs: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                      },
                    };
                  }
                  return item;
                });
                setData(newData);
                handleUpdateAMZDashboard(sku, {
                  optimizedInfo: {
                    ...payload?.optimizedInfo,
                    cogs: OPTIMIZED_INFO_STATUS_TO_NUMBER[value],
                  },
                });
              }}
            />
          );
        },
      },
    ],
    [data, setIsConfirmedQuery, query, pagination, setPagination]
  );

  // Custom sorting change handler
  const handleSortingChange = (newSorting) => {
    setQuery({
      ...query,
      sortBy: null,
      sortDir: null,
    });
    setPagination({
      ...pagination,
      currentPage: 1,
    });
    setSorting(newSorting);
  };

  const table = useMantineReactTable({
    columns,
    data,
    editDisplayMode: "cell",
    enablePagination: false,
    getRowId: (row) => row.id,
    enableRowSelection: false,
    enableEditing: true,
    enableFilters: false,
    enableToolbarInternalActions: false,
    enableTopToolbar: false,
    enableColumnActions: false,
    mantineTableHeadCellProps: { className: classes["head-cells"] },
    mantineTableProps: {
      className: classes["disable-hover"],
    },
    enableDensityToggle: false,
    state: {
      showProgressBars: loading,
      sorting,
      isLoading: loading,
    },
    mantineTableBodyCellProps: ({ cell, table }) => ({
      className: classes["body-cells"],
      sx: {
        cursor: "pointer",
      },
      onDoubleClick: () => {
        if (
          cell &&
          (cell.column.id === "checkedDate" ||
            cell.column.id === "value" ||
            cell.column.id === "threadLink")
        ) {
          table.setEditingCell(cell);
        }
      },
      onBlur: () => {
        table.setEditingCell(null);
      },
    }),
    onSortingChange: handleSortingChange,
    enableColumnResizing: false,
    enableSorting: true,
    enableMultiSort: false,
    enableBottomToolbar: true,
    manualSorting: true,
    mantineBottomToolbarProps: () => {
      return {
        className: classes["bottom-toolbar"],
      };
    },
    mantinePaperProps: {
      style: {
        "--mrt-row-hover-background-color": "#E1EAFF",
        "--mrt-pinned-column-background-color": "#E1EAFF",
      },
    },
    renderBottomToolbarCustomActions: () => {
      return (
        <Button
          loading={loading}
          disabled={pagination.currentPage >= pagination.totalPages}
          onClick={() => {
            setPagination((prev) => ({
              ...prev,
              currentPage: prev.currentPage + 1,
            }));
            setIsConfirmedQuery(true);
            setIsLoadmore(true);
          }}
        >
          Load More
        </Button>
      );
    },
    enableStickyHeader: true,
    enableStickyFooter: true,
    mantineTableContainerProps: {
      // ref: tableContainerRef, // Attach ref to the scrollable container
    },
    enableColumnPinning: true,
    initialState: {
      columnPinning: {
        left: ["product", "time", "createdDate", "value", "PIC", "status"],
      },
    },
  });

  return !isEmpty(tableData) ? <MantineReactTable table={table} /> : null;
};

export default OptimizedTableMode;
