import { useCallback, useEffect, useState } from "react";
import apiClient from "../services/axiosClient";
import removeNullKeys from "../utils/removeNullKeys";
import usePagination from "./usePagination";
import { isEmpty, map } from "lodash";

const useGetSampleListVideo = ({
  filters = {},
  defaultFilters = {},
  endPoint = "/briefs/video/v2",
}) => {
  const { pagination, handleChangePage, setTotalPages } = usePagination();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const filter = removeNullKeys({
        videoStatus: filters?.status,
        rnd: filters?.rndId,
        rndTeam: filters?.rndTeam,
        value: filters.value,
        startDate: filters.startDate,
        endDate: filters.endDate,
        startVideoRequestedDate: filters.startVideoRequestedDate,
        endVideoRequestedDate: filters.endVideoRequestedDate,
        sku: filters.sku,
        hasSupplier: filters.hasSupplier,
        videoSupplier: filters.videoSupplier,
        videoEditorId: filters.videoEditorId,
        isDoneSource: filters.isDoneSource,
        archive: filters.archive,
        projects: filters.projects,
      });

      const queryParams = {
        page: pagination.page,
      };

      if (Object.keys(filter).length > 0) {
        queryParams["filter"] = JSON.stringify(filter);
      } else {
        // load default filters
        queryParams["filter"] = JSON.stringify(defaultFilters);
      }

      const result = await apiClient.get(endPoint, {
        params: queryParams,
      });

      setData({
        ...result.data,
        data: map(result?.data?.data, (x) => ({
          ...x,
          ...(!isEmpty(x.briefs) &&
            x?.briefs.length > 1 && {
              subRows: map(x?.briefs, (brief, index) => ({
                ...brief,
                videoIndex: index + 1,
              })),
              isDisabled: true,
            }),
          ...(!isEmpty(x.briefs) &&
            x?.briefs.length === 1 && { ...x?.briefs[0], videoIndex: 1 }),
        })),
      });
      setTotalPages(result.data.metadata.totalPages);
    } catch (error) {
      if (error.message) {
        return setError(error.message);
      }
      setError("Unhandle error");
    } finally {
      setLoading(false);
    }
  }, [filters, pagination.page, setTotalPages]);

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    pagination,
    data,
    loading,
    setLoading,
    error,
    handleChangePage,
    refetch: getData,
  };
};

export default useGetSampleListVideo;
