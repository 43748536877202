import React, { useState } from "react";
import { Image, Modal } from "@mantine/core";
import styles from "./ImagePreview.module.sass";

const DEFAULT_IMAGE = "/images/content/not_found_2.jpg";

const ImagePreview = ({
  src,
  width = 50,
  height = 50,
  radius = "sm",
  withPlaceholder = true,
  fit = "contain",
  alt = "Preview image",
  showDefaultImage = true,
}) => {
  const [opened, setOpened] = useState(false);

  // Determine which image to show
  const displaySrc = src || (showDefaultImage ? DEFAULT_IMAGE : null);

  if (!displaySrc) return null;

  return (
    <>
      <div
        className={styles.imageWrapper}
        onClick={() => displaySrc && setOpened(true)}
      >
        <Image
          src={displaySrc}
          width={width}
          height={height}
          radius={radius}
          withPlaceholder={withPlaceholder}
          fit={fit}
          alt={alt}
          className={styles.thumbnail}
        />
      </div>

      {displaySrc && (
        <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          size="xl"
          padding="xs"
          className={styles.modal}
          withCloseButton={false}
          centered
        >
          <div className={styles.modalContent}>
            <Image
              src={displaySrc}
              fit="contain"
              className={styles.fullImage}
              onClick={() => setOpened(false)}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ImagePreview;
