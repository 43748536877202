import {
  JsonParam,
  NumberParam,
  NumericArrayParam,
  NumericObjectParam,
  ObjectParam,
  StringParam,
  withDefault,
} from "use-query-params";
import {
  DateRangeParam,
  NullableStringParam,
  StatusValueParam,
} from "../../../utils";

export const defaultQueryParam = {
  defaultStatusValue: StatusValueParam,
  defaultStatus: withDefault(NumericArrayParam, [2, 12]),
  defaultPriority: withDefault(NumberParam, 1),
};

export const DEFAULT_QUERY = {
  statusValue: defaultQueryParam.defaultStatusValue,
  status: defaultQueryParam.defaultStatus,
  priority: defaultQueryParam.defaultPriority,
  // epmPriority: defaultQueryParam.defaultPriority,
  date: ObjectParam,
  batch: StringParam,
  sku: StringParam,
  briefType: StringParam,
  size: NumericObjectParam,
  rndTeam: StringParam,
  rnd: StringParam,
  designer: StringParam,
  sizeValue: NullableStringParam,
  rndName: StringParam,
  designerName: StringParam,
  dateValue: DateRangeParam,
  briefTypes: JsonParam,
  rndTeams: JsonParam,
  rnds: JsonParam,
  designers: JsonParam,
  epms: JsonParam,
  archive: NumberParam,
  directions: JsonParam,
};
