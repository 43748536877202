import React, { useMemo, useState } from "react";
import { MantineReactTable } from "mantine-react-table";
import {
  Button,
  Group,
  TextInput,
  Select,
  ActionIcon,
  Flex,
  Pagination,
  Text,
} from "@mantine/core";
import { IconPlus, IconCheck, IconX, IconFilterOff } from "@tabler/icons-react";
import { isEmpty, keys, map, omit, toNumber } from "lodash";
import classes from "./Table.module.css";
import moment from "moment-timezone";
import StatusBadge from "../../../components/StatusBadge";
import TextLink from "../../../components/TextLink";
import { DateRangePicker } from "rsuite";
import { SUPPLIER_SAMPLE_REQUEST } from "../../../constant";
import ImagePreview from "../../../components/ImagePreview";

const EDITABLE_COLUMNS = ["printUrl", "productType", "quantity"];

const SUPPLIER_OPTIONS = map(keys(SUPPLIER_SAMPLE_REQUEST), (key) => ({
  value: SUPPLIER_SAMPLE_REQUEST[key],
  label: key,
}));

const STATUS_OPTIONS = [
  { value: "all", label: "All" },
  { value: "Done Producing", label: "Done Producing" },
  { value: "Shipped", label: "Shipped" },
  { value: "Processing", label: "Processing" },
];

const RECEIVED_OPTIONS = [
  { value: "all", label: "All" },
  { value: "Yes", label: "Đã nhận" },
  { value: "No", label: "Chưa nhận" },
];

const EditableCell = ({
  type = "text",
  value,
  onChange,
  onBlur,
  onKeyDown,
  options = [],
}) => {
  const commonStyles = {
    input: {
      textAlign: "center",
    },
  };

  switch (type) {
    case "select":
      return (
        <Select
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          data={options}
          autoFocus
          styles={commonStyles}
        />
      );

    case "text":
    default:
      return (
        <TextInput
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          autoFocus
          styles={commonStyles}
        />
      );
  }
};

const EditCell = ({ row, table, field, onUpdate, type, options }) => {
  const [value, setValue] = useState(row.original[field] || "");

  if (row.original.isNew) return null;

  return (
    <EditableCell
      type={type}
      value={value}
      options={options}
      onChange={(e) => {
        switch (type) {
          case "select":
            setValue(e);
            break;
          case "number":
            setValue(toNumber(e.target.value));
            break;
          default:
            setValue(e.target.value);
            break;
        }
      }}
      onBlur={() => onUpdate(row, field, value, table)}
      onKeyDown={(e) => {
        if (e.key === "Enter") e.currentTarget.blur();
      }}
    />
  );
};

const FilterGroup = ({ label, children }) => (
  <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Text size="sm" fw={500} c="dimmed">
      {label}
    </Text>
    {children}
  </div>
);

const Table = ({
  data,
  loading,
  createSampleRequest,
  pagination,
  updateSampleRequest,
  query,
  setQuery,
}) => {
  const [newRow, setNewRow] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  // Add local state for text input
  const [productTypeInput, setProductTypeInput] = useState(query.productType);

  const handleProductTypeKeyDown = (e) => {
    if (e.key === "Enter") {
      setQuery((prev) => ({
        ...prev,
        productType: productTypeInput,
      }));
    }
  };

  const isRowValid = useMemo(() => {
    if (!newRow) return false;
    return (
      !isEmpty(newRow.productType) &&
      !isEmpty(newRow.supplierName) &&
      newRow.quantity
    );
  }, [newRow]);

  const handleEditCell = (row, field, value, table) => {
    if (value !== row.original[field]) {
      updateSampleRequest(row.original.uid, {
        [field]: value,
      });
    }
    table.setEditingCell(null);
  };
  const handleReceiveClick = (row) => {
    updateSampleRequest(row.original.uid, { uidReceived: "Yes" });
  };
  const getEditComponent =
    (field, type = "text", options = []) =>
    (props) =>
      (
        <EditCell
          {...props}
          field={field}
          onUpdate={handleEditCell}
          type={type}
          options={options}
        />
      );

  const columns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        size: 120,
        enableEditing: false,
        enableSorting: false,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
      },
      {
        accessorKey: "supplierName",
        header: "Supplier Name",
        size: 100,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: false,
        enableSorting: false,
        Cell: ({ row }) =>
          row.original.isNew ? (
            <EditableCell
              type="select"
              value={row.original.supplierName || ""}
              onChange={(value) => handleNewRowChange("supplierName", value)}
              options={SUPPLIER_OPTIONS}
            />
          ) : (
            row.original.supplierName
          ),
        Edit: getEditComponent("supplierName", "select", SUPPLIER_OPTIONS),
      },
      {
        accessorKey: "newPLBriefInfo.imageRef",
        header: "Hình Ref",
        size: 100,
        enableEditing: false,
        enableSorting: false,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => (
          <ImagePreview src={row?.original?.newPLBriefInfo?.imageRef} />
        ),
      },
      {
        accessorKey: "productType",
        header: "Tên Product (Product Type)",
        size: 200,
        mantineTableHeadCellProps: { className: classes["edit-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: true,
        enableSorting: false,
        Cell: ({ row }) =>
          row.original.isNew ? (
            <EditableCell
              value={row.original.productType || ""}
              onChange={(e) =>
                handleNewRowChange("productType", e.target.value)
              }
            />
          ) : (
            row.original.productType
          ),
        Edit: getEditComponent("productType"),
      },

      {
        accessorKey: "printUrl",
        header: "Print URL",
        size: 120,
        mantineTableHeadCellProps: { className: classes["edit-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: true,
        enableSorting: false,
        Cell: ({ row }) =>
          row.original.isNew ? (
            <EditableCell
              value={row.original.printUrl || ""}
              onChange={(e) => handleNewRowChange("printUrl", e.target.value)}
            />
          ) : (
            <TextLink href={row.original.printUrl}>
              {row.original.printUrl}
            </TextLink>
          ),
        Edit: getEditComponent("printUrl", "url"),
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        size: 100,
        mantineTableHeadCellProps: { className: classes["edit-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: true,
        enableSorting: false,
        Cell: ({ row }) => {
          if (row.original.isNew) {
            return (
              <TextInput
                value={row.original.quantity || ""}
                onChange={(e) => {
                  handleNewRowChange("quantity", toNumber(e.target.value));
                }}
                styles={{
                  input: {
                    textAlign: "center",
                  },
                }}
              />
            );
          }
          return row.original.quantity;
        },
        Edit: getEditComponent("quantity", "number"),
      },
      {
        accessorKey: "productCode",
        header: "Product Code",
        size: 120,
        mantineTableHeadCellProps: { className: classes["edit-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: true,
        enableSorting: false,
        Cell: ({ row }) => {
          if (row.original.isNew) {
            return (
              <TextInput
                value={row.original.productCode || ""}
                onChange={(e) => {
                  handleNewRowChange("productCode", e.target.value);
                }}
                styles={{
                  input: {
                    textAlign: "center",
                  },
                }}
              />
            );
          }
          return row.original.productCode;
        },
        Edit: getEditComponent("productCode", "text"),
      },
      {
        accessorKey: "estimatedArrivalDate",
        header: "Dự kiến ngày nhận Sample",
        size: 100,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: false,
        enableSorting: false,
        Cell: ({ row }) => row.original.estimatedArrivalDate,
      },
      {
        accessorKey: "supplierStatus",
        header: "Status",
        size: 100,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: false,
        enableSorting: false,
        Cell: ({ row }) => <StatusBadge status={row.original.supplierStatus} />,
        Edit: getEditComponent("supplierStatus", "select", [
          { value: "active", label: "Active" },
          { value: "inactive", label: "Inactive" },
        ]),
      },
      {
        accessorKey: "shippedOutDate",
        header: "Shipped Out Date",
        size: 100,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: false,
        enableSorting: false,
        Edit: getEditComponent("shippedOutDate", "date"),
      },
      {
        accessorKey: "trackingNumber",
        header: "Tracking Number",
        size: 100,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: false,
        enableSorting: false,
      },
      {
        accessorKey: "uidReceived",
        header: "Đã nhận",
        size: 100,
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: { className: classes["head-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        Cell: ({ row }) => {
          if (!row.original.uidReceived) {
            return (
              <Button
                variant="outline"
                color="gray"
                onClick={() => handleReceiveClick(row)}
                size="xs"
                radius="xs"
              >
                Confirm
              </Button>
            );
          }
          return <StatusBadge status="received" />;
        },
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 100,
        mantineTableHeadCellProps: { className: classes["edit-cells"] },
        mantineTableBodyCellProps: { className: classes["body-cells"] },
        enableEditing: false,
        enableSorting: false,
        Cell: ({ row }) => {
          if (row.original.isNew) {
            return (
              <Group spacing={4} justify="center">
                <ActionIcon
                  color="green"
                  onClick={() => handleSubmitNewRow(row.original)}
                  loading={loading.create}
                  disabled={!isRowValid}
                >
                  <IconCheck size={18} />
                </ActionIcon>
                <ActionIcon color="red" onClick={() => handleCancelNewRow()}>
                  <IconX size={18} />
                </ActionIcon>
              </Group>
            );
          }
          return null;
        },
      },
    ],
    [loading.create, isRowValid, updateSampleRequest, data]
  );

  const handleNewRowChange = (field, value) => {
    setNewRow((prev) => ({
      ...prev,
      [field]: value,
    }));
    // Clear validation error for this field
    if (validationErrors[field]) {
      setValidationErrors((prev) => ({
        ...prev,
        [field]: undefined,
      }));
    }
  };

  const validateNewRow = (row) => {
    const errors = {};
    if (isEmpty(row.productType)) {
      errors.productType = "Product Type is required";
    }
    if (isEmpty(row.supplierName)) {
      errors.supplierName = "Supplier Name is required";
    }
    if (!row.quantity) {
      errors.quantity = "Quantity is required";
    }
    return errors;
  };

  const handleSubmitNewRow = async (row) => {
    const errors = validateNewRow(row);
    if (keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      const payloads = [{ ...omit(row, ["isNew", "id"]) }];
      await createSampleRequest({ payloads });
      setNewRow(null);
      setValidationErrors({});
    } catch (error) {
      console.error("Failed to create sample request:", error);
    }
  };

  const handleCancelNewRow = () => {
    setNewRow(null);
    setValidationErrors({});
  };

  const handleCreateClick = () => {
    if (newRow) return; // Prevent creating multiple new rows
    const emptyRow = {
      id: "new",
      productType: "",
      supplierName: "",
      printUrl: "",
      quantity: 0,
      isNew: true,
      date: moment().format("YYYY-MM-DD"),
    };
    setNewRow(emptyRow);
  };

  const handleFilterReset = () => {
    setQuery({
      status: "all",
      date: [],
      page: 1,
      search: "",
      productType: "",
      supplierName: null,
      received: "all",
      startDate: null,
      endDate: null,
    });
    setProductTypeInput("");
  };

  const displayData = useMemo(() => {
    if (newRow) {
      return [newRow, ...data];
    }
    return data;
  }, [newRow, data]);

  return (
    <>
      <div className={classes.toolbar}>
        <Flex align="flex-end" gap="md" wrap="wrap">
          <FilterGroup label="Date Range">
            <DateRangePicker
              value={
                query.startDate && query.endDate
                  ? [query.startDate, query.endDate]
                  : []
              }
              onChange={(value) => {
                setQuery((prev) => ({
                  ...prev,
                  startDate: value?.[0],
                  endDate: value?.[1],
                  date: value,
                  page: 1,
                }));
              }}
              style={{ width: 230 }}
              placeholder="Select Date Range"
            />
          </FilterGroup>

          <FilterGroup label="Product Type">
            <TextInput
              placeholder="Search Product Type..."
              style={{ width: 200 }}
              value={productTypeInput}
              onChange={(e) => {
                setProductTypeInput(e.target.value);
              }}
              onKeyDown={handleProductTypeKeyDown}
            />
          </FilterGroup>

          <FilterGroup label="Supplier">
            <Select
              placeholder="Select Supplier"
              style={{ width: 200 }}
              data={SUPPLIER_OPTIONS}
              value={query.supplierName}
              onChange={(value) => {
                setQuery((prev) => ({
                  ...prev,
                  supplierName: value,
                  page: 1,
                }));
              }}
              clearable
            />
          </FilterGroup>

          <FilterGroup label="Status">
            <Select
              placeholder="Select Status"
              style={{ width: 150 }}
              data={STATUS_OPTIONS}
              value={query.supplierStatus}
              onChange={(value) => {
                setQuery((prev) => ({
                  ...prev,
                  supplierStatus: value || "all",
                  page: 1,
                }));
              }}
              clearable
            />
          </FilterGroup>

          <FilterGroup label="Đã nhận">
            <Select
              placeholder="Select Status"
              style={{ width: 150 }}
              data={RECEIVED_OPTIONS}
              value={query.uidReceived}
              onChange={(value) => {
                setQuery((prev) => ({
                  ...prev,
                  uidReceived: value || "all",
                  page: 1,
                }));
              }}
              clearable
            />
          </FilterGroup>

          <Button onClick={handleFilterReset} style={{ height: "36px" }}>
            <IconFilterOff />
          </Button>
        </Flex>
      </div>
      <MantineReactTable
        columns={columns}
        data={displayData}
        editDisplayMode="cell"
        enableEditing={true}
        enablePagination={false}
        getRowId={(row) => row.id}
        enableRowSelection={false}
        enableFilters={false}
        enableTopToolbar={false}
        enableColumnActions={false}
        mantineTableProps={{
          highlightOnHover: true,
          withColumnBorders: true,
          style: {
            tableLayout: "fixed",
          },
        }}
        state={{
          isLoading: loading.fetch || loading.update,
        }}
        mantineTableBodyCellProps={({ cell, table }) => ({
          className: classes["body-cells"],
          onDoubleClick: () => {
            if (EDITABLE_COLUMNS.includes(cell.column.id)) {
              table.setEditingCell(cell);
            }
          },
        })}
      />
      <Flex justify="flex-end">
        <Pagination
          total={pagination.totalPages}
          value={query.page}
          onChange={(value) => {
            setQuery((prev) => ({ ...prev, page: value }));
          }}
          color="pink"
          size="md"
          style={{ marginTop: "20px" }}
        />
      </Flex>

      <Button
        radius="xl"
        style={{
          marginTop: "20px",
          width: "60px",
          height: "60px",
        }}
        color="#3751D7"
        onClick={handleCreateClick}
        disabled={!!newRow}
      >
        <IconPlus style={{ width: "20px", height: "20px" }} />
      </Button>
    </>
  );
};

export default Table;
