import React from "react";
import { Card, Image, Text, Badge } from "@mantine/core";
import styles from "../ProductSelector.module.sass";

const ProductCard = ({ product, isSelected, onClick }) => {
  return (
    <Card
      className={`${styles.card} ${isSelected ? styles.selected : ""}`}
      onClick={onClick}
    >
      <Card.Section>
        <Image
          src={product?.image || product?.imageSrc || product?.imageUrl}
          height={200}
          className={styles.image}
        />
      </Card.Section>

      <Text className={styles.title} lineClamp={2}>
        {product?.title || product?.name}
      </Text>

      {product?.skuPrefix && (
        <Badge className={styles.badge}>{product?.skuPrefix}</Badge>
      )}
    </Card>
  );
};

export default ProductCard;
