import React, { useMemo, useState } from "react";
import { Select, Group, Radio, Stack, Text, Button } from "@mantine/core";
import { useForm, Controller } from "react-hook-form";
import styles from "./BriefForm.module.sass";
import QuillEditor from "../../../components/QuillEditor";
import RefDesignCard from "./RefDesignMarket/RefDesignCard";
import { IconPlus } from "@tabler/icons-react";
import {
  map,
  size,
  filter,
  toLower,
  keys,
  toString,
  isEmpty,
  find,
} from "lodash";
import { UNIQUE_TEAMS, PROJECTS, UID_CARD_SIZES } from "../../../constant";
import { UID_CARD_VALUES } from "../../../utils";
import ProductSelector from "./ProductSelector";
import PreviewModal from "./PreviewModal";

const BriefForm = ({
  onSubmit: submitForm,
  loading,
  initialData,
  onClose,
  directions,
  users,
  fetchProductBases,
  filtersClipArt,
  timeSettings,
}) => {
  const [previewOpened, setPreviewOpened] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      project: "POD",
      direction: "",
      rnd: "",
      team: "",
      value: "",
      designer: "",
      briefType: "",
      productBases: [],
      refs: initialData ? [initialData] : [],
      size: "",
      note: {
        designer: "",
        epm: "",
        mkt: "",
      },
    },
  });
  const selectedRnd = watch("rnd");

  const filteredDesigners = useMemo(() => {
    const rndUser = find(users, { uid: selectedRnd });
    if (isEmpty(rndUser))
      return map(
        filter(users, (user) => toLower(user?.position) === "designer"),
        (user) => ({
          value: user?.uid,
          label: user?.name,
        })
      );

    return map(
      filter(
        users,
        (user) =>
          toLower(user?.position) === "designer" && user.team === rndUser?.team
      ),
      (user) => ({
        value: user?.uid,
        label: user?.name,
      })
    );
  }, [selectedRnd, users]);

  // Reset dependent fields when RnD changes
  React.useEffect(() => {
    if (selectedRnd) {
      const rndUser = find(users, { uid: selectedRnd });
      setValue("team", rndUser?.team);
    }
  }, [selectedRnd, setValue]);

  const formData = watch(); // Get all form values

  const handlePreview = () => {
    setPreviewOpened(true);
  };

  const handleFormSubmit = (payload) => {
    submitForm(payload);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form}>
        <Stack gap="md">
          {/* Info của Batch */}
          <Group className={styles.section}>
            <Text className={styles.sectionTitle}>1. Direction</Text>
            <Group
              grow
              align="flex-start"
              styles={{
                root: {
                  width: "100%",
                },
              }}
            >
              <Controller
                name="project"
                control={control}
                rules={{ required: "Project is required" }}
                render={({ field }) => (
                  <Radio.Group
                    label="Project"
                    className={styles.radioGroup}
                    error={errors.project?.message}
                    classNames={{
                      radio: styles.radio,
                    }}
                    {...field}
                  >
                    <Group mt="xs">
                      {map(PROJECTS, (project) => (
                        <Radio
                          value={project?.VALUE}
                          label={project?.LABEL}
                          key={project?.VALUE}
                        />
                      ))}
                    </Group>
                  </Radio.Group>
                )}
              />

              <Controller
                name="direction"
                control={control}
                rules={{ required: "Direction is required" }}
                render={({ field }) => (
                  <Select
                    label="Direction"
                    placeholder="Select direction"
                    data={map(directions, (direction) => ({
                      value: direction?.uid,
                      label: direction?.name,
                    }))}
                    error={errors.direction?.message}
                    className={styles.input}
                    classNames={{
                      label: styles.inputLabel,
                      wrapper: styles.inputWrapper,
                      dropdown: styles.dropdown,
                      item: styles.dropdownItem,
                      error: styles.error,
                    }}
                    {...field}
                  />
                )}
              />
            </Group>
          </Group>

          {/* RnD Section */}
          <Group className={styles.section}>
            <Text className={styles.sectionTitle}>2. RnD</Text>
            <Group grow>
              <Controller
                name="rnd"
                control={control}
                rules={{ required: "RnD is required" }}
                render={({ field }) => (
                  <Select
                    label="RnD"
                    placeholder="Select RnD"
                    data={map(
                      filter(
                        users,
                        (user) => toLower(user?.position) === "rnd"
                      ),
                      (user) => ({
                        value: user?.uid,
                        label: user?.name,
                      })
                    )}
                    error={errors.rnd?.message}
                    className={styles.input}
                    classNames={{
                      label: styles.inputLabel,
                      dropdown: styles.dropdown,
                      item: styles.dropdownItem,
                      error: styles.error,
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="team"
                control={control}
                rules={{ required: "Team is required" }}
                render={({ field }) => (
                  <Select
                    label="Team"
                    placeholder="Select team"
                    data={UNIQUE_TEAMS}
                    error={errors.team?.message}
                    className={styles.input}
                    classNames={{
                      label: styles.inputLabel,
                      dropdown: styles.dropdown,
                      item: styles.dropdownItem,
                      error: styles.error,
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="value"
                control={control}
                rules={{ required: "Value is required" }}
                render={({ field }) => (
                  <Select
                    label="Value"
                    placeholder="Select value"
                    data={map(keys(UID_CARD_VALUES), (key) => ({
                      value: key,
                      label: UID_CARD_VALUES[key],
                    }))}
                    error={errors.value?.message}
                    className={styles.input}
                    classNames={{
                      label: styles.inputLabel,
                      dropdown: styles.dropdown,
                      item: styles.dropdownItem,
                      error: styles.error,
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="designer"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Designer"
                    placeholder="Select designer"
                    data={filteredDesigners}
                    error={errors.designer?.message}
                    className={styles.input}
                    classNames={{
                      label: styles.inputLabel,
                      dropdown: styles.dropdown,
                      item: styles.dropdownItem,
                      error: styles.error,
                    }}
                    {...field}
                  />
                )}
              />
            </Group>
          </Group>
          {/* Loại Brief */}
          <Group className={styles.section}>
            <Text className={styles.sectionTitle}>1. Chọn Product Base</Text>
            <Controller
              name="productBases"
              control={control}
              render={({ field }) => (
                <ProductSelector
                  value={field.value}
                  onChange={field.onChange}
                  fetchProducts={fetchProductBases}
                  showSync={false}
                  singleSelect
                />
              )}
            />
          </Group>

          <Group className={styles.section}>
            <Text className={styles.sectionTitle}>3. Ref Design (Market)</Text>
            <div className={styles.refDesignSection}>
              <div className={styles.uploadSection}>
                <Controller
                  name="refs"
                  control={control}
                  render={({ field }) => (
                    <Button
                      variant="light"
                      leftSection={<IconPlus size={16} />}
                      onClick={() => field.onChange([...field.value, {}])}
                      fullWidth
                      disabled={size(field.value) === 1}
                    >
                      Add Ref Design
                    </Button>
                  )}
                />
              </div>
              <div className={styles.cardsSection}>
                <Controller
                  name="refs"
                  control={control}
                  render={({ field }) => (
                    <Stack spacing="md">
                      {field.value.map((ref, index) => (
                        <RefDesignCard
                          key={index}
                          value={ref}
                          filters={filtersClipArt}
                          onChange={(newValue) => {
                            const newRefs = [...field.value];
                            newRefs[index] = newValue;
                            field.onChange(newRefs);
                          }}
                          onRemove={() => {
                            field.onChange(
                              field.value.filter((_, i) => i !== index)
                            );
                          }}
                        />
                      ))}
                    </Stack>
                  )}
                />
              </div>
            </div>
          </Group>

          {/* Size Card */}
          <Group className={styles.section}>
            <Text className={styles.sectionTitle}>4. Size Card</Text>
            <Controller
              name="size"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="Select Size"
                  data={map(UID_CARD_SIZES, (size) => ({
                    value: toString(size?.value),
                    label: size?.label,
                  }))}
                  error={errors?.size?.message}
                  className={styles.input}
                  classNames={{
                    label: styles.inputLabel,
                    dropdown: styles.dropdown,
                    item: styles.dropdownItem,
                    error: styles.error,
                  }}
                  {...field}
                />
              )}
            />
          </Group>

          {/* Note */}
          <Group className={styles.section}>
            <Text className={styles.sectionTitle}>5. Note</Text>
            <Group grow style={{ width: "100%" }}>
              <Controller
                name="note.designer"
                control={control}
                render={({ field }) => (
                  <QuillEditor
                    label="Designer Note"
                    error={errors.note?.designer?.message}
                    state={field.value}
                    onChange={field.onChange}
                    height={120}
                  />
                )}
              />
              <Controller
                name="note.epm"
                control={control}
                render={({ field }) => (
                  <QuillEditor
                    label="EPM Note"
                    error={errors.note?.epm?.message}
                    state={field.value}
                    onChange={field.onChange}
                    height={120}
                  />
                )}
              />
              <Controller
                name="note.mkt"
                control={control}
                render={({ field }) => (
                  <QuillEditor
                    label="MKT Note"
                    error={errors.note?.mkt?.message}
                    state={field.value}
                    onChange={field.onChange}
                    height={120}
                  />
                )}
              />
            </Group>
          </Group>

          <div className={styles.formActions}>
            <div className={styles.buttonsWrapper}>
              <Button
                variant="light"
                onClick={onClose}
                className={styles.buttonLight}
              >
                Cancel
              </Button>
              <Button onClick={handlePreview} className={styles.buttonFilled}>
                Preview
              </Button>
            </div>
          </div>
        </Stack>
      </form>

      {previewOpened && (
        <PreviewModal
          opened={previewOpened}
          onClose={() => setPreviewOpened(false)}
          data={formData}
          onSubmit={handleFormSubmit}
          loading={loading}
          users={users}
          timeSettings={timeSettings}
        />
      )}
    </>
  );
};

export default BriefForm;
