import { useState, useCallback, useEffect } from "react";
import { showNotification } from "../../../utils/index";
import { productlineService, rndServices } from "../../../services";
import { toNumber, toString } from "lodash";
import { NEW_PRODUCT_LINE_BRIEF_STATUS } from "../../../constant";

export const useProductBaseForEPM = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    name: "",
    epmId: "",
    status: toString(NEW_PRODUCT_LINE_BRIEF_STATUS.MOCKUP_DONE),
  });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  // call api fetch users for getting epms
  const [epms, setEpm] = useState([]);
  const fetchEpm = useCallback(async () => {
    const { data } = await rndServices.getUsers({
      limit: -1,
    });
    setEpm(data);
  }, []);

  useEffect(() => {
    fetchEpm();
  }, []);

  const fetchProducts = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await productlineService.fetchProductBaseForEPM({
        page: params?.page || 1,
        limit: params?.limit || 10,
        query: {
          ...params,
        },
      });
      setProducts(response?.data || []);
      setPagination({
        currentPage: response?.metadata?.currentPage || 1,
        totalPages: response?.metadata?.totalPages || 1,
      });
    } catch (error) {
      showNotification("Error", error.message, "red");
    } finally {
      setLoading(false);
    }
  }, []);

  const updateProduct = useCallback(
    async (uid, data) => {
      try {
        setLoading(true);
        await productlineService.updateProductBaseForEPM({ uid, data });
        await fetchProducts(transformQuery(query)); // Refresh list after update
        showNotification("Success", "Product updated successfully", "green");
      } catch (error) {
        showNotification("Error", error.message, "red");
      } finally {
        setLoading(false);
      }
    },
    [fetchProducts, query]
  );

  const transformQuery = (query) => {
    return {
      name: query.name || null,
      epmId: query.epmId || null,
      status: query.status
        ? toNumber(query.status)
        : [
            NEW_PRODUCT_LINE_BRIEF_STATUS.EPM_DONE_PRODUCT_BASE,
            NEW_PRODUCT_LINE_BRIEF_STATUS.MOCKUP_DONE,
          ],
      page: query.page || 1,
      limit: query.limit || 10,
    };
  };

  // Effect to fetch data when query changes
  useEffect(() => {
    const params = transformQuery(query);
    fetchProducts(params);
  }, [query, fetchProducts]);

  return {
    products,
    loading,
    updateProduct,
    epms,
    fetchProducts,
    pagination,
    setQuery,
    query,
  };
};
