export const NEW_PRODUCT_LINE_BRIEF_STATUS = {
  BRIEF_CREATED: 1,
  NEW_PRODUCT_LINE_DONE: 2,
  READY_TO_LAUNCH_DONE: 3,
  MOCKUP_DONE: 4,
  EPM_DONE_PRODUCT_BASE: 5,
  OPTIMIZED_MOCKUP_UNDONE: 8,
  OPTIMIZED_MOCKUP_DONE: 9,
};
export const PHOTOGRAPHY_STATUS = {
  UNDONE: -1,
  DONE: 1,
};

export const SUPPLIER_SAMPLE_REQUEST = {
  SLZ: "SLZ",
  LIDONG: "Lidong",
  TIC: "TIC",
  FM: "FM",
  ZESTY: "Zesty",
  BF: "BF",
  KM: "KM",
  FLANK: "Flank",
  METRONS: "Metrons",
  RYSON: "Ryson",
  ALUFF: "AluFF",
  BOBO: "BoBo",
  HUBFF: "HubFF",
  CENDO: "Cendo",
  YOYCOL: "YoyCol",
  TAI: "TAI",
};
