import {
  Table,
  ScrollArea,
  Group,
  ActionIcon,
  TextInput,
  Text,
  Button,
  Box,
  Tooltip,
  CopyButton,
} from "@mantine/core";
import { IconExternalLink, IconCopy } from "@tabler/icons-react";
import { filter, find, flatMap, isEmpty, map, toNumber } from "lodash";
import { IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { campaignServices, postService } from "../../services";
import { showNotification } from "../../utils/index";

const CampaignTable = ({ payload, close, view }) => {
  const headCellStyles = {
    fontWeight: "bold",
    color: "#000000",
    padding: 0,
    backgroundColor: "#e1eaff",
    height: "70px",
    borderRight: "1px solid #d1d6e3",
    textAlign: "center",
    verticalAlign: "middle",
  };
  const [data, setData] = useState(payload);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isEmpty(payload)) {
      setData(payload);
    }
  }, [payload]);

  const handleCheckPostCTA = async () => {
    setLoading(true);
    const postIds = flatMap(data, (item) =>
      map(item?.adsInfo, (x) => x?.objectStoryId?.split("_")[1])
    );
    let selectedView = "posts";
    switch (view) {
      case "politics-mkt-camp":
        selectedView = "posts/politics";
        break;
      case "jesus-mkt-camp":
        selectedView = "posts/jesus";
        break;
      default:
        selectedView = "posts";
        break;
    }
    const postCheckedResponse = await postService.checkPostCta(
      { postIds },
      selectedView
    );
    if (!postCheckedResponse) {
      showNotification("Thất bại", "Đã có lỗi xảy ra", "red");
      return false;
    }
    const { data: responseData } = postCheckedResponse;
    const newData = data?.map((item) => {
      return {
        ...item,
        adsInfo: item?.adsInfo.map((ad) => {
          const postId = ad?.objectStoryId?.split("_")[1];
          const post = responseData?.find((x) => x?.postId === postId);
          if (post) {
            return {
              ...ad,
              ...(!post?.isAssigned && { error: "Chưa gắn CTA" }),
            };
          }
          return ad;
        }),
      };
    });
    setData(newData);
    setLoading(false);
    const isHasError = filter(responseData, { isAssigned: false }).length > 0;

    return !isHasError;
  };

  const handleCreateCampaign = async () => {
    setLoading(true);
    const selectedView =
      view === "politics-mkt-camp" ? "campaigns/politics" : "campaigns";
    const createCampResponse = await campaignServices.createCampsV2(
      {
        payloads: data,
      },
      selectedView
    );
    if (createCampResponse?.success === false || !createCampResponse) {
      setData((prev) => {
        return map(prev, (item) => {
          const briefId = item.briefId;
          const errorMessage = find(
            createCampResponse?.errorList,
            (x) => x?.briefId === briefId
          )?.message;
          return {
            ...item,
            ...(errorMessage && {
              error: errorMessage,
            }),
          };
        });
      });
    } else {
      showNotification(
        "Thành công",
        "Các Campaigns đang được processing",
        "green"
      );
      close();
    }
    setLoading(false);
  };

  const rows = map(data, (item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td>
          <TextInput
            value={item?.campInfo?.name}
            onChange={(event) => {
              setData((prev) =>
                prev.map((i) => {
                  if (i.briefId === item.briefId) {
                    return {
                      ...i,
                      campInfo: {
                        ...i.campInfo,
                        name: event.target.value,
                      },
                    };
                  }
                  return i;
                })
              );
            }}
          />
        </Table.Td>
        <Table.Td>
          <TextInput
            value={item?.campInfo?.dailyBudget}
            onChange={(event) => {
              setData((prev) =>
                prev.map((i) => {
                  if (i.briefId === item.briefId) {
                    return {
                      ...i,
                      campInfo: {
                        ...i.campInfo,
                        dailyBudget: toNumber(event.target.value) || 0,
                      },
                    };
                  }
                  return i;
                })
              );
            }}
          />
        </Table.Td>
        <Table.Td>
          {map(item?.adsInfo, (ad) => {
            const error = ad?.error || "";
            const postId = ad?.objectStoryId?.split("_")[1];
            return (
              <Group
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <TextInput
                  value={ad?.name}
                  readOnly={true}
                  error={error}
                  m={4}
                  styles={{
                    error: {
                      margin: "5px",
                    },
                  }}
                />
                {error && (
                  <span
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Tooltip label="To Post">
                      <ActionIcon
                        component="a"
                        href={`https://facebook.com/${postId}`}
                        size="sx"
                        aria-label="Open in a new tab"
                        onClick={() => {}}
                        target="_blank"
                      >
                        <IconExternalLink />
                      </ActionIcon>
                    </Tooltip>
                    <CopyButton value={postId}>
                      {({ copied, copy }) => (
                        <Tooltip label="Copy Post Id">
                          <ActionIcon
                            color={copied ? "teal" : "blue"}
                            onClick={copy}
                            size="sx"
                          >
                            <IconCopy />
                          </ActionIcon>
                        </Tooltip>
                      )}
                    </CopyButton>
                  </span>
                )}
              </Group>
            );
          })}
        </Table.Td>
        <Table.Td>
          <Text
            style={{
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {item?.error || ""}
          </Text>
        </Table.Td>
        <Table.Td
          style={{
            verticalAlign: "middle",
          }}
        >
          <Group
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActionIcon
              variant="filled"
              aria-label="Settings"
              color="red"
              onClick={() => {
                setData((prev) =>
                  prev.filter((i) => i.campInfo?.name !== item.campInfo?.name)
                );
              }}
            >
              <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
            </ActionIcon>
          </Group>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Box>
      <ScrollArea>
        <Table miw={500} verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={headCellStyles}>Campaign Name</Table.Th>
              <Table.Th style={headCellStyles}>Budget</Table.Th>
              <Table.Th style={headCellStyles}>Ad Name</Table.Th>
              <Table.Th style={headCellStyles}>Error</Table.Th>
              <Table.Th style={headCellStyles}>Remove</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </ScrollArea>
      <Group
        mt={10}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={async () => {
            const isPostsChecked = await handleCheckPostCTA();
            if (isPostsChecked) {
              await handleCreateCampaign();
            }
          }}
          loading={loading}
        >
          Tạo
        </Button>
      </Group>
    </Box>
  );
};

export default CampaignTable;
