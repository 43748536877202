import DynamicTable from "./table/table";
import {
  Grid,
  Box,
  Group,
  Flex,
  Select,
  Title,
  Paper,
  Text,
  Stack,
  LoadingOverlay,
  Button,
  Image
} from "@mantine/core";
import { DateRangePicker } from "rsuite";
import { userServices } from "../../services/users";
import { authServices } from "../../services/auth";
import { dashboardServices } from "../../services/dashboards";
import { useEffect, useState } from "react";
import { map, uniq, filter, merge, omit, difference, flatMap } from "lodash";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

import { LOCAL_STORAGE_KEY } from "../../constant";
import { useLocalStorage } from "../../hooks";

export default function DashboardPersonal() {
  dayjs.extend(isoWeek);
  const [role, setRole] = useState(null);
  const [positions, setPositions] = useState([]);
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [department, setDepartment] = useState(null);

  // Sử dụng local storage để lưu trữ dữ liệu
  const [userInfoStorage, setUserInfoStorage] = useLocalStorage({
    key: LOCAL_STORAGE_KEY.USER_INFO,
    defaultValue: "",
    expiryInMinutes: 720, // hết hạn sau 12 giờ
  });

  //state for table order by sku
  const [columnsOrderSKU, setColumnsOrderSKU] = useState([]);
  const [dataOrderSKU, setDataOrderSKU] = useState([]);
  const [loadingOrderSKU, setLoadingOrderSKU] = useState(true);

  //state for table order by timeline
  const [columnsOrderTimeline, setColumnsOrderTimeline] = useState([]);
  const [dataOrderTimeline, setDataOrderTimeline] = useState([]);
  const [loadingOrderTimeline, setLoadingOrderTimeline] = useState(true);

  const [userInfo, setUserInfo] = useState({
    name: "Không có thông tin",
    position: "Không có thông tin",
  });
  const [filterUsers, setFilterUsers] = useState({
    memberId: userInfo?.uid,
    from: dayjs().startOf("month").format(),
    to: dayjs().format(),
  });

  const [dataReportPersonalSummary, setDataReportPersonalSummary] = useState({
    totalOrders: 0,
    optimizedSkus: 0,
    heroSkus: 0,
    totalSkus: 0,
    noSalePerSku: 0,
    heroPerSku: 0,
    optimizedPerSku: 0,
  });
  const [loadingSummary, setLoadingSummary] = useState(true);

  // Data filter cho order by SKU
  const SKUStatus = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Testing",
      value: "testing",
    },
    {
      label: "Optimize",
      value: "optimize",
    },
    {
      label: "Hero",
      value: "hero",
    },
  ];
  const SKUValue = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Small",
      value: "small",
    },
    {
      label: "Medium",
      value: "medium",
    },
    {
      label: "Big",
      value: "big",
    },
    {
      label: "Super Big",
      value: "super-big",
    },
  ];
  const [filterOrderBySKU, setFilterOrderBySKU] = useState({
    status: null,
    value: null,
  });

  // Data filter cho order by Timeline
  const timeLineBreakdown = [
    {
      label: "Day",
      value: "day",
    },
    {
      label: "Week",
      value: "week",
    },
    {
      label: "Month",
      value: "month",
    },
  ];
  const [timeLineBreakdownSelected, setTimeLineBreakdownSelected] =
    useState("week");
  const timeLineValue = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Small",
      value: "small",
    },
    {
      label: "Medium",
      value: "medium",
    },
    {
      label: "Big",
      value: "big",
    },
    {
      label: "Super Big",
      value: "super-big",
    },
  ];
  const timeLineMetric = [
    {
      label: "Order",
      value: "order",
    },
    {
      label: "Optimize",
      value: "optimize",
    },
    {
      label: "Hero",
      value: "hero",
    },
  ];

  const [timeLineReportFilter, setTimeLineReportFilter] = useState({
    metric: "order",
    value: null,
  });

  let [auth0Token, setToken] = useLocalStorage({
    key: LOCAL_STORAGE_KEY.ACCESS_TOKEN,
    defaultValue: "",
    expiryInMinutes: 720, // expired in 0.5 day
  });

  const getUserInfo = async () => {
    const { data } = await authServices.verifyToken(auth0Token);
    // console.log("data getUserInfo", data);
    setUserInfo({
      name: data?.name || "Không có thông tin",
      position: data?.position || "Không có thông tin",
    });
    getUsersByPosition(users, data?.position);
    setDepartment(data?.position || "Không có thông tin");
    setFilterUsers({
      ...filterUsers,
      memberId: data?.uid,
    });
  };
  // tạo mảng position theo mảng user
  const getPositions = (users) => {
    const positions = uniq(map(users, "position"));
    // console.log("positions ", positions);
    setPositions(positions);
    return positions;
  };

  const getUsersByPosition = (users, position) => {
    const members = filter(users, { position });
    const membersFilter = members.map((member) => ({
      label: member.name,
      value: member.uid,
    }));
    setMembers(membersFilter);
    return members;
  };

  const fetchRoles = async () => {
    if (userInfoStorage.isAdmin) {
      setRole("Admin");
    } else {
      const response = await userServices.fetchRoles();
      console.log("role ", response);
      setRole(response?.metadata?.currentUser?.roleInfo?.name || "Member");
    }
  };

  const fetchUsers = async () => {
    const response = await userServices.fetchUsers({
      page: 0,
      limit: 0,
    });
    getPositions(response?.data);
    setUsers(response?.data);
  };

  const fetchDataReportPersonalSummary = async () => {
    try {
      setLoadingSummary(true);
      const response = await dashboardServices.fetchDataReportPersonalSummary({
        query: filterUsers,
      });
      setDataReportPersonalSummary({
        heroPerSku: response?.heroPerSku ?? 0,
        noSalePerSku: response?.noSalePerSku ?? 0,
        optimizedPerSku: response?.optimizedPerSku ?? 0,
        heroSkus: response?.heroSkus ?? 0,
        optimizedSkus: response?.optimizedSkus ?? 0,
        totalSkus: response?.totalSkus ?? 0,
        totalOrders: response?.totalOrders ?? 0,
      });
      console.log("response summary", response);
    } catch (error) {
      console.log("error fetchDataReportPersonalSummary", error);
    } finally {
      setLoadingSummary(false);
    }
  };

  // hàm xử lý data theo breakdown (week,day,month)
  function groupOrdersByTime(data, type) {
    if (!data) return [];
    // Hàm tính tổng totalOrders theo nhóm
    const groupData = (keyFn, headerFn) => {
      const grouped = data.reduce((acc, item) => {
        const key = keyFn(item.date);
        const header = headerFn(item.date, key);
        if (!acc[header]) {
          acc[header] = 0; // Khởi tạo tổng bằng 0
        }
        if (timeLineReportFilter?.metric == "order") {
          acc[header] += item.totalOrders; // Cộng dồn totalOrders
        } else if (timeLineReportFilter?.metric == "optimize") {
          acc[header] = item.isOptimize; // Cộng dồn totalOrders
        } else if (timeLineReportFilter?.metric == "hero") {
          acc[header] = item.isHero; // Cộng dồn totalOrders
        }
        return acc;
      }, {});

      return Object.entries(grouped).map(([header, totalOrders]) => ({
        [header]: totalOrders,
      }));
    };

    switch (type) {
      case "day":
        return groupData(
          (date) => date, // Nhóm theo ngày gốc
          (date) => dayjs(date).format("YYYY-MM-DD") // Header là ngày đầy đủ
        );

      case "week":
        return groupData(
          (date) => dayjs(date).isoWeek(), // Nhóm theo tuần ISO
          (_, key) => `Week ${key}` // Header là "Week X"
        );

      case "month":
        return groupData(
          (date) => dayjs(date).month(), // Nhóm theo tháng (0-11)
          (date) => dayjs(date).format("MMMM") // Header là "Tháng Năm"
        );

      default:
        throw new Error('Invalid type. Use "day", "week", or "month".');
    }
  }

  function flattenDataArray(array) {
    return array.map((item) => {
      // Lấy mảng data và làm phẳng nó thành một object duy nhất
      const flattenedData = merge({}, ...item.data);
      // Loại bỏ trường data và kết hợp các thuộc tính còn lại với flattenedData
      return omit({ ...item, ...flattenedData }, "data");
    });
  }

  // Hàm tạo mảng columns từ data
  function generateColumns(data) {
    // Lấy tất cả key duy nhất từ toàn bộ mảng data
    const allKeys = uniq(flatMap(data, Object.keys));

    // Các key cố định
    const fixedKeys = ["sku", "image", "value", "createdDate"];
    const dynamicKeys = difference(allKeys, fixedKeys);

    // Sắp xếp dynamicKeys theo số tuần (giả sử định dạng là "Week X")
    const sortedDynamicKeys = dynamicKeys.sort((a, b) => {
      const weekA = parseInt(a.replace("Week ", ""), 10); // Trích xuất số từ "Week X"
      const weekB = parseInt(b.replace("Week ", ""), 10);
      return weekA - weekB; // Sắp xếp tăng dần
    });

    // Định nghĩa cột cố định
    const fixedColumns = [
      {
        accessorKey: "sku",
        header: "SKU",
      },
      {
        accessorKey: "image",
        header: "Image",
        Cell: ({ cell }) => (
          <Image
            src={cell.getValue()}
            alt="Product"
            height={100}
            width={100}
            fallbackSrc="/images/no-img.jpg"
          />
        ),
      },
      {
        accessorKey: "value",
        header: "Value",
      },
      {
        accessorKey: "createdDate",
        header: "Created Date",
        Footer: ({ table }) => {
          return <Text>Total: </Text>;
        },
      },
    ];

    // Định nghĩa cột động đã sắp xếp
    const dynamicColumns = sortedDynamicKeys.map((key) => ({
      accessorKey: key,
      header: key, // Hoặc tùy chỉnh header nếu cần
      Cell: ({ cell }) => <Text>{cell.getValue()}</Text>,
      Footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce((sum, row) => {
          return sum + (Number(row.original[key]) || 0);
        }, 0);
        return <Text>{total}</Text>;
      },
    }));

    // Kết hợp cột cố định và cột động
    return [...fixedColumns, ...dynamicColumns];
  }

  const fetchDataReportPersonalOrderBySKU = async () => {
    const columnsOrderSKU = [
      {
        accessorKey: "sku",
        header: "SKU",
        enableColumnFilter: false,
      },
      {
        accessorKey: "image",
        header: "Image",
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Image
            src={cell.getValue()} // Lấy giá trị URL từ dữ liệu
            alt="Product"
            height={100}
            width={100}
            fallbackSrc="/images/no-img.jpg"
          />
        ),
      },
      {
        accessorKey: "value",
        header: "Value",
        enableColumnFilter: false,
      },
      {
        accessorKey: "createdDate",
        header: "Create Date",
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Text>{dayjs(cell.getValue()).format("DD/MM/YYYY")}</Text>
        ),
        Footer: ({ table }) => {
          return <Text>Total: </Text>;
        },
      },
      {
        accessorKey: "orderTest",
        header: "Order - Test",
        enableColumnFilter: false,
        Cell: ({ cell }) => <Text>{cell.getValue()}</Text>,
        Footer: ({ table }) => {
          const total = table.getFilteredRowModel().rows.reduce((sum, row) => {
            return sum + (Number(row.original.orderTest) || 0);
          }, 0);
          return <Text>{total}</Text>;
        },
      },
      {
        accessorKey: "orderLifetime",
        header: "Order - Lifetime",
        enableColumnFilter: false,
        Footer: ({ table }) => {
          const total = table.getFilteredRowModel().rows.reduce((sum, row) => {
            return sum + (Number(row.original.orderLifetime) || 0);
          }, 0);
          return <Text>{total}</Text>;
        },
      },
      {
        accessorKey: "status",
        header: "SKU status",
        enableColumnFilter: false,
      },
    ];

    try {
      setLoadingOrderSKU(true);
      const response = await dashboardServices.fetchDataReportPersonalOrderBySKU({
        query: { ...filterUsers, ...filterOrderBySKU },
      });
      const data = response?.data;
      // console.log("response SKU", response);
      setDataOrderSKU(data);
      setColumnsOrderSKU(columnsOrderSKU);
    } catch (error) {
      console.log("error fetchDataReportPersonalOrderBySKU", error);
    } finally {
      setLoadingOrderSKU(false);
    }
  };

  const fetchDataReportPersonalOrderByTimeline = async () => {
    try {
      setLoadingOrderTimeline(true);
      const response =
        await dashboardServices.fetchDataReportPersonalOrderByTimeline({
          query: { ...filterUsers, ...timeLineReportFilter },
      });
    // const data = groupOrdersByTime(response?.data?.data, "week");
    const data = map(response?.data, (item) => ({
      ...item,
      data: item.data
        ? groupOrdersByTime(item.data, timeLineBreakdownSelected ?? "week")
        : [],
      }));
      const flattenedData = flattenDataArray(data);
      const columns = generateColumns(flattenedData);
      setColumnsOrderTimeline(columns);
      setDataOrderTimeline(flattenedData);
      // console.log("response Timeline", response?.data, flattenedData, columns);
    } catch (error) {
      console.log("error fetchDataReportPersonalOrderByTimeline", error);
    } finally {
      setLoadingOrderTimeline(false);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchUsers();
    getUserInfo();
    fetchDataReportPersonalSummary();
    getUsersByPosition(users, userInfo?.position);
  }, []);

  useEffect(() => {
    fetchDataReportPersonalSummary();
    fetchDataReportPersonalOrderBySKU();
    fetchDataReportPersonalOrderByTimeline();
    console.log("filter ", {
      filterUsers,
      timeLineBreakdownSelected,
      filterOrderBySKU,
      timeLineReportFilter,
    });
  }, [
    role,
    filterUsers,
    timeLineBreakdownSelected,
    filterOrderBySKU,
    timeLineReportFilter,
  ]);

  const dynamicTable = (data, columns, isLoading = false) => (
    <DynamicTable
      columns={columns}
      data={data}
      enableColumnFilters={false}
      enablePagination={true}
      enableSorting={true}
      pageSize={10}
      totalColumns={["orderTest", "orderLifetime"]}
      isLoading={isLoading}
    />
  );

  return (
    <Flex p="lg" gap="lg" justify="center" direction="column" align="center">
      {/* Filter */}
      <Box maw={900} mx="auto">
        <Group>
          <Select
            onChange={(value) => {
              setDepartment(value);
              getUsersByPosition(users, value);
            }}
            data={positions}
            value={department}
            disabled={role !== "Admin"}
          />
          <Select
            onChange={(value) => {
              setUserInfo({
                ...userInfo,
                name: { label: value, value: value },
              });
              setFilterUsers({ ...filterUsers, memberId: value });
            }}
            data={members}
            value={userInfo?.name?.value}
            disabled={role !== "Admin" && role !== "Lead"}
          />
          <DateRangePicker
            defaultValue={[
              dayjs().startOf("month").toDate(), // Chuyển dayjs thành Date
              dayjs().toDate(), // Chuyển dayjs thành Date
            ]}
            onChange={(value) => {
              setFilterUsers({
                ...filterUsers,
                from: dayjs(value?.[0]).format(),
                to: dayjs(value?.[1]).format(),
              });
            }}
            placeholder="Date"
          />
        </Group>
      </Box>
      {/* Summary */}
      <Title order={2}>Summary</Title>
      <Grid grow w={"100%"} justify="center" align="center" pos="relative">
        <LoadingOverlay visible={loadingSummary} overlayProps={{ blur: 1 }} />
        <Grid.Col span={4}>
          <Paper shadow="sm" radius="md" p="lg">
            <Stack align="center" gap="xs">
              <Title order={3}>Total Order</Title>
              <Text fw={700} fz={24}>
                {dataReportPersonalSummary?.totalOrders?.toLocaleString()}
              </Text>
            </Stack>
          </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Paper shadow="sm" radius="md" p="lg">
            <Stack align="center" gap="xs">
              <Title order={3}>SKU Optimize</Title>
              <Text fw={700} fz={24}>
                {dataReportPersonalSummary?.optimizedSkus}
              </Text>
            </Stack>
          </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Paper shadow="sm" radius="md" p="lg">
            <Stack align="center" gap="xs">
              <Title order={3}>SKU Hero</Title>
              <Text fw={700} fz={24}>
                {dataReportPersonalSummary?.heroSkus}
              </Text>
            </Stack>
          </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Paper shadow="sm" radius="md" p="lg">
            <Stack align="flex-start" gap="xs">
              <Group>
                <Text fw={700} fz={16}>
                  Total SKU:
                </Text>
                <Text fw={500} fz={16}>
                  {dataReportPersonalSummary?.totalSkus}
                </Text>
              </Group>
              <Group>
                <Text fw={700} fz={16}>
                  {department === "rnd" ? "NoSale/SKU" : "Avg SKU/day"}
                </Text>
                <Text fw={500} fz={16}>
                  {dataReportPersonalSummary?.noSalePerSku}%
                </Text>
              </Group>
            </Stack>
          </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Paper shadow="sm" radius="md" p="lg">
            <Stack align="flex-start" gap="xs">
              <Group>
                <Text fw={700} fz={16}>
                  Hero / SKU:
                </Text>
                <Text fw={500} fz={16}>
                  {dataReportPersonalSummary?.heroPerSku}%
                </Text>
              </Group>
              <Group>
                <Text fw={700} fz={16}>
                  Opt / SKU:
                </Text>
                <Text fw={500} fz={16}>
                  {dataReportPersonalSummary?.optimizedPerSku}%
                </Text>
              </Group>
            </Stack>
          </Paper>
        </Grid.Col>
      </Grid>
      {/* Table Order by SKU */}
      <Title order={2}>DETAIL REPORT</Title>
      <Flex w={"100%"} justify="flex-start" align="center">
        <Title order={3}>Order by SKU</Title>
      </Flex>
      <Stack w={"100%"}>
        <Group align="flex-end">
          <Select
            data={SKUStatus}
            label="SKU Status"
            defaultValue="all"
            value={filterOrderBySKU?.status ?? "all"}
            onChange={(value) => {
              setFilterOrderBySKU({
                ...filterOrderBySKU,
                status: value == "all" ? null : value,
              });
            }}
          />
          <Select
            data={SKUValue}
            label="SKU Value"
            defaultValue="small"
            value={filterOrderBySKU?.value ?? "all"}
            onChange={(value) => {
              setFilterOrderBySKU({ ...filterOrderBySKU, value: value == "all" ? null : value });
            }}
          />
          <Button
            variant="filled"
            onClick={() => {
              setFilterOrderBySKU({
                ...filterOrderBySKU,
                status: null,
                value: null,
              });
            }}
          >
            Reset Filter
          </Button>
        </Group>
        {dynamicTable(dataOrderSKU, columnsOrderSKU, loadingOrderSKU)}
      </Stack>
      {/* Table Order by SKU */}
      <Title order={2}>Order by Timeline</Title>
      <Flex w={"100%"} justify="flex-start" align="center">
        <Title order={3}>Order by Timeline</Title>
      </Flex>
      <Stack w={"100%"}>
        <Group align="flex-end">
          <Select
            data={timeLineBreakdown}
            label="Time Line Breakdown"
            onChange={(value) => {
              setTimeLineBreakdownSelected(value);
            }}
            defaultValue="week"
          />
          <Select
            data={timeLineValue}
            value={timeLineReportFilter?.value ?? "all"}
            onChange={(value) => {
              setTimeLineReportFilter({
                ...timeLineReportFilter,
                value: value == "all" ? null : value,
              });
            }}
            defaultValue="small"
          />
          <Select
            data={timeLineMetric}
            value={timeLineReportFilter?.metric ?? "order"}
            label="Time Line Metric"
            onChange={(value) => {
              setTimeLineReportFilter({
                ...timeLineReportFilter,
                metric: value,
              });
            }}
            defaultValue="order"
          />
          <Button
            variant="filled"
            onClick={() => {
              setTimeLineReportFilter({
                ...timeLineReportFilter,
                metric: "order",
                value: "small",
              });
            }}
          >
            Reset Filter
          </Button>
        </Group>
        {dynamicTable(dataOrderTimeline, columnsOrderTimeline, loadingOrderTimeline)}
      </Stack>
    </Flex>
  );
}
