import React, { useEffect, useState } from "react";
import { Modal } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import styles from "./PreviewModal.module.sass";
import { find, map, toNumber } from "lodash";
import { BRIEF_TYPES } from "../../../../constant";

const PreviewModal = ({
  opened,
  onClose,
  data,
  onSubmit,
  loading,
  users,
  timeSettings,
}) => {
  const [payload, setPayload] = useState({});

  useEffect(() => {
    const briefType = BRIEF_TYPES[9];
    const { note } = data;
    const ref = data?.refs?.[0];
    const productLine = data?.productBases?.[0];

    const rndUser = find(users, { uid: data?.rnd });
    const skuAccumulators = productLine?.skuAccumulators;

    const currentSkuAccumulator =
      find(skuAccumulators, {
        rndId: data?.rnd,
      })?.accumulator || 500;

    const nextAccumulator = currentSkuAccumulator + 1;

    const sku = `${productLine?.skuPrefix}-${rndUser?.shortName}${String(
      nextAccumulator
    ).padStart(4, "0")}`;
    let designerTime = find(timeSettings, {
      team: "designer",
      scaleType: briefType,
      size: toNumber(data?.size),
    });
    let epmTime = find(timeSettings, {
      team: "epm",
      scaleType: briefType,
      size: toNumber(data?.size),
    });

    const payload = {
      ...(designerTime && {
        designerTimeId: designerTime?.uid,
      }),
      ...(epmTime && {
        epmTimeId: epmTime?.uid,
      }),
      directionId: data?.direction,
      imageRef: ref?.image,
      sku,
      project: data?.project,
      batch: rndUser?.nextBatch,
      briefType,
      value: {
        rnd: toNumber(data?.value),
      },
      rndId: data?.rnd,
      designerId: data?.designer,
      note: {
        designer: note?.designer,
        epm: note?.epm,
        mkt: note?.mkt,
      },
      status: 1,
      productLineId: productLine?.uid,
      designLinkRef: ref?.designLink,
      clipartIds: map(ref?.products, (clipart) => clipart?.uid),
      refDesignMarketNote: ref?.note,
      nextSkuAccumulator: nextAccumulator,
      rndTeam: data?.team,
      skuPrefix: productLine?.skuPrefix,
      productRankingInfo: {
        competitor: ref?.competitor,
        id: ref?.id,
      },
      productLineImage: productLine?.imageSrc,
      productLineTitle: productLine?.name,
      clipartImages: map(ref?.products, (clipart) => clipart?.imageSrc),
      rndUser,
    };
    setPayload(payload);
  }, [data]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Preview Brief"
      size="xl"
      classNames={{
        content: styles.modalContent,
        header: styles.modalHeader,
        title: styles.modalTitle,
        body: styles.modalBody,
      }}
    >
      <div className={styles.header}>
        <div className={styles.headerTop}>
          <div className={styles.batchText}>BATCH: {payload?.batch}</div>
          <div className={styles.marketTrend}>
            {payload?.briefType || "No brief type"}
          </div>
          <div className={styles.owner}>
            {payload?.rndTeam || "No team"} -{" "}
            {payload?.rndUser?.name || "No designer"}
          </div>
        </div>
      </div>
      {payload && (
        <div className={styles.content}>
          <div className={styles.mainContent}>
            <div className={styles.refSection}>
              <div className={styles.sectionTitle}>Product Line</div>
              <div className={styles.refImage}>
                <img
                  src={payload?.productLineImage}
                  alt={payload?.title}
                  className={styles.image}
                />
              </div>
              <div
                className={styles.productLine}
                style={{
                  textAlign: "center",
                }}
              >
                {payload?.productLineTitle || "No reference"}
              </div>
            </div>

            <div className={styles.productSection}>
              <div className={styles.sectionTitle}>Scale</div>
              <table className={styles.table}>
                <thead className={styles.tableHead}>
                  <tr>
                    <th className={styles.tableHeadCell}>No</th>
                    <th className={styles.tableHeadCell}>Ref</th>
                    <th className={styles.tableHeadCell}>Cliparts</th>
                    <th className={styles.tableHeadCell}>SKU</th>
                    <th className={styles.tableHeadCell}>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={styles.tableCell}>1</td>
                    <td className={styles.tableCell}>
                      <img
                        src={payload?.imageRef}
                        alt={payload?.title}
                        className={styles.productImage}
                      />
                    </td>
                    <td className={styles.tableCell}>
                      {map(payload?.clipartImages, (clipart, index) => (
                        <img
                          key={index}
                          src={clipart}
                          alt={clipart?.title}
                          className={styles.productImage}
                        />
                      ))}
                    </td>
                    <td className={styles.tableCell}>{payload?.sku}</td>
                    <td
                      className={styles.tableCell}
                      onClick={() => {
                        setPayload({});
                      }}
                    >
                      <IconX className={styles.removeIcon} size={16} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <div className={styles.footer}>
        <button className={styles.buttonLight} onClick={onClose}>
          Cancel
        </button>
        <button
          className={styles.buttonPrimary}
          onClick={() => {
            onSubmit?.({
              isOptimized: false,
              payloads: [payload],
            });
            onClose();
          }}
          disabled={loading}
        >
          {loading ? "Loading..." : "Submit"}
        </button>
      </div>
    </Modal>
  );
};

export default PreviewModal;
