import React from "react";
import { Group, TextInput } from "@mantine/core";
import styles from "../ProductSelector.module.sass";
import AppMultiSelect from "../../../../../components/CustomMultiselect";
import { map } from "lodash";

const FilterBar = ({ filters = [], value = {}, onChange }) => {
  const renderFilter = (filter) => {
    const { _id, name, type, value: options } = filter;

    switch (type) {
      case "MultiSelect":
        return (
          <AppMultiSelect
            key={_id}
            label={name}
            placeholder={`Select ${name}`}
            options={options.map((opt) => ({ value: opt, label: opt }))}
            defaultValue={
              map(value[name], (x) => ({ value: x, label: x })) || []
            }
            onChange={(newValue) => onChange({ _id, newValue, name })}
            className={styles.filter}
            clearable
            searchable
          />
        );

      case "Select":
        return (
          <AppMultiSelect
            key={_id}
            label={name}
            placeholder={`Select ${name}`}
            data={options.map((opt) => ({ value: opt, label: opt }))}
            value={value[_id] ? [value[_id]] : []}
            onChange={(newValue) => onChange(_id, newValue[0])}
            className={styles.filter}
            clearable
            searchable
            maxSelectedValues={1}
          />
        );

      case "Text":
        return (
          <TextInput
            key={_id}
            label={name}
            placeholder={`Enter ${name}`}
            value={value[_id] || ""}
            onChange={(e) => onChange(_id, e.target.value)}
            className={styles.filter}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Group className={styles.filterBar}>
      {filters.map((filter) => renderFilter(filter))}
    </Group>
  );
};

export default FilterBar;
