import { useMemo, useState } from "react";
import useGetUser from "../../../hooks/useGetUser";
import { campaignServices } from "../../../services";
import { CONVERT_STATUS_TO_NUMBER } from "../../../utils";
import formatDate from "../../../utils/formatDate";
import { showNotification } from "../../../utils/index";
import useGetSampleList from "../../../hooks/useGetSampleList";
import { find, omit } from "lodash";
import useGetSampleListVideo from "../../../hooks/useGetSampleListVideo";
import { STATUS } from "../../../constant";

const defaultFilters = {
  videoStatus: [2],
};

const useTable = ({ query, setQuery }) => {
  const { users } = useGetUser();

  const { data, pagination, handleChangePage, refetch, loading, setLoading } =
    useGetSampleListVideo({
      filters: query,
      defaultFilters,
    });

  const [searchSKU, setSearchSKU] = useState("");

  const handleChangeSKU = (e) => {
    setSearchSKU(e.target.value);
  };

  const handleSubmitSKU = () => {
    setQuery({
      ...query,
      sku: searchSKU,
    });
    handleChangePage();
  };

  const handleChangeDate = (dateValue, startDate, endDate) => {
    if (!startDate) {
      setQuery({
        ...query,
        startVideoRequestedDate: null,
        endVideoRequestedDate: null,
        dateValue: null,
      });
      return;
    }

    setQuery({
      ...query,
      dateValue,
      startVideoRequestedDate: formatDate(startDate),
      endVideoRequestedDate: formatDate(endDate),
    });
    handleChangePage();
  };

  const clearFilters = () => {
    setQuery({
      sku: null,
      dateValue: null,
      startDate: null,
      projects: null,
      endDate: null,
      startVideoRequestedDate: null,
      endVideoRequestedDate: null,
      value: null,
      valueName: null,
      rndTeam: null,
      rndName: null,
      rndId: null,
      editor: null,
      videoEditorId: null,
      status: [2],
      isDoneSourceValue: "All",
      statusValue: "Undone",
      archive: null,
    });
    setSearchSKU("");
  };

  const handleChangeSizeValue = (value) => {
    setQuery({
      ...query,
      value: CONVERT_STATUS_TO_NUMBER[value],
      valueName: value,
    });
    handleChangePage();
  };

  const handleClearSizeValue = () => {
    setQuery({
      ...query,
      value: null,
      valueName: null,
    });
    handleChangePage();
  };

  const handleResetEditor = () => {
    setQuery({
      ...query,
      editor: null,
      videoEditorId: null,
    });
    handleChangePage();
  };

  const handleResetIsDoneSource = () => {
    setQuery({
      ...omit(query, ["isDoneSource"]),
      isDoneSourceValue: null,
    });
    handleChangePage();
  };

  const handleSelectEditor = (value) => {
    setQuery({
      ...query,
      editor: value,
      videoEditorId:
        find(users, { name: value, position: "video" })?.uid || null,
    });
    handleChangePage();
  };

  const handleChangeTeam = (value) => {
    setQuery({ ...query, rndTeam: value });
    handleChangePage();
  };

  const handleCheckDoneSource = (value) => {
    const refactoredValue =
      value === "All" ? null : value === "Done" ? true : false;
    setQuery({
      ...query,
      isDoneSource: refactoredValue,
      isDoneSourceValue: value,
    });
    handleChangePage();
  };
  const handleClearTeam = () => {
    setQuery({
      ...query,
      rndTeam: null,
    });
    handleChangePage();
  };

  const handleChangeStatus = (value) => {
    let status;
    let archive = null;
    switch (value) {
      case "Done":
        status = [3];
        break;
      case "Undone":
        status = [2];
        break;
      case "Archived":
        archive = STATUS.ARCHIVED;
        break;
      default:
        status = [2, 3];
        archive = null;
        break;
    }
    setQuery({
      ...query,
      status,
      statusValue: value,
      ...(archive
        ? { archive, status: [2, 3] }
        : {
            archive: null,
            status,
          }),
    });
    handleChangePage();
  };

  const handleClearStatus = () => {
    setQuery({
      ...query,
      status: [3, 2],
      statusValue: null,
      archive: null,
    });
    handleChangePage();
  };

  const handleDoneSample = async (briefId) => {
    setLoading(true);
    const result = await campaignServices.updateVideoBrief(briefId, {
      videoStatus: 3,
    });
    if (result.success) {
      showNotification("Thành công", "Update status thành công", "green");
      refetch();
    }
    setLoading(false);
  };

  const handleIncompleteSample = async (briefId) => {
    setLoading(true);
    const result = await campaignServices.updateVideoBrief(briefId, {
      videoStatus: 2,
    });
    if (result.success) {
      showNotification("Thành công", "Update status thành công", "green");
      refetch();
    }
    setLoading(false);
  };

  const handleUpdateDoneScene = async (briefId, isDoneSource) => {
    const result = await campaignServices.updateVideoBrief(briefId, {
      isDoneSource,
    });
    if (result.success) {
      showNotification("Thành công", "Update status thành công", "green");
      refetch();
    }
  };

  const handleUpdateLinkVideo = async (briefId, videoLinks) => {
    const result = await campaignServices.updateVideoBrief(briefId, {
      videoLinks,
    });
    if (result.success) {
      showNotification("Thành công", "Update Link thành công", "green");
      refetch();
    }
  };

  const handleUpdateVideoThumbLinks = async (briefId, videoThumbLinks) => {
    const result = await campaignServices.updateVideoBrief(briefId, {
      videoThumbLinks,
    });
    if (result.success) {
      showNotification("Thành công", "Update Link thành công", "green");
      refetch();
    }
  };

  const handleUpdateVideoEditor = async (briefId, videoEditorId) => {
    const result = await campaignServices.updateVideoBrief(briefId, {
      videoEditorId,
    });
    if (result.success) {
      showNotification("Thành công", "Update status thành công", "green");
      refetch();
    }
  };

  const listUserOptions = useMemo(() => {
    const videoMems = users?.filter((user) => user.position === "video") || [];
    return videoMems.map((user) => ({
      value: user.uid,
      label: user.name,
    }));
  }, [users]);

  const handleUpdateNote = async (briefId, note) => {
    const result = await campaignServices.updateVideoBrief(briefId, note);
    if (result.success) {
      showNotification("Thành công", "Update Note thành công", "green");
      refetch();
    }
  };

  const handleRemoveVideo = async (briefId) => {
    const result = await campaignServices.removeVideoBrief(briefId);
    if (result.success) {
      showNotification("Thành công", "Xóa thành công", "green");
      refetch();
    }
  };

  const handleUpdateValue = async (briefId, value) => {
    const result = await campaignServices.updateVideoBrief(briefId, {
      value,
    });
    if (result.success) {
      showNotification("Thành công", "Update value thành công", "green");
      refetch();
    }
  };

  const handleArchiveBrief = async (briefId, archive) => {
    const result = await campaignServices.updateVideoBrief(briefId, {
      archive,
    });
    if (result.success) {
      showNotification("Thành công", "Update thành công", "green");
      refetch();
    }
  };

  const handleUpdateTeam = async (briefId, rndTeam) => {
    const result = await campaignServices.updateVideoBrief(briefId, {
      rndTeam,
    });
    if (result.success) {
      showNotification("Thành công", "Update thành công", "green");
      refetch();
    }
  };

  const handleChangeProject = (value) => {
    setQuery({
      ...query,
      projects: [value],
    });
    handleChangePage();
  };
  const handleClearProject = () => {
    setQuery({
      ...query,
      projects: null,
    });
    handleChangePage();
  };

  return {
    data,
    loading,
    users,
    searchSKU,
    pagination,
    listUserOptions,
    clearFilters,
    handleChangeDate,
    handleChangePage,
    handleChangeSizeValue,
    handleChangeSKU,
    handleChangeStatus,
    handleChangeTeam,
    handleClearSizeValue,
    handleClearStatus,
    handleClearTeam,
    handleDoneSample,
    handleIncompleteSample,
    handleSubmitSKU,
    handleUpdateDoneScene,
    handleUpdateLinkVideo,
    handleUpdateVideoEditor,
    handleUpdateVideoThumbLinks,
    handleSelectEditor,
    handleResetEditor,
    handleCheckDoneSource,
    handleResetIsDoneSource,
    handleUpdateNote,
    handleRemoveVideo,
    handleUpdateValue,
    handleArchiveBrief,
    handleUpdateTeam,
    handleChangeProject,
    handleClearProject,
  };
};

export default useTable;
