import React, { useState, useEffect } from "react";
import {
  Modal,
  TextInput,
  Checkbox,
  Group,
  Button,
  Stack,
  NumberInput,
} from "@mantine/core";
import { isEmpty, toUpper } from "lodash";
import styles from "./StoreModal.module.sass";

const StoreModal = ({
  opened,
  onClose,
  onSubmit,
  loading = false,
  initialData = null,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    domain: "",
    accumulatedRankingLink: "",
    trendingRankingLink: "",
    productsPerPage: 0,
    alive: true,
    shortName: "",
    isShopify: true,
    category: "",
  });

  // Load initial data when editing
  useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name || "",
        domain: initialData.domain || "",
        accumulatedRankingLink: initialData.accumulatedRankingLink || "",
        trendingRankingLink: initialData.trendingRankingLink || "",
        productsPerPage: initialData.productsPerPage || 0,
        alive: initialData.alive ?? true,
        shortName: initialData.shortName || "",
        isShopify: initialData.isShopify || true,
        category: initialData.category || "",
      });
    } else {
      // Reset form when adding new
      setFormData({
        name: "",
        domain: "",
        accumulatedRankingLink: "",
        trendingRankingLink: "",
        productsPerPage: 0,
        alive: true,
        shortName: "",
        isShopify: true,
        category: "",
      });
    }
  }, [initialData, opened]);

  const handleSubmit = () => {
    if (isEmpty(formData.name) || isEmpty(formData.domain)) {
      return;
    }
    onSubmit(formData);
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={initialData ? "Edit Competitor" : "Add New Competitor"}
      size="lg"
      classNames={{
        title: styles.modalTitle,
        content: styles.modalContent,
      }}
    >
      <div className={styles.content}>
        <Stack spacing="md">
          <div className={styles.inputWrapper}>
            <TextInput
              required
              label="Competitor Name"
              placeholder="Enter competitor name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              classNames={{
                label: styles.inputLabel,
                required: styles.inputRequired,
                input: styles.input,
              }}
            />
          </div>

          <div className={styles.inputWrapper}>
            <TextInput
              required
              label="Domain"
              placeholder="Enter store domain (e.g., https://store.com)"
              value={formData.domain}
              onChange={(e) =>
                setFormData({ ...formData, domain: e.target.value })
              }
              classNames={{
                label: styles.inputLabel,
                required: styles.inputRequired,
                input: styles.input,
              }}
            />
          </div>

          <div className={styles.inputWrapper}>
            <TextInput
              label="Short Name"
              placeholder="Enter competitor short name"
              value={formData.shortName}
              onChange={(e) =>
                setFormData({ ...formData, shortName: e.target.value })
              }
              classNames={{
                label: styles.inputLabel,
                input: styles.input,
              }}
            />
          </div>
          <div className={styles.inputWrapper}>
            <TextInput
              label="Category"
              placeholder="Enter competitor category"
              value={formData.category}
              onChange={(e) =>
                setFormData({ ...formData, category: toUpper(e.target.value) })
              }
              classNames={{
                label: styles.inputLabel,
                input: styles.input,
              }}
            />
          </div>

          <div className={styles.inputWrapper}>
            <TextInput
              label="Accumulated Ranking Link"
              placeholder="Enter accumulated ranking link"
              value={formData.accumulatedRankingLink}
              error={
                formData.accumulatedRankingLink &&
                !formData.accumulatedRankingLink.match(/^https?:\/\/.+/i)
                  ? "Please enter a valid HTTPS URL"
                  : null
              }
              onChange={(e) =>
                setFormData({
                  ...formData,
                  accumulatedRankingLink: e.target.value,
                })
              }
              classNames={{
                label: styles.inputLabel,
                input: styles.input,
              }}
            />
          </div>

          <div className={styles.inputWrapper}>
            <TextInput
              label="Trending Ranking Link"
              placeholder="Enter trending ranking link"
              value={formData.trendingRankingLink}
              error={
                formData.trendingRankingLink &&
                !formData.trendingRankingLink.match(/^https?:\/\/.+/i)
                  ? "Please enter a valid HTTPS URL"
                  : null
              }
              onChange={(e) =>
                setFormData({
                  ...formData,
                  trendingRankingLink: e.target.value,
                })
              }
              classNames={{
                label: styles.inputLabel,
                input: styles.input,
              }}
            />
          </div>

          <div className={styles.inputWrapper}>
            <NumberInput
              label="Products Per Page"
              placeholder="Enter products per page"
              value={formData.productsPerPage}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  productsPerPage: value,
                })
              }
              classNames={{
                label: styles.inputLabel,
                input: styles.input,
              }}
            />
          </div>

          <Group mt="xs">
            <Checkbox
              label="Active"
              checked={formData.alive}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  alive: event.currentTarget.checked,
                })
              }
              classNames={{
                label: styles.checkboxLabel,
              }}
            />
            <Checkbox
              label="Is Shopify"
              checked={formData.isShopify}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  isShopify: event.currentTarget.checked,
                })
              }
              classNames={{
                label: styles.checkboxLabel,
              }}
            />
          </Group>

          <div className={styles.actions}>
            <Button variant="light" onClick={onClose} className={styles.button}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              loading={loading}
              disabled={isEmpty(formData.name) || isEmpty(formData.domain)}
              className={styles.button}
            >
              {initialData ? "Update" : "Submit"}
            </Button>
          </div>
        </Stack>
      </div>
    </Modal>
  );
};

export default StoreModal;
