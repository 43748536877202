import React from "react";
import { Badge } from "@mantine/core";

const statusColors = {
  active: "green",
  inactive: "red",
  pending: "yellow",
  shipped: "blue",
  received: "teal",
  default: "gray",
  "done producing": "green",
  processing: "yellow",
  "đang design": "blue",
  "đang listing": "green",
  "đang lên post": "yellow",
  "đang lên campaign": "red",
  "đã chạy campaign": "teal",
};

const StatusBadge = ({ status }) => {
  const color = statusColors[status?.toLowerCase()] || statusColors.default;

  return (
    <Badge
      color={color}
      variant="light"
      size="md"
      style={{ textTransform: "capitalize" }}
    >
      {status || "N/A"}
    </Badge>
  );
};

export default StatusBadge;
