import { useState, useEffect, useCallback } from "react";
import { debounce, isEmpty, get, isFunction, omit, pick, map } from "lodash";

export const useProductSelector = ({ fetchProducts, onSync }) => {
  const [state, setState] = useState({
    searchValue: "",
    products: [],
    loading: false,
    filters: {},
    pagination: {
      currentPage: 1,
      totalPages: 1,
    },
  });

  const updateState = useCallback((newState) => {
    setState((prev) => ({
      ...prev,
      ...newState,
    }));
  }, []);

  const handleFetchProducts = async ({
    page = 1,
    search = "",
    filters = {},
  }) => {
    try {
      updateState({ loading: true });
      const response = await fetchProducts({
        page,
        limit: 12,
        query: {
          keyword: search,
          ...(!isEmpty(filters) && { ...filters }),
        },
      });

      if (!isEmpty(response)) {
        updateState({
          products: get(response, "data", []),
          pagination: pick(get(response, "metadata", {}), [
            "currentPage",
            "totalPages",
          ]),
        });
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      updateState({ loading: false });
    }
  };

  const debouncedSearch = debounce((value) => {
    handleFetchProducts({
      page: 1,
      search: value,
      filters: state.filters,
    });
  }, 500);

  const handleSearch = useCallback((value) => {
    updateState({ searchValue: value });
    debouncedSearch(value);
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      handleFetchProducts({
        page,
        search: state.searchValue,
        filters: state.filters,
      });
    },
    [state.searchValue]
  );

  const handleSync = useCallback(async () => {
    if (isFunction(onSync)) {
      try {
        updateState({ loading: true });
        await onSync();
        await handleFetchProducts({
          page: 1,
          search: state.searchValue,
          filters: state.filters,
        });
      } catch (error) {
        console.error("Error syncing products:", error);
      } finally {
        updateState({ loading: false });
      }
    }
  }, [onSync, state.searchValue]);

  const handleFilterChange = useCallback(
    ({ _id, newValue, name }) => {
      console.log(state);
      const newFilters = {
        ...state.filters,
        [name]: map(newValue, (value) => value.value),
      };
      updateState({ filters: newFilters });
      handleFetchProducts({
        page: 1,
        search: state.searchValue,
        filters: {
          ...state.filters,
          [name]: map(newValue, (value) => value.value),
        },
      });
    },
    [state.searchValue, state.filters]
  );

  useEffect(() => {
    handleFetchProducts({});
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  return {
    ...state,
    handleSearch,
    handlePageChange,
    handleSync,
    handleFilterChange,
  };
};
