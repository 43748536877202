import { showNotification } from "../utils/index";
import apiClient from "./axiosClient";

const API_URL = "https://pod-rnd-backend-test.uidevify.com";

export const playgroundServices = {
  createTask: async (data) => {
    try {
      const response = await apiClient.post(`${API_URL}/kling-ai`, data);
      return response.data;
    } catch (error) {
      const message = error.response?.data?.message || "Error fetching tasks";
      showNotification("Thất bại", message, "red");
      console.error(error);
    }
  },
  fetchTasks: async () => {
    try {
      const response = await apiClient.get(`${API_URL}/kling-ai`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
